import React from "react";
import { useFormState, useWatch } from "react-hook-form";
import ApplicationFormFieldset from "components/ApplicationFormFieldset/ApplicationFormFieldset";
import InputText from "components/InputText/InputText";
import InputUpload from "components/InputUpload/InputUpload";

const ApplicationFormStep6 = ({
  number,
  title,
  description,
  fields,
  register,
  control,
  setValue,
  clearErrors,
  trigger,
}) => {
  const { errors } = useFormState({
    control,
  });

  const spares = useWatch({
    control,
    name: "spares",
  });

  const vacantSpares = spares && spares.length ? spares.filter(spare => spare.title === "нет нужной детали в списке арт.0000000") : [];
  const stepFields = fields.map((field) => field.name);

  return (
    <div className="application__step application__step--six">
      <ApplicationFormFieldset
        number={number}
        title={title}
        description={description}
        trigger={trigger}
        stepFields={stepFields}
      >
        <div className="application__fields">
          {fields.map((field, i) =>
            field.type === "textarea" ? (
              <div key={i + 1} className="application__field">
                <InputText
                  name={field.name}
                  label={field.label}
                  params={{ ...field.params, onChange: () => clearErrors() }}
                  register={register}
                  placeholder={field.placeholder}
                  alert={vacantSpares.length ? "Укажите использованную запчаcть!" : false}
                />
              </div>
            ) : (
              <div key={i + 1} className="application__field">
                <InputUpload
                  name={field.name}
                  label={field.label}
                  params={field.params}
                  register={register}
                  control={control}
                  setValue={setValue}
                  isMultiple={true}
                />
              </div>
            )
          )}
        </div>
      </ApplicationFormFieldset>
    </div>
  );
};

export default ApplicationFormStep6;
