import React from "react";

const ApplicationStatus = ({ color, label }) => {
  return (
    <span className="application__status">
      <span
        className={`application__status-point application__status-point--${color}`}
      ></span>
      {label}
    </span>
  );
};

export default ApplicationStatus;
