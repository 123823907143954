const getAddresses = async (query) => {
  const url =
    "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
  const token = "8902455f8c39831f86115e953d069a132870de5b";

  const options = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify({
      query: query,
      count: 3,
      locations: [
        {
          country_iso_code: "RU",
        },
        {
          country_iso_code: "BY",
        },
        {
          country_iso_code: "KG",
        },
        {
          country_iso_code: "KZ",
        },
        {
          country_iso_code: "TJ",
        },
        {
          country_iso_code: "UZ",
        },
        {
          country_iso_code: "AM",
        },
        {
          country_iso_code: "AZ",
        },
        {
          country_iso_code: "MD",
        },
      ],
    }),
  };

  return fetch(url, options)
    .then((response) => response.text())
    .then((result) => {
      const suggestions = JSON.parse(result).suggestions;
      const promptData = suggestions.reduce((acc, suggestion) => {
        acc.push({
          value: suggestion.value,
          region: suggestion.data.region_with_type,
        });
        return acc;
      }, []);
      return promptData;
    })
    .catch((error) => console.log("error", error));
};

export default getAddresses;
