import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import InputText from "components/InputText/InputText";
import Icon from "components/Icon/Icon";

const ModalWindow = ({
  modal,
  number,
  comment,
  onSubmit,
  onClose,
  onButtonClick,
}) => {
  const { register, handleSubmit, clearErrors } = useForm();
  const isMobile = useSelector((state) => state.isMobile);
  const { name, title, message, input, button, isNumber } = modal;

  const renderButtons = () => {
    return (
      <div className="modal__buttons">
        <button
          className="modal__button button button--medium-gray"
          type="button"
          onClick={onClose}
        >
          Отмена
        </button>
        <button
          className={`modal__button button button--${button.className}`}
          type={button.type}
          onClick={onButtonClick || null}
        >
          {button.icon ? <Icon name={button.icon} /> : ""}
          <span>{button.label}</span>
        </button>
      </div>
    );
  };

  return (
    <div className={`modal__window modal__window--${name}`}>
      <h3 className="modal__title title">
        {isNumber && !isMobile && `Заявка #${number}: `}
        {title}
      </h3>
      <p
        className="modal__message"
        dangerouslySetInnerHTML={{ __html: message }}
      ></p>
      {input ? (
        <form className="form modal__form" onSubmit={handleSubmit(onSubmit)}>
          <InputText
            name={input.name}
            label={input.label}
            params={{
              ...input.params,
              value: input.name === "internal" && comment ? comment : "",
              onChange: () => clearErrors(),
            }}
            register={register}
            placeholder={input.placeholder}
          />
          {renderButtons()}
        </form>
      ) : (
        renderButtons()
      )}
    </div>
  );
};

export default ModalWindow;
