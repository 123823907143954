import React from "react";
import { Redirect } from "react-router-dom";
import { useQuery } from "hooks/useQuery";
import PageWBack from "components/PageWBack/PageWBack";
import Auth from "components/Auth/Auth";
import { AppPath, AuthPage } from "utils/const";
import Password from "components/Password/Password";

const PasswordPage = () => {
  const query = useQuery();
  const isResetMode = query.get("mode") === "resetPassword";
  const oobCode = query.get("oobCode");

  return isResetMode ? (
    <PageWBack>
      <Auth
        title={AuthPage.PASSWORD.title}
        subtitle={AuthPage.PASSWORD.subtitle}
      >
        <Password code={oobCode} />
      </Auth>
    </PageWBack>
  ) : (
    <Redirect to={AppPath.ROOT} replace />
  );
};

export default PasswordPage;
