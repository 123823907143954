import React from "react";
import { Link } from "react-router-dom";
import Logo from "components/Logo/Logo";
import Icon from "components/Icon/Icon";
import { AppPath } from "utils/const";

const Auth = ({ children, title, subtitle, isBackButton }) => {
  return (
    <div className="auth">
      {isBackButton && (
        <Link
          to={AppPath.LOGIN}
          className="button button--small auth__back-btn"
        >
          <Icon name="arrow-left" />
          <span>Назад</span>
        </Link>
      )}
      <Logo title={title} />
      {subtitle && <p className="auth__subtitle" dangerouslySetInnerHTML={{ __html: subtitle }}></p>}
      {children}
    </div>
  );
};

export default Auth;
