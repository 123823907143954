import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { setPassword } from "store/async";
import Input from "components/Input/Input";
import { AppPath, Forms } from "utils/const";

const { passwordMeta } = Forms;

const Password = ({ code }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    const { password } = data;
    try {
      await dispatch(
        setPassword({ oobCode: code, newPassword: password })
      ).unwrap();
      history.push(AppPath.LOGIN);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form className="form auth__form" onSubmit={handleSubmit(onSubmit)}>
      <div className="auth__fieldset">
        {passwordMeta.map((field, i) => (
          <div key={i + 1} className="auth__field">
            <Input
              name={field.name}
              type={field.type}
              label={field.label}
              params={field.params}
              register={register}
            />
          </div>
        ))}
      </div>

      <div className="auth__buttons">
        <button className="button button--black" type="submit">
          Установить
        </button>
      </div>
    </form>
  );
};

export default Password;
