import React from "react";
import OutsideClickHandler from "react-outside-click-handler";

const FiltersModal = ({ children, onClose }) => {
  return (
    <OutsideClickHandler onOutsideClick={onClose}>
      <div className="filters__modal">{children}</div>
    </OutsideClickHandler>
  );
};

export default FiltersModal;
