import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useWatch } from "react-hook-form";
import Icon from "components/Icon/Icon";

const InputUpload = ({
  name,
  label,
  params,
  register,
  control,
  setValue,
  isMultiple,
  isError,
  clearErrors
}) => {
  const currentValues = useWatch({
    control,
    name: name,
  });

  const isMobile = useSelector((state) => state.isMobile);
  const currentFiles = currentValues ? [...currentValues] : [];
  const isDisabled = currentFiles.length === 10;

  useEffect(() => {
    if (currentFiles.length > 10) {
      const updatedFiles = currentFiles.filter((_file, i) => i <= 9);
      setValue(name, updatedFiles);
    }
  }, [currentFiles]);

  const clickFileUploadHandler = (e) => {
    if (isMultiple) {
      setValue(name, [...currentFiles, ...e.target.files]);
    } else {
      setValue(name, [...e.target.files]);
    }
    if (clearErrors) {
      clearErrors();
    }
  };

  const clickFileChangeHandler = () => {
    const input = document.getElementById(`${name}-file`);
    input.click();
  };

  const clickFileDeleteHandler = (fileToDelete) => {
    const updatedFiles = currentFiles.filter((_file, i) => i !== fileToDelete);
    setValue(name, updatedFiles);
  };

  return (
    <>
      <label className="form__label" htmlFor={name}>
        {label}
        {params.required ? <span>*</span> : <></>}
      </label>
      <div className="form__upload">
        {currentFiles.length ? (
          <div
            className={`form__upload-files ${
              isMultiple ? "form__upload-files--multiple" : ""
            }`}
          >
            {currentFiles.map((file, i) => (
              <div key={i + 1} className="form__upload-file">
                <Icon name={isMobile ? "file-mobile" : "file"} />
                <p className="form__upload-filename">{file.name}</p>
                <div className="form__upload-buttons">
                  {!isMultiple && (
                    <button
                      className="form__upload-button form__upload-button--edit"
                      type="button"
                      onClick={clickFileChangeHandler}
                    >
                      {!isMobile && <Icon name="edit" />}
                    </button>
                  )}
                  <button
                    className="form__upload-button form__upload-button--delete"
                    type="button"
                    onClick={() => clickFileDeleteHandler(i)}
                  >
                    <Icon name="delete" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          ""
        )}

        <div
          className={`form__upload-area ${
            currentFiles.length && !isMultiple
              ? "form__upload-area--hidden"
              : isError ? "form__upload-area--error" : ""
          }`}
        >
          <div className="form__upload-field">
            <Icon name={isMobile ? "clip" : "upload"} />
            {!isDisabled ? (
              !isMobile ? (
                <p className="form__upload-text">
                  Перетащите сюда файл или{" "}
                  <span className="form__link">загрузите</span>
                </p>
              ) : (
                <p className="form__upload-text">Выберите файл</p>
              )
            ) : (
              ""
            )}
            {isDisabled && (
              <p className="form__upload-text form__upload-text--error">
                Нельзя загрузить больше 10 дополнительных файлов
              </p>
            )}
          </div>

          <input
            type="file"
            id={`${name}-file`}
            className="form__upload-input"
            onChange={clickFileUploadHandler}
            accept="image/*"
            multiple={isMultiple}
            disabled={isDisabled}
          />
          <input
            type="hidden"
            name={name}
            id={name}
            {...register(name, params)}
          />
        </div>
      </div>
    </>
  );
};

export default InputUpload;
