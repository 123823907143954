import React, { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import ApplicationItemImage from "components/ApplicationItemImage/ApplicationItemImage";
import ApplicationItemText from "components/ApplicationItemText/ApplicationItemText";
import Icon from "components/Icon/Icon";

const ApplicationImageUpload = ({
  name,
  value,
  alt,
  params,
  register,
  control,
  setValue,
  isMultiple,
}) => {
  const currentValues = useWatch({
    control,
    name: name,
  });

  const [imagePreview, setImagePreview] = useState("");
  const currentFiles = currentValues
    ? isMultiple
      ? [...currentValues]
      : [currentValues]
    : [];
  const isDisabled = currentFiles.length === 10;

  useEffect(() => {
    if (!currentFiles.length && value.length) {
      setValue(name, value);
      setImagePreview(value);
    }
  }, [value]);

  useEffect(() => {
    if (currentFiles.length > 10) {
      const updatedFiles = currentFiles.filter((_file, i) => i <= 9);
      const updatedPreview = imagePreview.filter((_preview, i) => i <= 9);
      setValue(name, updatedFiles);
      setImagePreview(updatedPreview);
    }
  }, [currentFiles]);

  const clickFileUploadHandler = (e) => {
    if (isMultiple) {
      setValue(name, [...currentFiles, ...e.target.files]);
      const newPreviews = [];
      [...e.target.files].map((file) => {
        newPreviews.push(URL.createObjectURL(file));
      });
      setImagePreview([...imagePreview, ...newPreviews]);
    } else {
      setValue(name, [...e.target.files]);
      setImagePreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  const clickAddButtonHandler = () => {
    const input = document.getElementById(`${name}-file`);
    input.click();
  };

  const clickFileDeleteHandler = (fileToDelete) => {

    const updatedFiles = currentFiles.filter((_file, i) => i !== fileToDelete);
    const updatedPreview = imagePreview.filter(
      (_preview, i) => i !== fileToDelete
    );
    setValue(name, updatedFiles);
    setImagePreview(updatedPreview);
  };

  const renderUploadButton = (icon) => {
    return (
      <span className="application__button-upload button button--round button--black">
        <Icon name={icon} />
        <input
          type="file"
          id={`${name}-file`}
          className="form__upload-input"
          onChange={clickFileUploadHandler}
          accept="image/*"
          multiple={isMultiple}
          disabled={isDisabled}
        />
        <input
          type="hidden"
          name={name}
          id={name}
          {...register(name, params)}
        />
      </span>
    );
  };

  return (
    <>
      {isMultiple ? (
        <div className="application__images">
          {imagePreview.length
            ? imagePreview.map((image, i) => {
                return (
                  <div key={i + 1} className="application__item-img-wrapper">
                    <ApplicationItemImage image={image} alt={alt} />
                    <button
                      className="application__button-delete-img button button--round button--black"
                      type="button"
                      onClick={() => clickFileDeleteHandler(i)}
                    >
                      <Icon name="delete" />
                    </button>
                  </div>
                );
              })
            : ""}
          {!isDisabled ? (
            <button
              type="button"
              className="application__button-add"
              onClick={clickAddButtonHandler}
            >
              {renderUploadButton("add")}
              <span>Добавить фотографии</span>
            </button>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="application__item-img-wrapper">
          {imagePreview ? (
            <>
              <ApplicationItemImage image={imagePreview} alt={alt} />
              {renderUploadButton("edit")}
            </>
          ) : (
            <ApplicationItemText modClass={"application__item-text--no-img"}>
              {renderUploadButton("add")}
              <span>Добавить фотографии</span>
            </ApplicationItemText>
          )}
        </div>
      )}
    </>
  );
};

export default ApplicationImageUpload;
