import React from 'react';

import { Status } from "utils/const";

export const formatPhoneNumber = (value) => {
  const pattern = "## ### ### ## ##";
  let i = 0;
  return pattern.replace(/#/g, (_) => value[i++]);
};

export const formatDate = (seconds) => {
  return new Date(seconds * 1000).toLocaleString("ru", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
};

export const getDateOverdue = (date, target) => {
  const current = date !== undefined ? new Date(date.seconds * 1000) : (new Date(0)).seconds;
  const deadline = target !== undefined ? new Date(target.seconds * 1000) : (new Date(0)).seconds;
  console.log("target");
  console.log(target);
  console.log("deadline");
  console.log(deadline);

  deadline.setFullYear(deadline.getFullYear() + 1);
  //return current.getTime() <= deadline.getTime();
  return current <= (deadline ?? 0);
};

export const getSum = (visitCost, worksCost, spares) => {
  let sparesCost = 0;
  let sparesString = [];

  if (spares.length) {
    spares.forEach((spare) => {
      sparesCost += Number(spare.cost);
      sparesString.push(spare.cost);
    });
  }

  return (
    <>
      {Number(visitCost) + Number(worksCost) + sparesCost}
      <span className="application__item-text application__item-text--sum">
        {` = ${visitCost} + ${worksCost} ${
          sparesString.length ? `+ ${sparesString.join(" + ")}` : ""
        }`}
      </span>
    </>
  );
};

export const getSumNumber = (visitCost, worksCost, spares) => {
  let sparesCost = 0;

  if (spares.length) {
    spares.forEach((spare) => {
      sparesCost += Number(spare.cost);
    });
  }

  return Number(visitCost) + Number(worksCost) + sparesCost;
};

export const getExternalComment = (status, comments) => {
  let comment;

  switch (status) {
    case "incomplete":
      comment = comments.incomplete;
      break;
    case "rejection":
      comment = comments.rejection;
      break;
    default:
      comment = comments?.additional ? comments.additional : "";
      break;
  }

  return comment;
};

export const filterByCheckedValues = (data, filters, key) => {
  return data.filter((item) => {
    return filters.some((value) => {
      return value === item[key];
    });
  });
};

export const filterByCheckedValue = (data, filter, parentKey, key) => {
  return data.filter((item) => {
    return filter === item[parentKey][key];
  });
};

export const filterByDate = (data, min, max) => {
  return data.filter((item) => {
    const keyDate = new Date(item.date.seconds * 1000);
    const isDateMoreThanMin = min ? keyDate.getTime() >= min.getTime() : true;
    const isDateLessThanMax = max ? keyDate.getTime() <= max.getTime() : true;
    return isDateMoreThanMin && isDateLessThanMax;
  });
};

export const filterBySum = (data, min, max) => {
  return data.filter((item) => {
    let sparesCost = 0;
    if (item.spares.length) {
      item.spares.forEach((spare) => {
        sparesCost += Number(spare.cost);
      });
    }

    const sum = Number(item.visitCost) + Number(item.worksCost) + sparesCost;
    return sum >= min && sum <= max;
  });
};

export const filterBySpare = (data, sparesValue, spareName) => {
  return data.filter((item) => {
    if (sparesValue === "withSpares") {
      let isSpareIncludes = true;

      if (spareName) {
        isSpareIncludes = item.spares.some(
          (spare) => spare.title === spareName
        );
      }

      return item.isSpares === "yes" && isSpareIncludes;
    } else {
      return item.isSpares === "no";
    }
  });
};

export const searchInApplications = (filteredData, query) => {
  let searchKeys = Object.keys(filteredData[0]);
  return filteredData.filter((item) => {
    return searchKeys.some((key) => {
      switch (key) {
        case "launchDate":
          return formatDate(item[key].seconds).includes(query);
        case "serial":
          return item[key].toLowerCase().includes(query.toLowerCase());
        case "date":
          return formatDate(item[key].seconds).includes(query);
        case "guaranteePeriod":
          return item[key].toLowerCase().includes(query.toLowerCase());
        case "spares":
          return item[key].some((spare) =>
            spare.title.toLowerCase().includes(query.toLowerCase())
          );
        case "status":
          return Status[item[key]].name
            .toLowerCase()
            .includes(query.toLowerCase());
        case "comment":
          return item[key].toLowerCase().includes(query.toLowerCase());
        case "client":
          const clientKeys = Object.keys(item[key]);

          return clientKeys.some((clientKey) => {
            return item[key][clientKey]
              .toLowerCase()
              .includes(query.toLowerCase());
          });
        case "model":
          return item[key].toLowerCase().includes(query.toLowerCase());
        case "worksCost":
          return item[key].toLowerCase().includes(query.toLowerCase());
        case "visitCost":
          return item[key].toLowerCase().includes(query.toLowerCase());
        case "number":
          return item[key]
            .toString()
            .toLowerCase()
            .includes(query.toLowerCase());
        case "breakdown":
          return item[key].toLowerCase().includes(query.toLowerCase());
        case "serviceDate":
          return (
            formatDate(item[key].first?.seconds).includes(query) ||
            formatDate(item[key].second?.seconds).includes(query) ||
            formatDate(item[key].third?.seconds).includes(query)
          );
        case "applicant":
          const applicantKeys = Object.keys(item[key]).filter(
            (keyItem) => keyItem !== "uid"
          );
          return applicantKeys.some((applicantKey) => {
            return item[key][applicantKey]
              .toLowerCase()
              .includes(query.toLowerCase());
          });
        default:
         return false;
      }
    });
  });
};
