import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth } from "hooks/useAuth";
import {
  setFilteredData,
  setStep,
  setEditedApplication,
} from "store/slice";
import { getApplications, getApplicationsByUserId } from "store/async";
import ApplicationsNavigation from "components/ApplicationsNavigation/ApplicationsNavigation";
import ApplicationsTable from "components/ApplicationsTable/ApplicationsTable";
import Filters from "components/Filters/Filters";
import EmptyPage from "components/EmptyPage/EmptyPage";
import {
  filterByCheckedValues,
  filterByCheckedValue,
  filterByDate,
  filterBySum,
  filterBySpare,
  searchInApplications,
} from "utils/utils";
import { APPLICATIONS_PER_PAGE } from "utils/const";

const Applications = () => {
  const dispatch = useDispatch();
  const applicationsData = useSelector((state) => state.applications);
  const filters = useSelector((state) => state.filters);
  const query = useSelector((state) => state.query);
  const applicationCreatingStep = useSelector((state) => state.applicationStep);
  const editedApplication = useSelector((state) => state.editedApplication);

  const [isLoading, setLoading] = useState(true);
  const [applications, setApplications] = useState([]);
  const [isFiltersOpened, setFiltersOpened] = useState(false);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const { isAuth, isAdmin, uid } = useAuth();

  useEffect(() => {
    if (isAuth) {
      if (isAdmin) {
        dispatch(getApplications());
      } else {
        dispatch(getApplicationsByUserId(uid));
      }
    }
  }, []);

  useEffect(() => {
    if (applicationCreatingStep > 1) {
      dispatch(setStep(1));
    }
  }, [applicationCreatingStep]);

  useEffect(() => {
    if (editedApplication) {
      dispatch(setEditedApplication(null));
    }
  }, [editedApplication]);

  useEffect(() => {
    if (applicationsData?.length) {
      let filteredData = applicationsData;

      if (filters.status?.values?.length) {
        filteredData = filterByCheckedValues(
          filteredData,
          filters.status.values,
          "status"
        );
      }

      if (filters.date?.count) {
        filteredData = filterByDate(
          filteredData,
          filters.date.min,
          filters.date.max
        );
      }

      if (filters.model?.values?.length) {
        filteredData = filterByCheckedValues(
          filteredData,
          filters.model.values,
          "model"
        );
      }

      if (filters.region?.count) {
        filteredData = filterByCheckedValue(
          filteredData,
          filters.region.value,
          "applicant",
          "region"
        );
      }

      if (filters.sum?.count) {
        filteredData = filterBySum(
          filteredData,
          filters.sum.min,
          filters.sum.max
        );
      }

      if (filters.spare?.count) {
        filteredData = filterBySpare(
          filteredData,
          filters.spare.sparesValue,
          filters.spare.spareName
        );
      }

      if (query && filteredData.length) {
        filteredData = searchInApplications(filteredData, query);
      }

      setApplications(filteredData);
      dispatch(setFilteredData(filteredData));
    }
  }, [applicationsData, filters, query]);

  useEffect(() => {
    if (applications) {
      const endOffset = itemOffset + APPLICATIONS_PER_PAGE;
      const newPageCount = Math.ceil(
        applications.length / APPLICATIONS_PER_PAGE
      );
      setCurrentItems(applications.slice(itemOffset, endOffset));
      setPageCount(newPageCount);

      if (applications.length && itemOffset >= applications.length) {
        const firstPage = document.querySelector('[aria-label="Page 1"]');
        firstPage.click();
      }
    }
  }, [itemOffset, applications]);

  useEffect(() => {
    if (currentItems?.length) {
      setLoading(false);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, [currentItems]);

  const filtersButtonClickHandler = () => {
    setFiltersOpened(!isFiltersOpened);
  };

  const pageChangeHandler = (e) => {
    const newOffset =
      (e.selected * APPLICATIONS_PER_PAGE) % applications?.length;
    setItemOffset(newOffset);
  };

  return (
    <section className="applications">
      <ApplicationsNavigation
        isAdmin={isAdmin}
        onFiltersClick={filtersButtonClickHandler}
        pageCount={pageCount}
        onPageChange={pageChangeHandler}
      />
      {isAdmin ? <Filters isFiltersOpened={isFiltersOpened} /> : ""}
      {isLoading ? (
        <p>Подождите...</p>
      ) : currentItems?.length ? (
        <ApplicationsTable applications={currentItems} isAdmin={isAdmin} />
      ) : (
        <EmptyPage
          isQuery={query.length}
          isFilters={applicationsData.length && !applications.length}
          isAdmin={isAdmin}
          page={"applications"}
        />
      )}
    </section>
  );
};

export default Applications;
