import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUsers } from "store/async";
import UsersNavigation from "components/UsersNavigation/UsersNavigation";
import UsersTable from "components/UsersTable/UsersTable";
import EmptyPage from "components/EmptyPage/EmptyPage";

const Users = () => {
  const dispatch = useDispatch();
  const usersData = useSelector((state) => state.users);
  const query = useSelector((state) => state.query);

  const [isLoading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  useEffect(() => {
    if (usersData?.length) {
      setLoading(false);
      let filterKeys = Object.keys(usersData[0]);
      filterKeys.shift();
      let filteredData;
      if (query) {
        filteredData = usersData.filter((item) => {
          return filterKeys.some((key) => {
            return item[key]?.toLowerCase().includes(query.toLowerCase());
          });
        });
      } else {
        filteredData = usersData;
      }

      setUsers(filteredData);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, [usersData, query]);

  return (
    <section className="users">
      <UsersNavigation />
      {isLoading ? (
        <p>Подождите...</p>
      ) : users.length ? (
        <UsersTable users={users} />
      ) : (
        <EmptyPage
        isQuery={query.length}
        isAdmin={true}
        page={"users"}
      />
      )}
    </section>
  );
};

export default Users;
