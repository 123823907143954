import React from "react";
import Applications from "components/Applications/Applications";

import Page from "components/Page/Page";

const MainPage = () => {
  return (
    <Page>
      <Applications />
    </Page>
  );
};

export default MainPage;
