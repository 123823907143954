import React from "react";

const InputText = ({ name, label, register, params, placeholder, alert }) => {

  return (
    <>
      {label ? (
        <label className="form__label" htmlFor={name}>
          {label}
          {params.required ? <span>*</span> : <></>}
          {alert ? <span className="form__alert">{alert}</span> : <></>}
        </label>
      ) : (
        ""
      )}
      <textarea
        className="form__input form__input--textarea"
        name={name}
        id={name}
        {...register(name, params)}
        placeholder={placeholder}
      ></textarea>
    </>
  );
};

export default InputText;
