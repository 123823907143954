import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { loginUser } from "store/async";
import {
  clearLoginError,
  clearResetError,
  clearResetSuccess,
} from "store/slice";
import Input from "components/Input/Input";
import { Forms, AppPath, Errors } from "utils/const";

const { loginMeta } = Forms;

const Login = () => {
  const dispatch = useDispatch();
  const errorCode = useSelector((state) => state.loginError);

  useEffect(() => {
    dispatch(clearResetError());
    dispatch(clearResetSuccess());
  });

  const { register, handleSubmit } = useForm();

  const changeInputHandler = () => {
    if (errorCode) {
      dispatch(clearLoginError());
    }
  };

  const onSubmit = (data) => {
    dispatch(loginUser(data));
  };

  return (
    <form className="form auth__form" onSubmit={handleSubmit(onSubmit)}>
      <div className="auth__fieldset">
        {loginMeta.map((field, i) => (
          <div key={i + 1} className="auth__field">
            <Input
              name={field.name}
              type={field.type}
              label={field.label}
              params={{ ...field.params, onChange: changeInputHandler }}
              register={register}
              placeholder={field.placeholder}
              isError={errorCode && Errors[errorCode].type === field.name}
            />
          </div>
        ))}
      </div>

      <div className="auth__buttons">
        <button className="button button--black" type="submit">
          Войти
        </button>
        <Link to={AppPath.RESET} className="auth__link">
          Забыли пароль?
        </Link>
        {errorCode && (
          <p className="auth__error">{Errors[errorCode].message}</p>
        )}
      </div>
    </form>
  );
};

export default Login;
