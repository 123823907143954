import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setIsApplicationCreating, setEditedApplication } from "store/slice";
import ApplicationComment from "components/ApplicationComment/ApplicationComment";
import Icon from "components/Icon/Icon";
import { formatDate, getExternalComment } from "utils/utils";
import { StatusComment } from "utils/const";

const ApplicationHeader = ({
  number,
  date,
  status,
  daesungComments,
  isEdit,
}) => {
  const dispatch = useDispatch();
  const isMobile = useSelector((state) => state.isMobile);
  const isNewApplication = useSelector((state) => state.isApplicationCreating);
  const isApplicationUpdated = useSelector(
    (state) => state.isApplicationUpdated
  );

  useEffect(() => {
    if (isNewApplication) {
      setTimeout(() => {
        dispatch(setIsApplicationCreating(false));
      }, 5000);
    }
  }, [isNewApplication]);

  const comment = getExternalComment(status, daesungComments);
  const isCommentAdditional = status !== "incomplete" && status !== "rejection";

  const cancelEditButtonClick = () => {
    dispatch(setEditedApplication(null));
  };

  return (
    <div className="application__header">
      <div className="application__header-wrapper">
        {!isMobile && <h2 className="application__title title">{`Заявка #${number} от ${formatDate(
          date.seconds
        )}`}</h2>}
        {isEdit && !isApplicationUpdated ? (
          <div className="application__buttons">
            <button
              className="application__button-cancel button button--medium-gray"
              type="button"
              onClick={cancelEditButtonClick}
            >
              Отмена
            </button>
            <button
              className="application__button-save button button--black"
              type="submit"
            >
              <Icon name="check" />
              <span>Сохранить изменения</span>
            </button>
          </div>
        ) : isApplicationUpdated ? (
          <p className="application__alert button button--alert button--green">
            <Icon name="check" />
            <span>Изменения сохранены</span>
          </p>
        ) : (
          isNewApplication && (
            <p className="button button--alert button--green">
              Заявка успешно создана!
            </p>
          )
        )}
      </div>
      {!isEdit ? (
          <>
            <ApplicationComment
                title={StatusComment[status].title}
                message={
                  comment && !isCommentAdditional
                      ? comment
                      : StatusComment[status].message
                }
                additionalComment={comment && isCommentAdditional ? comment : null}
                isEditAvailable={status === "incomplete"}
                number={number}
            />
          </>
      ) : (
        ""
      )}
    </div>
  );
};

export default ApplicationHeader;
