import React from "react";
import { formatDate, getDateOverdue } from "utils/utils";

const ApplicationServiceDates = ({ dates, launchDate }) => {
  const { first, second, third } = dates;

  const isFirstValid = getDateOverdue(first, launchDate);
  const isSecondValid = second && getDateOverdue(second, first) && isFirstValid;
  const isThirdValid =
    third && getDateOverdue(third, launchDate) && isFirstValid;

  return (
    <>
      <span className={isFirstValid ? "" : "application__date-overdue"}>
        {first !== undefined ? formatDate(first.seconds) : formatDate((new Date(0)).seconds)}
      </span>
      {second && (
        <span>
          {` и `}
          <span className={isSecondValid ? "" : "application__date-overdue"}>
            {formatDate(second.seconds)}
          </span>
        </span>
      )}
      {third && (
        <span>
          {` и `}
          <span className={isThirdValid ? "" : "application__date-overdue"}>
            {formatDate(third.seconds)}
          </span>
        </span>
      )}
    </>
  );
};

export default ApplicationServiceDates;
