import React, { useState } from "react";
import getAddresses from "utils/getAddresses";
import Input from "components/Input/Input";

const InputPrompt = ({ type, name, label, register, params, setValue, isError, clearErrors }) => {
  const [prompt, setPrompt] = useState([]);
  const [isSelected, setSelected] = useState(false);

  const focusInputHandler = async (e) => {
    const { value } = e.target;
    const position = value.length;
    e.target.setSelectionRange(position, position);

    if (value) {
      const promptData = await getAddresses(value);
      setPrompt(promptData);

      if (!isSelected) {
        setSelected(true);
      }
    }
  };

  const changeInputHandler = async (e) => {
    setSelected(false);
    const promptData = await getAddresses(e.target.value);
    setPrompt(promptData);

    if (clearErrors) {
      clearErrors();
    }
  };

  const blurInputHandler = (e) => {
    if (
      e.relatedTarget &&
      e.relatedTarget.classList.contains("form__prompt-item")
    ) {
      return;
    } else {
      if (!isSelected) {
        setValue(name, "");
      }
      setPrompt([]);
    }
  };

  const clickPromptItemHandler = (selected) => {
    setValue(name, selected.value);
    setValue("region", selected.region);
    setSelected(true);
    setPrompt([]);
  };

  const newParams = {
    ...params,
    onChange: changeInputHandler,
    onBlur: blurInputHandler,
  };

  return (
    <>
      <Input
        name={name}
        type={type}
        label={label}
        params={newParams}
        register={register}
        onFocus={focusInputHandler}
        isError={isError}
      />
      {prompt.length ? (
        <div className="form__prompt">
          {prompt.map((item, i) => (
            <p
              key={i + 1}
              className="form__prompt-item"
              tabIndex="0"
              onClick={() => clickPromptItemHandler(item)}
            >
              {item.value}
            </p>
          ))}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default InputPrompt;
