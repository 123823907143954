import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Pagination from "components/Pagination/Pagination";
import InputSearch from "components/InputSearch/InputSearch";
import Icon from "components/Icon/Icon";
import { AppPath } from "utils/const";

const ApplicationsNavigation = ({
  isAdmin,
  pageCount,
  onFiltersClick,
  onPageChange,
}) => {
  const isMobile = useSelector((state) => state.isMobile);
  return (
    <div
      className={`applications__navigation ${
        isAdmin ? "applications__navigation--admin" : ""
      }`}
    >
      <div className="applications__buttons">
        {isAdmin ? (
          !isMobile && (
            <button
              className="button button--gray applications__filters-btn"
              type="button"
              onClick={onFiltersClick}
            >
              <span>Фильтры</span>
              <Icon name="filters" />
            </button>
          )
        ) : (
          <Link
            to={`${AppPath.APPLICATION}/add`}
            className="button button--black applications__add-btn"
          >
            <Icon name="plus" />
            <span>Новая заявка</span>
          </Link>
        )}
        <InputSearch />
      </div>
      <div className="applications__pagination">
        <p className="applications__pagination-label">Страница</p>
        <Pagination pageCount={pageCount} onPageChange={onPageChange} />
      </div>
    </div>
  );
};

export default ApplicationsNavigation;
