import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Page from "components/Page/Page";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Application from "components/Application/Application";
import ApplicationEdit from "components/ApplicationEdit/ApplicationEdit";
import { formatDate } from "utils/utils";

const ApplicationPage = () => {
  const { id } = useParams();
  const editedApplication = useSelector((state) => state.editedApplication);
  const isEdit = editedApplication && editedApplication === Number(id);

  return (
    <Page>
      <Breadcrumbs
        path={"/"}
        btnText={"Все заявки"}
        currentPage={`Заявка #${id}`}
      />
      {isEdit ? (
        <ApplicationEdit number={editedApplication} />
      ) : (
        <Application />
      )}
    </Page>
  );
};

export default ApplicationPage;
