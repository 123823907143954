export const MOBILE_VIEW = 767;

export const APPLICATIONS_PER_PAGE = 5;

export const AppPath = {
  ROOT: "/",
  LOGIN: "/login",
  PASSWORD: "/password",
  RESET: "/reset",
  USERS: "/users",
  APPLICATION: "/application",
  APPLICATIONS: "/applications",
};

export const AuthPage = {
  LOGIN: {
    title: "| Вход",
    subtitle: "Чтобы начать работу с заявками, пожалуйста, войдите в систему.",
  },
  RESET: {
    title: "| Забыли пароль",
    subtitle:
      "Вы можете сбросить старый пароль. <br/>Мы отправим ссылку для входа вам на&nbspe-mail.",
  },
  PASSWORD: {
    title: "| Новый пароль",
    subtitle: "Установите новый пароль для входа в&nbspаккаунт.",
  },
};

export const Forms = {
  loginMeta: [
    {
      name: "email",
      type: "email",
      label: "E-mail:",
      params: {
        required: "required",
        pattern: {
          value: /\S+@\S+\.\S+/,
          message: "Entered value does not match email format",
        },
      },
      placeholder: "Введите свой e-mail",
    },
    {
      name: "password",
      type: "password",
      label: "Пароль:",
      params: {
        required: "required",
      },
      placeholder: "Введите свой пароль",
    },
  ],
  resetMeta: [
    {
      name: "email",
      type: "email",
      label: "E-mail:",
      params: {
        required: "required",
        pattern: {
          value: /\S+@\S+\.\S+/,
          message: "Entered value does not match email format",
        },
      },
      placeholder: "Введите свой e-mail",
    },
  ],
  passwordMeta: [
    {
      name: "password",
      type: "password",
      label: "Новый пароль:",
      params: {
        required: "required",
      },
    },
  ],
  userMeta: [
    {
      name: "company",
      type: "text",
      label: "Компания:",
      params: {
        required: "required",
      },
    },
    {
      name: "inn",
      type: "mask",
      label: "ИНН:",
      params: {
        required: "required",
        minLength: 10,
      },
      mask: "999999999999",
      formatChars: {
        9: "[0-9]",
      },
    },
    {
      name: "ogrn",
      type: "mask",
      label: "ОГРН:",
      params: {
        minLength: 13,
      },
      mask: "9999999999999",
      formatChars: {
        9: "[0-9]",
      },
    },
    {
      name: "address",
      type: "text",
      label: "Адрес:",
      params: {
        required: "required",
      },
    },
    {
      name: "contact",
      type: "text",
      label: "Контакт:",
      params: {
        required: "required",
      },
    },
    {
      name: "phone",
      type: "mask",
      label: "Телефон:",
      params: {
        required: "required",
        minLength: 16,
      },
      mask: "+7 999 999-99-99",
      formatChars: {
        9: "[0-9]",
      },
    },
    {
      name: "email",
      type: "email",
      label: "E-mail:",
      params: {
        required: "required",
        pattern: {
          value: /\S+@\S+\.\S+/,
          message: "Entered value does not match email format",
        },
      },
    },
  ],
};

export const Errors = {
  "auth/email-already-exists": {
    type: "email",
    message: "Пользователь с таким e-mail уже существует",
  },
  "auth/user-not-found": {
    type: "email",
    message: "Пользователь с таким e-mail не найден",
  },
  "auth/wrong-password": {
    type: "password",
    message: "Пароль указан не верно",
  },
  "auth/too-many-requests": {
    type: "too-many",
    message: "Превышено число запросов к серверу. Попробуйте позже.",
  },
};

export const UsersTableMeta = [
  {
    id: "company",
    label: "Компания",
  },
  {
    id: "region",
    label: "Регион",
  },
  {
    id: "contact",
    label: "Контактное лицо",
  },
  {
    id: "phone",
    label: "Телефон",
  },
  {
    id: "email",
    label: "E-mail",
  },
];

export const ApplicationsTableMeta = [
  {
    id: "status",
    label: "Статус",
  },
  {
    id: "date",
    label: "Дата",
  },
  {
    parentId: "applicant",
    id: "company",
    label: "Компания",
    isAdminOnly: true,
  },
  {
    parentId: "applicant",
    id: "region",
    label: "Регион",
    isAdminOnly: true,
  },
  {
    id: "model",
    label: "Модель",
  },
  {
    id: "breakdown",
    label: "Что сломалось?",
    labelMobile: "Поломка",
  },
  {
    id: "number",
    label: "Номер",
  },
];

export const ApplicationCreateFormSteps = [
  {
    number: 1,
    title: "Информация о клиенте",
    description:
      "Заполните информацию о клиенте, для которого выполняли ремонт.",
    fields: [
      {
        name: "name",
        type: "text",
        label: "Имя:",
        params: {
          required: "required",
        },
      },
      {
        name: "address",
        type: "text",
        label: "Адрес:",
        params: {
          required: "required",
        },
      },
      {
        name: "phone",
        type: "mask",
        label: "Контактный телефон:",
        params: {
          required: "required",
          minLength: 16,
        },
        mask: "+7 999 999-99-99",
        formatChars: {
          9: "[0-9]",
        },
      },
    ],
  },
  {
    number: 2,
    title: "Информация об оборудовании",
    description:
      "Заполните информацию об оборудовании, ремонт которого выполняли",
    fields: [
      {
        name: "model",
        type: "text",
        label: "Модель:",
        params: {
          required: "required",
        },
        fieldType: "select",
        emptyMessage:
          "Нет подходящих моделей. Возможно вам нужно поменять язык ввода.",
        placeholder: "Введите модель...",
      },
      {
        name: "serial",
        type: "mask",
        label: "Серийный номер:",
        params: {
          required: "required",
        },
        mask: "***************",
        formatChars: {
          "*": "[A-Z0-9]",
        },
      },
      {
        name: "nameplate",
        type: "file",
        label: "Шильдик:",
        params: {},
      },
    ],
    instruction: {
      topText:
        "Модель и серийный номер котла указаны на шильдике — информационной наклейке на корпусе котла",
      scheme: {
        img: "/assets/nameplate.jpg",
        name: "Шильдик",
      },
      markers: "/assets/nameplate-markers.svg",
      markersMobile: "/assets/nameplate-markers-mobile.svg",
      bottomText:
        "Пожалуйста, проверьте, что цифры на фото хорошо различимы, иначе мы не сможем принять вашу заявку!",
    },
  },
  {
    number: 3,
    title: "Информация о гарантии",
    fields: [
      {
        name: "launchDate",
        type: "date",
        label: "Дата запуска:",
        params: {
          required: "required",
        },
      },
      {
        name: "guaranteePeriod",
        type: "text",
        label: "Гарантийный срок:",
        params: {
          required: "required",
        },
      },
      {
        name: "isService",
        type: "toggle",
        label: "Тех. обслуживание:",
        params: {
          required: "required",
        },
        buttons: [
          {
            value: "yes",
            label: "Да",
          },
          {
            value: "no",
            label: "Нет",
          },
        ],
      },
      {
        name: "guarantee",
        type: "file",
        label: "Гарантийный талон:",
        params: {
          required: "required",
        },
      },
    ],
    hiddenFields: [
      {
        name: "serviceDate",
        fieldName: "first",
        type: "date",
        label: "Дата 1 тех.обсл.:",
        labelMobile: "Дата 1 тех. обслуживания:",
        params: {},
      },
      {
        name: "serviceDate",
        fieldName: "second",
        type: "date",
        label: "Дата 2 тех.обсл.:",
        labelMobile: "Дата 2 тех. обслуживания:",
        params: {},
      },
      {
        name: "serviceDate",
        fieldName: "third",
        type: "date",
        label: "Дата 3 тех.обсл.:",
        labelMobile: "Дата 3 тех. обслуживания:",
        params: {},
      },
    ],
    instruction: {
      topText:
        "Информацию о гарантийном сроке и датах запуска и обслуживания котла можно найти на гарантийном талоне. ",
      scheme: {
        img: "/assets/guarantee.jpg",
        name: "Гарантийный талон",
      },
      markers: "/assets/guarantee-markers.svg",
      markersMobile: "/assets/guarantee-markers-mobile.svg",
      bottomText:
        "Пожалуйста, проверьте, что цифры на фото хорошо различимы, иначе мы не сможем принять вашу заявку!",
    },
  },
  {
    number: 4,
    title: "Информация о ремонтных работах",
    fields: [
      {
        name: "breakdown",
        type: "text",
        label: "Поломка:",
        params: {
          required: "required",
        },
        placeholder: "Кратко опишите что сломалось",
      },
      {
        name: "visitCost",
        type: "mask",
        label: "Стоимость выезда, ₽:",
        params: {
          required: "required",
        },
        mask: "9999999",
        formatChars: {
          9: "[0-9]",
        },
        placeholder: "1300 ₽",
      },
      {
        name: "worksCost",
        type: "mask",
        label: "Стоимость работ, ₽:",
        params: {
          required: "required",
        },
        mask: "9999999",
        formatChars: {
          9: "[0-9]",
        },
        placeholder: "1300 ₽",
      },
      {
        name: "act",
        type: "file",
        label: "Акт выполненных работ:",
        params: {
          required: "required",
        },
      },
    ],
    instruction: {
      topText: "Пример акта о проведении гарантийного ремонта:",
      scheme: {
        img: "/assets/act.jpg",
        name: "Aкт о проведении гарантийного ремонта",
      },
      markers: "",
      bottomText: "Стандартная стоимость выезда и работ  - 1300 ₽.",
    },
  },
  {
    number: 5,
    title: "Информация о запасных частях",
    fields: [
      {
        name: "isSpares",
        type: "toggle",
        label: "Использовались ли запасные части?",
        params: {
          required: "required",
        },
        buttons: [
          {
            value: "yes",
            label: "Да",
          },
          {
            value: "no",
            label: "Нет",
          },
        ],
      },
    ],
    hiddenFields: [
      {
        name: "spares",
        title: {
          fieldName: "title",
          type: "text",
          label: "Запасная часть:",
          params: {},
          fieldType: "select",
          emptyMessage: "Нет подходящих запчастей",
          placeholder: "Введите название или артикул...",
        },
        cost: {
          fieldName: "cost",
          type: "mask",
          label: "Стоимость, ₽:",
          params: {},
          mask: "9999999",
          formatChars: {
            9: "[0-9]",
          },
          placeholder: "0 ₽",
        },
      },
      {
        name: "spares",
        title: {
          fieldName: "title",
          type: "text",
          label: "Запасная часть:",
          params: {},
          fieldType: "select",
          emptyMessage: "Нет подходящих запчастей",
          placeholder: "Введите название или артикул...",
        },
        cost: {
          fieldName: "cost",
          type: "mask",
          label: "Стоимость, ₽:",
          params: {},
          mask: "9999999",
          formatChars: {
            9: "[0-9]",
          },
          placeholder: "0 ₽",
        },
      },
      {
        name: "spares",
        title: {
          fieldName: "title",
          type: "text",
          label: "Запасная часть:",
          params: {},
          fieldType: "select",
          emptyMessage: "Нет подходящих запчастей",
          placeholder: "Введите название или артикул...",
        },
        cost: {
          fieldName: "cost",
          type: "mask",
          label: "Стоимость, ₽:",
          params: {},
          mask: "9999999",
          formatChars: {
            9: "[0-9]",
          },
          placeholder: "0 ₽",
        },
      },
      {
        name: "spares",
        title: {
          fieldName: "title",
          type: "text",
          label: "Запасная часть:",
          params: {},
          fieldType: "select",
          emptyMessage: "Нет подходящих запчастей",
          placeholder: "Введите название или артикул...",
        },
        cost: {
          fieldName: "cost",
          type: "mask",
          label: "Стоимость, ₽:",
          params: {},
          mask: "9999999",
          formatChars: {
            9: "[0-9]",
          },
          placeholder: "0 ₽",
        },
      },
      {
        name: "spares",
        title: {
          fieldName: "title",
          type: "text",
          label: "Запасная часть:",
          params: {},
          fieldType: "select",
          emptyMessage: "Нет подходящих запчастей",
          placeholder: "Введите название или артикул...",
        },
        cost: {
          fieldName: "cost",
          type: "mask",
          label: "Стоимость, ₽:",
          params: {},
          mask: "9999999",
          formatChars: {
            9: "[0-9]",
          },
          placeholder: "0 ₽",
        },
      },
      {
        name: "spares",
        title: {
          fieldName: "title",
          type: "text",
          label: "Запасная часть:",
          params: {},
          fieldType: "select",
          emptyMessage: "Нет подходящих запчастей",
          placeholder: "Введите название или артикул...",
        },
        cost: {
          fieldName: "cost",
          type: "mask",
          label: "Стоимость, ₽:",
          params: {},
          mask: "9999999",
          formatChars: {
            9: "[0-9]",
          },
          placeholder: "0 ₽",
        },
      },
    ],
    instruction: {
      topText:
        "Уточнить названия и артикулы запчастей можно с помощью схемы оборудования:",
      scheme: {
        img: "/assets/equipment.jpg",
        name: "Схема оборудования",
      },
      markers: "",
      bottomText: "Стоимость запчастей вы найдёте в своих закупочных актах.",
    },
  },
  {
    number: 6,
    title: "Дополнительная информация",
    description:
      "Здесь вы можете по желанию оставить дополнительные изображения и текстовый коментарий, чтобы подробнее описать ситуацию.",
    fields: [
      {
        name: "comment",
        type: "textarea",
        label: "Ваш комментарий:",
        params: {},
        placeholder: "Прикладываю несколько дополнительных фото поломки...",
      },
      {
        name: "additionalImages",
        type: "file",
        label: "Дополнительные изображения:",
        params: {},
      },
    ],
  },
];

export const ApplicationEditBlocks = [
  {
    items: [
      {
        id: "status",
        title: "Статус:",
        type: "input",
        input: {
          name: "status",
          type: "text",
          params: {
            required: "required",
          },
          options: [
            {
              value: "inProcess",
            },
            {
              value: "incomplete",
            },
            {
              value: "rejection",
            },
            {
              value: "documentsAwait",
            },
            {
              value: "paymentAwait",
            },
            {
              value: "paid",
            },
          ],
        },
        modClass: "application__item-input--status",
      },
      { id: "date", title: "Дата заявки:", titleMobile: "Дата:" },
    ],
  },
  {
    items: [
      {
        id: "model",
        title: "Модель:",
        type: "input",
        input: {
          name: "model",
          type: "text",
          params: {
            required: "required",
          },
          emptyMessage:
            "Нет подходящих моделей. Возможно вам нужно поменять язык ввода.",
          placeholder: "Введите модель...",
        },
      },
      {
        id: "serial",
        title: "Серийный номер:",
        titleMobile: "Сер. номер:",
        type: "input",
        input: {
          name: "serial",
          params: {
            required: "required",
          },
          mask: "***************",
          formatChars: {
            "*": "[A-Z0-9]",
          },
        },
      },
      {
        id: "guaranteePeriod",
        title: "Срок гарантии:",
        titleMobile: "Гарантия:",
        type: "input",
        input: {
          name: "launchDate",
          type: "date",
          params: {
            required: "required",
          },
        },
        select: {
          name: "guaranteePeriod",
          type: "text",
          params: {
            required: "required",
          },
        },
        modClass: "application__item-input--guarantee",
      },
      {
        id: "isService",
        title: "Тех. обслуживание",
        titleMobile: "Тех. обсл.",
        type: "input",
        inputs: [
          {
            name: "serviceDate",
            fieldName: "first",
            params: {},
          },
          {
            name: "serviceDate",
            fieldName: "second",
            params: {},
          },
          {
            name: "serviceDate",
            fieldName: "third",
            params: {},
          },
        ],
        modClass: "application__item-input--service",
      },
    ],
  },
  {
    modClass: "application__block--large-right",
    items: [
      {
        id: "sum",
        title: "Сумма, ₽:",
        titleMobile: "none",
        type: "input",
        inputs: [
          {
            name: "visitCost",
            type: "mask",
            label: "Выезд",
            labelMobile: "Выезд:",
            params: {
              required: "required",
            },
            mask: "9999999",
            formatChars: {
              9: "[0-9]",
            },
            placeholder: "1300 ₽",
          },
          {
            name: "worksCost",
            type: "mask",
            label: "Работа",
            labelMobile: "Работа:",
            params: {
              required: "required",
            },
            mask: "9999999",
            formatChars: {
              9: "[0-9]",
            },
            placeholder: "1300 ₽",
          },
        ],
        modClass: "application__item-input--sum",
      },
      {
        id: "breakdown",
        title: "Что сломалось:",
        titleMobile: "Поломка:",
        type: "input",
        input: {
          name: "breakdown",
          type: "text",
          params: {
            required: "required",
          },
          placeholder: "Кратко опишите что сломалось",
        },
      },
      {
        id: "spares",

        inputs: [
          {
            name: "spares",
            title: {
              fieldName: "title",
              type: "text",
              label: "Запасная часть:",
              labelMobile: "Зап. часть:",
              params: {},
              fieldType: "select",
              emptyMessage: "Нет подходящих запчастей",
              placeholder: "Введите название или артикул...",
              modClass: "application__item-input--spare",
            },
            cost: {
              fieldName: "cost",
              type: "mask",
              label: "Цена",
              labelMobile: "Цена:",
              params: {},
              mask: "9999999",
              formatChars: {
                9: "[0-9]",
              },
              placeholder: "0 ₽",
              modClass: "application__item-input--cost",
            },
          },
          {
            name: "spares",
            title: {
              fieldName: "title",
              type: "text",
              label: "Запасная часть:",
              labelMobile: "Зап. часть:",
              params: {},
              fieldType: "select",
              emptyMessage: "Нет подходящих запчастей",
              placeholder: "Введите название или артикул...",
              modClass: "application__item-input--spare",
            },
            cost: {
              fieldName: "cost",
              type: "mask",
              label: "Цена",
              labelMobile: "Цена:",
              params: {},
              mask: "9999999",
              formatChars: {
                9: "[0-9]",
              },
              placeholder: "0 ₽",
              modClass: "application__item-input--cost",
            },
          },
          {
            name: "spares",
            title: {
              fieldName: "title",
              type: "text",
              label: "Запасная часть:",
              labelMobile: "Зап. часть:",
              params: {},
              fieldType: "select",
              emptyMessage: "Нет подходящих запчастей",
              placeholder: "Введите название или артикул...",
              modClass: "application__item-input--spare",
            },
            cost: {
              fieldName: "cost",
              type: "mask",
              label: "Цена",
              labelMobile: "Цена:",
              params: {},
              mask: "9999999",
              formatChars: {
                9: "[0-9]",
              },
              placeholder: "0 ₽",
              modClass: "application__item-input--cost",
            },
          },
          {
            name: "spares",
            title: {
              fieldName: "title",
              type: "text",
              label: "Запасная часть:",
              labelMobile: "Зап. часть:",
              params: {},
              fieldType: "select",
              emptyMessage: "Нет подходящих запчастей",
              placeholder: "Введите название или артикул...",
              modClass: "application__item-input--spare",
            },
            cost: {
              fieldName: "cost",
              type: "mask",
              label: "Цена",
              labelMobile: "Цена:",
              params: {},
              mask: "9999999",
              formatChars: {
                9: "[0-9]",
              },
              placeholder: "0 ₽",
              modClass: "application__item-input--cost",
            },
          },
          {
            name: "spares",
            title: {
              fieldName: "title",
              type: "text",
              label: "Запасная часть:",
              labelMobile: "Зап. часть:",
              params: {},
              fieldType: "select",
              emptyMessage: "Нет подходящих запчастей",
              placeholder: "Введите название или артикул...",
              modClass: "application__item-input--spare",
            },
            cost: {
              fieldName: "cost",
              type: "mask",
              label: "Цена",
              labelMobile: "Цена:",
              params: {},
              mask: "9999999",
              formatChars: {
                9: "[0-9]",
              },
              placeholder: "0 ₽",
              modClass: "application__item-input--cost",
            },
          },
          {
            name: "spares",
            title: {
              fieldName: "title",
              type: "text",
              label: "Запасная часть:",
              labelMobile: "Зап. часть:",
              params: {},
              fieldType: "select",
              emptyMessage: "Нет подходящих запчастей",
              placeholder: "Введите название или артикул...",
              modClass: "application__item-input--spare",
            },
            cost: {
              fieldName: "cost",
              type: "mask",
              label: "Цена",
              labelMobile: "Цена:",
              params: {},
              mask: "9999999",
              formatChars: {
                9: "[0-9]",
              },
              placeholder: "0 ₽",
              modClass: "application__item-input--cost",
            },
          },
        ],
      },
    ],
  },
  {
    modClass: "application__block--large-left",
    type: "images",
    items: [
      {
        id: "guarantee",
        title: "Гарантийный талон:",
        type: "image",
        input: {
          name: "guarantee",
          params: {
            required: "required",
          },
        },
      },
      {
        id: "act",
        title: "Акт ремонта:",
        type: "image",
        input: {
          name: "act",
          params: {
            required: "required",
          },
        },
      },
      {
        id: "nameplate",
        title: "Шильдик:",
        type: "image",
        input: {
          name: "nameplate",
          params: {},
        },
      },
    ],
  },
  {
    modClass: "application__block--right",
    items: [
      {
        parentId: "client",
        id: "name",
        title: "ФИО:",
        type: "input",
        input: {
          name: "name",
          type: "text",
          params: {
            required: "required",
          },
        },
      },
      {
        parentId: "client",
        id: "address",
        title: "Адрес:",
        type: "input",
        input: {
          name: "address",
          type: "text",
          params: {
            required: "required",
          },
        },
      },
      {
        parentId: "client",
        id: "phone",
        title: "Телефон:",
        type: "input",
        input: {
          name: "phone",
          type: "mask",
          params: {
            required: "required",
            minLength: 16,
          },
          mask: "+7 999 999-99-99",
          formatChars: {
            9: "[0-9]",
          },
        },
      },
    ],
  },

  {
    modClass: "application__block--right application__block--not-edit",
    items: [
      { parentId: "applicant", id: "company", title: "Компания:" },
      { parentId: "applicant", id: "inn", title: "ИНН:" },
      { parentId: "applicant", id: "ogrn", title: "ОГРН:" },
      { parentId: "applicant", id: "address", title: "Адрес:" },
    ],
  },
];

export const ApplicationBlocks = [
  {
    items: [
      { id: "status", title: "Статус:" },
      { id: "date", title: "Дата заявки:", titleMobile: "Дата:" },
    ],
  },
  {
    items: [
      { id: "model", title: "Модель:" },
      { id: "serial", title: "Серийный номер:", titleMobile: "Сер. номер:" },
      {
        id: "guaranteePeriod",
        title: "Срок гарантии:",
        titleMobile: "Гарантия:",
      },
      {
        id: "isService",
        title: "Тех. обслуживание:",
        titleMobile: "Тех. обсл.:",
      },
    ],
  },
  {
    modClass: "application__block--large-right",
    items: [
      { id: "sum", title: "Сумма, ₽:" },
      { id: "breakdown", title: "Что сломалось:", titleMobile: "Поломка:" },
      {
        id: "spares",
        title: "Запасная часть:",
        titleMobile: "Зап. часть:",
        modClass: "application__item-text--spare",
      },
    ],
  },
  {
    modClass: "application__block--large-left",
    type: "images",
    items: [
      { id: "guarantee", title: "Гарантийный талон:", type: "image" },
      { id: "act", title: "Акт ремонта:", type: "image" },
      { id: "nameplate", title: "Шильдик:", type: "image" },
    ],
  },
  {
    modClass: "application__block--right",
    items: [
      { parentId: "client", id: "name", title: "ФИО:" },
      { parentId: "client", id: "address", title: "Адрес:" },
      { parentId: "client", id: "phone", title: "Телефон:" },
    ],
  },
  {
    modClass: "application__block--right",
    items: [
      { parentId: "applicant", id: "company", title: "Компания:" },
      { parentId: "applicant", id: "inn", title: "ИНН:" },
      { parentId: "applicant", id: "ogrn", title: "ОГРН:" },
      { parentId: "applicant", id: "address", title: "Адрес:" },
    ],
  },
];

export const Status = {
  inProcess: {
    name: "На рассмотрении",
    color: "red",
  },
  incomplete: {
    name: "Не хватает данных",
    color: "blue",
  },
  rejection: {
    name: "Отказ",
    color: "black",
  },
  documentsAwait: {
    name: "Ожидание документов",
    color: "yellow",
  },
  paymentAwait: {
    name: "Ожидает оплаты",
    color: "yellow",
  },
  paid: {
    name: "Оплачено",
    color: "green",
  },
};

export const StatusComment = {
  inProcess: {
    message:
      "Сейчас заявка находится на рассмотрении. В ближайшее время менеджеры Daesung изучат её и примут решение.",
  },
  incomplete: {
    title: "Не хватает данных по заявке!",
  },
  rejection: {
    title: "Отказано",
  },
  documentsAwait: {
    title: "Заявка одобрена, отправьте документы на оплату.",
    message:
      "Чтобы получить выплату, сформируйте и отправьте счёт и акт по заявке. <br/>Вы можете использовать шаблоны: <a class='form__link' href='/assets/template-invoice.docx' target='_blank'>счёта</a> и <a class='form__link' href='/assets/template-act.pdf' target='_blank'>акта</a>. <br/><br/> Отправить готовые документы нужно по электронной почте <a class='form__link' href='mailto:info@дэсунг.рус'>info@дэсунг.рус</a>",
  },
  paymentAwait: {
    title: "Заявка ожидает оплаты",
    message:
      "Мы получили ваши документы, спасибо! Скоро ваша заявка будет оплачена. <br/>По вопросам оплаты можете обращаться в отдел бухгалтерии по тел.<a href='tel:+78002349335'>+7 800 234-93-35</a>",
  },
  paid: {
    title: "Заявка оплачена",
    message:
      "Выплата по заявке совершена! Обычно оплата проходит в течении 3 платёжных дней, но в редких случаях может занять больше времени. Вы можете узнать статус перевода в своём банке.",
  },
};

export const StatusButtons = [
  {
    id: "incomplete",
    label: "Не хватает данных",
    color: "blue",
    visible: "inProcess",
  },
  {
    id: "rejection",
    label: "Отказать",
    color: "black",
    visible: "inProcess",
  },
  {
    id: "documentsAwait",
    label: "Одобрить",
    color: "green",
    visible: "inProcess",
  },
  {
    id: "paymentAwait",
    label: "Документы получены",
    color: "yellow",
    visible: "documentsAwait",
  },
  {
    id: "paid",
    label: "Оплачена",
    color: "green",
    visible: "paymentAwait",
  },
];

export const StatusModal = {
  DELETE: {
    name: "delete",
    title: "Вы собираетесь удалить заявку",
    message:
      "После удаления сотрётся вся информация о заявке и все вложенные файлы. <br/>Отменить это действие будет невозможно",
    button: {
      className: "black",
      type: "button",
      icon: "trash",
      label: "Удалить",
    },
  },
  INCOMPLETE: {
    name: "incomplete",
    title: "Не хватает данных",
    message:
      "Вы собираетесь отправить заявку на доработку. После этого у представителя АСЦ появится возможность отредактировать заявку и вновь отправить её на проверку.",
    input: {
      name: "incomplete",
      label: "Каких данных не хватает в заявке?",
      params: { required: "required" },
      placeholder: "Введите комментарий",
    },
    button: {
      className: "blue",
      type: "submit",
      label: "Отправить",
    },
    isNumber: true,
  },

  REJECTION: {
    name: "rejection",
    title: "Отказ",
    message:
      "Вы собираетесь отказать в возмещении гарантийного ремонта по заявке. После этого представитель АСЦ увидит принятое решение, но не сможет редактировать заявку или отправить её повторно.",
    input: {
      name: "rejection",
      label: "Причина отказа?",
      params: { required: "required" },
      placeholder: "Введите комментарий",
    },
    button: {
      className: "black",
      type: "submit",
      label: "Отказать",
    },
    isNumber: true,
  },
  CONFIRM: {
    name: "confirm",
    title: "Одобрить",
    message:
      "Вы собираетесь одобрить заявку. <br/>После этого представитель АСЦ получит сообщение с&nbsp;просьбой отправить документы на оплату.",
    input: {
      name: "additional",
      label: "Комментарий, который увидит АСЦ:",
      params: {},
      placeholder: "Введите комментарий",
    },
    button: {
      className: "green",
      type: "submit",
      label: "Одобрить",
    },
    isNumber: true,
  },
  COMMENT: {
    name: "comment",
    title: "Скрытый комментарий",
    message:
      "Оставьте скрытый комментарий к заявке. Его увидят только менеджеры Daesung.",
    input: {
      name: "internal",
      params: { required: "required" },
      placeholder: "Введите комментарий",
    },
    button: {
      className: "black",
      type: "submit",
      label: "Оставить комментарий",
    },
    isNumber: true,
  },
};

export const FiltersMeta = [
  {
    id: "status",
    label: "Статус заявки",
    inputs: [
      {
        name: "status",
        label: "На рассмотрении",
        value: "inProcess",
        type: "checkbox",
        color: "red",
      },
      {
        name: "status",
        label: "Ожидает оплаты",
        value: "paymentAwait",
        type: "checkbox",
        color: "yellow",
      },
      {
        name: "status",
        label: "Ожидание документов",
        value: "documentsAwait",
        type: "checkbox",
        color: "yellow",
      },
      {
        name: "status",
        label: "Оплачено",
        value: "paid",
        type: "checkbox",
        color: "green",
      },
      {
        name: "status",
        label: "Не хватает данных",
        value: "incomplete",
        type: "checkbox",
        color: "blue",
      },
      {
        name: "status",
        label: "Отказ",
        value: "rejection",
        type: "checkbox",
        color: "black",
      },
    ],
  },
  {
    id: "date",
    label: "Дата заявки",
    inputs: [
      {
        name: "minDate",
        type: "date",
      },
      {
        name: "maxDate",
        type: "date",
      },
    ],
  },
  {
    id: "model",
    label: "Модель котла",
  },
  {
    id: "region",
    label: "Регион",
    selector: {
      name: "region",
      type: "text",
      emptyMessage: "Ничего не найдено",
      placeholder: "Начните вводить название",
    },
  },
  {
    id: "sum",
    label: "Сумма, ₽",
    range: {
      name: "sum",
      type: "range",
      step: 1,
      trackStyles: {
        height: "0.2083rem",
        width: "100%",
        colors: ["#E2E4E8", "#959BA7", "#E2E4E8"],
      },
      thumbStyles: {
        height: "0.7638rem",
        width: "0.7638rem",
        borderRadius: "50%",
        backgroundColor: "#353535",
      },
    },
    inputs: [
      {
        name: "min",
        type: "mask",
        mask: "9999999",
        formatChars: {
          9: "[0-9]",
        },
      },
      {
        name: "max",
        type: "mask",
        mask: "9999999",
        formatChars: {
          9: "[0-9]",
        },
      },
    ],
  },
  {
    id: "spare",
    label: "Запасная часть",
    radioButtons: [
      {
        name: "spares",
        label: "Все",
        value: "all",
        type: "radio",
      },
      {
        name: "spares",
        label: "С запчастями",
        value: "withSpares",
        type: "radio",
      },
      {
        name: "spares",
        label: "Без запчастей",
        value: "withoutSpares",
        type: "radio",
      },
    ],
    selector: {
      name: "spare",
      type: "text",
      emptyMessage: "Ничего не найдено",
      placeholder: "Начните вводить артикул или название",
    },
  },
];

export const QueryResetRoutes = ["/login", "/password", "/reset", "/users"];