import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setStep } from "store/slice";
import Icon from "components/Icon/Icon";
import { ApplicationCreateFormSteps } from "utils/const";

const ApplicationFormFieldset = ({
  children,
  number,
  title,
  description,
  trigger,
  stepFields,
  isInstructionButton,
  onInstructionButtonClick,
}) => {
  const currentStep = useSelector((state) => state.applicationStep);
  const isLoading = useSelector((state) => state.isApplicationCreating);
  const isMobile = useSelector((state) => state.isMobile);
  const dispatch = useDispatch();

  const nextButtonClickHandler = async () => {
    const result = await trigger(stepFields);

    if (!result) {
      return;
    } else {
      dispatch(setStep(currentStep + 1));
    }
  };

  const prevButtonClickHandler = () => {
    dispatch(setStep(currentStep - 1));
  };

  return (
    <div className="application__step-wrapper">
      <div className="application__step-header">
        {!isMobile && <h3 className="title">Новая заявка</h3>}
        <div className="application__step-pagination">
          {ApplicationCreateFormSteps.map((step) => (
            <div
              key={step.number}
              className={`application__step-page ${
                step.number === currentStep
                  ? "application__step-page--active"
                  : ""
              }`}
            >
              {step.number === currentStep ? step.number : ""}
            </div>
          ))}
        </div>
      </div>
      <div className="application__fieldset">
          <h3
            className="application__step-title title"
          >
            <span className="application__step-title-number">{`${number}. `}</span>
            {title} {isMobile && isInstructionButton && !description && (
            <span
              className="application__button-instruction"
              onClick={onInstructionButtonClick}
            >
              ?
            </span>
          )}
          </h3>

        {description && (
          <p className="application__step-description">
            {description}{" "}
            {isMobile && isInstructionButton && (
              <span
                className="application__button-instruction"
                onClick={onInstructionButtonClick}
              >
                ?
              </span>
            )}
          </p>
        )}
        {children}
        <div className="application__step-buttons">
          {currentStep != 1 && (
            <button
              className="button button--medium-gray application__button-prev"
              type="button"
              onClick={prevButtonClickHandler}
            >
              {!isMobile && <Icon name="prev" />}
              <span>Назад</span>
            </button>
          )}
          {currentStep < ApplicationCreateFormSteps.length && (
            <button
              className="button button--black application__button-next"
              type="button"
              onClick={nextButtonClickHandler}
            >
              <span>Дальше</span>
              {!isMobile && <Icon name="next" />}
            </button>
          )}
          {currentStep === ApplicationCreateFormSteps.length && (
            <button
              className="button button--black application__button-next"
              type="submit"
            >
              <span>{isLoading ? "Подождите..." : "Создать заявку"}</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApplicationFormFieldset;
