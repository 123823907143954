import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { useAuth } from "hooks/useAuth";
import { logoutUser } from "store/async";
import DropdownMenu from "components/DropdownMenu/DropdownMenu";
import Logo from "components/Logo/Logo";
import Icon from "components/Icon/Icon";
import { AppPath } from "utils/const";

const Header = () => {
  const dispatch = useDispatch();
  const isMobile = useSelector((state) => state.isMobile);
  const { isAuth, isAdmin, name } = useAuth();
  const { url } = useRouteMatch();
  const [isMenuOpened, setMenuOpened] = useState(false);

  const isUsersListCurrent = url === AppPath.USERS;
  const isUsersAddCurrent = url === `${AppPath.USERS}/add`;

  const logoutHandler = () => {
    dispatch(logoutUser());
  };

  const adminLinks = [
    { label: "Скачать заявки", type: "download" },
    {
      label: isUsersAddCurrent ? "Список заявок" : "Добавить пользователя",
      path: isUsersAddCurrent ? AppPath.ROOT : `${AppPath.USERS}/add`,
    },
    {
      label: isUsersListCurrent ? "Список заявок" : "Список пользователей",
      path: isUsersListCurrent ? AppPath.ROOT : AppPath.USERS,
    },
    { label: "Инструкции", href: "https://disk.yandex.ru/d/vTwcH54pc88aoQ" },
    { label: "Выйти", handler: logoutHandler },
  ];

  const adminMobileLinks = [
    { label: "Скачать заявки", type: "download" },
    { label: "Инструкции", href: "https://disk.yandex.ru/d/vTwcH54pc88aoQ" },
    { label: "Выйти", handler: logoutHandler },
  ];

  const userLinks = [
    { label: "Инструкции", href: "https://disk.yandex.ru/d/vTwcH54pc88aoQ" },
    { label: "Выйти", handler: logoutHandler },
  ];

  const openMenuHandler = () => {
    setMenuOpened(!isMenuOpened);
  };

  const closeMenuHandler = () => {
    if (isMenuOpened) {
      setMenuOpened(false);
    }
  };

  return (
    <header className="header container">
      <Logo title={isMobile ? "" : "| Гарантийный ремонт"} isHeader={true} />
      {isAuth && (
        <OutsideClickHandler onOutsideClick={closeMenuHandler}>
          <div className="header__user">
            <button
              type="button"
              className="button button--gray"
              onClick={openMenuHandler}
            >
              <Icon name={isMobile ? "burger-mobile" : "burger"} />
              {!isMobile && <span>{name}</span>}
            </button>
            {isMenuOpened && (
              <DropdownMenu
                links={
                  isAdmin
                    ? isMobile
                      ? adminMobileLinks
                      : adminLinks
                    : userLinks
                }
                isCloseButton={true}
                onClose={closeMenuHandler}
              />
            )}
          </div>
        </OutsideClickHandler>
      )}
    </header>
  );
};

export default Header;
