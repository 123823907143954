import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";
import { setAdminModal, setEditedApplication } from "store/slice";
import { setApplicationStatus } from "store/async";
import ApplicationStatus from "components/ApplicationStatus/ApplicationStatus";
import DropdownMenu from "components/DropdownMenu/DropdownMenu";
import Icon from "components/Icon/Icon";
import { StatusButtons, StatusModal } from "utils/const";

const { DELETE, COMMENT, INCOMPLETE, REJECTION, CONFIRM } = StatusModal;

const ApplicationAdminButtons = ({ status, uid, number }) => {
  const dispatch = useDispatch();
  const isMobile = useSelector((state) => state.isMobile);
  const [isMenuOpened, setMenuOpened] = useState(false);

  const openMenuHandler = () => {
    setMenuOpened(!isMenuOpened);
  };

  const closeMenuHandler = () => {
    if (isMenuOpened) {
      setMenuOpened(false);
    }
  };

  const printButtonHandler = () => {
    closeMenuHandler();
    window.print();
  };

  const editButtonHandler = () => {
    closeMenuHandler();
    dispatch(setEditedApplication(number));
  };

  const deleteButtonHandler = () => {
    closeMenuHandler();
    dispatch(setAdminModal(DELETE));
  };

  const commentButtonHandler = () => {
    closeMenuHandler();
    dispatch(setAdminModal(COMMENT));
  };

  const incompleteButtonHandler = () => {
    dispatch(setAdminModal(INCOMPLETE));
  };

  const rejectionButtonHandler = () => {
    dispatch(setAdminModal(REJECTION));
  };

  const documentsAwaitButtonHandler = () => {
    dispatch(setAdminModal(CONFIRM));
  };

  const paymentAwaitButtonHandler = () => {
    dispatch(setApplicationStatus({ id: uid, status: "paymentAwait" }));
  };

  const paidButtonHandler = () => {
    dispatch(setApplicationStatus({ id: uid, status: "paid" }));
  };

  const adminButtonsClickHandlers = {
    incomplete: incompleteButtonHandler,
    rejection: rejectionButtonHandler,
    documentsAwait: documentsAwaitButtonHandler,
    paymentAwait: paymentAwaitButtonHandler,
    paid: paidButtonHandler,
  };

  return (
    <div className="application__buttons">
      {!isMobile
        ? StatusButtons.map((button, i) => {
            return button.visible === status ? (
              <button
                key={i + 1}
                className={`button button--gray application__button-status application__button-status--${button.color}`}
                type="button"
                onClick={adminButtonsClickHandlers[button.id]}
              >
                <ApplicationStatus color={button.color} label={button.label} />
              </button>
            ) : (
              ""
            );
          })
        : ""}
      <OutsideClickHandler onOutsideClick={closeMenuHandler}>
        <button
          className="application__button-menu"
          type="button"
          onClick={openMenuHandler}
        >
          <Icon name="dots" />
        </button>
        {isMenuOpened && (
          <DropdownMenu
            links={[
              ...StatusButtons.reduce((acc, button) => {
                if (button.visible === status && isMobile) {
                  acc.push({
                    label: button.label,
                    handler: adminButtonsClickHandlers[button.id],
                    modClass: `status status--${button.color}`,
                  });
                }
                return acc;
              }, []),
              { label: "Скрытый комментарий", handler: commentButtonHandler },
              { label: "Удалить заявку", handler: deleteButtonHandler },
              { label: "Редактировать заявку", handler: editButtonHandler },
              { label: "Печать", handler: printButtonHandler },
            ]}
          />
        )}
      </OutsideClickHandler>
    </div>
  );
};

export default ApplicationAdminButtons;
