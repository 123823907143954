import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAuth } from "hooks/useAuth";
import { selectApplicationById } from "store/slice";
import ApplicationHeader from "components/ApplicationHeader/ApplicationHeader";
import ApplicationHeaderAdmin from "components/ApplicationHeaderAdmin/ApplicationHeaderAdmin";
import ApplicationItem from "components/ApplicationItem/ApplicationItem";
import ApplicationItemText from "components/ApplicationItemText/ApplicationItemText";
import ApplicationItemImage from "components/ApplicationItemImage/ApplicationItemImage";
import ApplicationServiceDates from "components/ApplicationServiceDates/ApplicationServiceDates";
import ApplicationStatus from "components/ApplicationStatus/ApplicationStatus";
import ApplicationModal from "components/ApplicationModal/ApplicationModal";
import EmptyPage from "components/EmptyPage/EmptyPage";
import { formatDate, getSum } from "utils/utils";
import { Status, ApplicationBlocks } from "utils/const";

const Application = () => {
  const { id } = useParams();
  const { isAdmin } = useAuth();

  const isMobile = useSelector((state) => state.isMobile);
  const currentApplication = useSelector((state) =>
    selectApplicationById(state, Number(id))
  );
  const [isLoading, setLoading] = useState(true);
  const [application, setApplication] = useState(null);

  useEffect(() => {
    if (currentApplication.length) {
      setLoading(false);
      setApplication(currentApplication[0]);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, [currentApplication]);

  const renderChildren = (item, i, data) => {
    const renderSwitch = (id, parentId) => {
      switch (id) {
        case "status":
          return (
            <ApplicationStatus
              color={Status[application[id]].color}
              label={Status[application[id]].name}
            />
          );
        case "date":
          return formatDate(application[id].seconds);
        case "guaranteePeriod":
          return `${formatDate(application.launchDate.seconds)} + ${
            application[id]
          }`;
        case "isService":
          return application[id] === "yes" ? (
            <ApplicationServiceDates
              dates={application.serviceDate}
              launchDate={application.launchDate}
            />
          ) : (
            "Нет"
          );
        case "sum":
          return getSum(
            application.visitCost,
            application.worksCost,
            application.spares
          );
        case "spares":
          return (
            <>
              <span className="application__item-text application__item-text--ellipsis">
                {data.title}
              </span>{" "}
              <span className="application__item-text application__item-text--sum">
                {data.cost} ₽
              </span>
            </>
          );

        default:
          return parentId ? application[parentId][id] : application[id];
      }
    };

    if (item.type === "image") {
      return (
        <ApplicationItem key={i + 1} title={isMobile && item.titleMobile ? item.titleMobile : item.title}>
          {application[item.id] ? (
            <ApplicationItemImage
              image={application[item.id]}
              alt={item.title}
            />
          ) : (
            <ApplicationItemText modClass={"application__item-text--no-img"}>
              <span>Изображение отсутствует</span>
            </ApplicationItemText>
          )}
        </ApplicationItem>
      );
    } else {
      return (
        <ApplicationItem key={i + 1} title={isMobile && item.titleMobile ? item.titleMobile : item.title}>
          <ApplicationItemText modClass={item.modClass ? item.modClass : ""}>
            {renderSwitch(item.id, item.parentId)}
          </ApplicationItemText>
        </ApplicationItem>
      );
    }
  };

  return (
    <>
      <div className={`application ${isAdmin ? "application--admin" : ""}`}>
        {isLoading ? (
          <p>Подождите...</p>
        ) : application ? (
          <>
            {isAdmin ? (
              <ApplicationHeaderAdmin
                number={application.number}
                applicant={application.applicant}
                status={application.status}
                uid={application.uid}
                daesungComments={application.daesungComments}
              />
            ) : (
              <ApplicationHeader
                number={application.number}
                date={application.date}
                status={application.status}
                daesungComments={application.daesungComments}
              />
            )}
            <div className="application__grid">
              {application.comment ? (
                  <div className="application__block application__block--additional application__block--comment">
                    <ApplicationItem title="Комментарий отправителя:">
                      <ApplicationItemText>
                        {application.comment}
                      </ApplicationItemText>
                    </ApplicationItem>
                  </div>
              ) : (
                  ""
              )}

              <div className="application__content">
                {ApplicationBlocks.map((block, i) => {
                  return (
                    <div
                      key={i + 1}
                      className={`application__block ${
                        block.modClass ? block.modClass : ""
                      }`}
                    >
                      {block.type === "images" ? (
                        <div className="application__images">
                          {block.items.map((item, i) => {
                            return renderChildren(item, i);
                          })}
                        </div>
                      ) : (
                        block.items.map((item, i) => {
                          if (item.id === "spares") {
                            return application.spares.length
                              ? application.spares.map((spare, i) => {
                                  return renderChildren(item, i, spare);
                                })
                              : "";
                          } else {
                            return renderChildren(item, i);
                          }
                        })
                      )}
                    </div>
                  );
                })}
              </div>

              {application.additionalImages.length ? (
                <div className="application__block application__block--additional">
                  <ApplicationItem title="Дополнительные материалы:">
                    <div className="application__images">
                      {application.additionalImages.map((img, i) => {
                        return (
                          <div
                            key={i + 1}
                            className="application__item-img-wrapper"
                          >
                            <ApplicationItemImage
                              image={img}
                              alt="Дополнительное фото"
                            />
                          </div>
                        );
                      })}
                    </div>
                  </ApplicationItem>
                </div>
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          <EmptyPage
          isAdmin={isAdmin}
          page={"application"}
        />
        )}
      </div>
      {application ? (
        <ApplicationModal
          number={application.number}
          uid={application.uid}
          internalComment={
            application.daesungComments?.internal
              ? application.daesungComments.internal
              : ""
          }
          confirmComment={
            application.daesungComments?.additional
              ? application.daesungComments.additional
              : ""
          }
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Application;
