import React, { useState } from "react";
import InputMask from "react-input-mask";
import InputRange from "components/InputRange/InputRange";

const FiltersSum = ({
  range,
  control,
  inputs,
  setValue,
  minValue,
  maxValue,
  currentValues = [minValue, maxValue],
}) => {
  const [errors, setErrors] = useState({});

  const changeInputHandler = (e) => {
    let value = Number(e.target.value);
    let newValues = currentValues;

    if (e.target.name === "min") {
      if (value < minValue || value > maxValue) {
        setErrors({ ...errors, min: true });
      } else {
        if (errors.min) {
          setErrors({ ...errors, min: false });
        }
      }
      newValues[0] = value;
    } else {
      if (value < minValue || value > maxValue) {
        setErrors({ ...errors, max: true });
      } else {
        if (errors.max) {
          setErrors({ ...errors, max: false });
        }
      }
      newValues[1] = value;
    }

    setValue("sum", newValues);
  };

  const blurInputHandler = (e) => {
    let value = Number(e.target.value);

    if (errors.min || errors.max || !value) {
      setErrors({});
      let newValues = currentValues;

      if (e.target.name === "min") {
        newValues[0] = minValue;
      } else {
        newValues[1] = maxValue;
      }
      setValue("sum", newValues);
    }
  };

  return (
    <div className="filters__sum-filters">
      <div className="filters__range">
        <InputRange
          range={range}
          control={control}
          min={minValue}
          max={maxValue}
          isMinError={errors.min}
          isMaxError={errors.max}
        />
      </div>
      <div className="filters__range-inputs">
        {inputs.map((input, i) => {
          return (
            <InputMask
              key={i + 1}
              name={input.name}
              mask={input.mask}
              onBlur={blurInputHandler}
              onChange={changeInputHandler}
              value={input.name === "min" ? currentValues[0] : currentValues[1]}
              maskChar={null}
              className={`form__input ${
                errors[input.name] ? "form__input--error" : ""
              }`}
              formatChars={input.formatChars}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FiltersSum;
