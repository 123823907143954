import React from "react";
import Icon from "components/Icon/Icon";

const FiltersToggle = ({ title, count, id, onClick }) => {
  return (
    <a
      id={id}
      className={`filters__toggle ${count ? "filters__toggle--checked" : ""}`}
      onClick={() => onClick(id)}
    >
      <span>
        {" "}
        {title}
        {count ? ` (${count})` : ""}
      </span>

      <Icon name="select" />
    </a>
  );
};

export default FiltersToggle;
