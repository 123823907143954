import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppPath } from "utils/const";

const EmptyPage = ({ isQuery, isFilters, isAdmin, page }) => {
  const isMobile = useSelector((state) => state.isMobile);

  const message = {
    applications: {
      title:
        isQuery || isFilters
          ? "Не найдено ни одной подходящей заявки."
          : "Сейчас тут нет ни одной заявки.",
      text:
        isQuery || isFilters ? (
          "Попробуйте другой запроc или посмотрите пока на котика:"
        ) : isAdmin ? (
          "Посмотрите пока на котика:"
        ) : (
          <>
            <Link to={`${AppPath.APPLICATION}/add`} className="form__link">
              Создайте новую
            </Link>{" "}
            или посмотрите пока на котика:
          </>
        ),
    },
    application: {
      title: "Такой заявки не существует",
      text: (
        <>
          <Link to={AppPath.ROOT} className="form__link">
            Вернитесь к списку заявок
          </Link>{" "}
          или посмотрите пока на котика:
        </>
      ),
    },
    users: {
      title: isQuery
        ? "Не найдено ни одного подходящего пользователя."
        : "Сейчас тут нет ни одного пользователя.",
      text: isQuery ? (
        "Попробуйте другой запроc или посмотрите пока на котика:"
      ) : (
        <>
          <Link to={`${AppPath.USERS}/add`} className="form__link">
            Создайте нового
          </Link>{" "}
          или посмотрите пока на котика:
        </>
      ),
    },
  };

  return (
    <div className="applications__empty">
      <h3 className="applications__empty-title title">{message[page].title}</h3>
      <p className="applications__empty-subtitle">{message[page].text}</p>
      {isMobile ? (
        <img
          className="applications__empty-img"
          src={
            isAdmin
              ? "/assets/cat-empty-admin-mobile.jpg"
              : "/assets/cat-empty-mobile.jpg"
          }
          alt="Котик"
        />
      ) : (
        <img
          className="applications__empty-img"
          src={
            isAdmin ? "/assets/cat-empty-admin.jpg" : "/assets/cat-empty.jpg"
          }
          alt="Котик"
        />
      )}
    </div>
  );
};

export default EmptyPage;
