import React from "react";

const InputCheckbox = ({ input, register, isStatusCheckbox }) => {
  return (
    <div className="filters__checkbox">
      <input
        {...register(input.name)}
        type={input.type}
        value={input.value}
        id={input.value}
      />
      <label className="filters__checkbox-label" htmlFor={input.value}>
        {isStatusCheckbox && (
          <span
            className={`application__status-point application__status-point--${input.color}`}
          ></span>
        )}
        <span>{input.label}</span>
      </label>
    </div>
  );
};

export default InputCheckbox;
