import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectSpares } from "store/slice";
import InputSelect from "components/InputSelect/InputSelect";
import InputRadio from "components/InputRadio/InputRadio";

const FilterSpares = ({
  inputs,
  selector,
  register,
  setValue,
  setFocus,
  isSelectOpened,
}) => {
  const spares = useSelector((state) => selectSpares(state));

  useEffect(() => {
    if (isSelectOpened) {
      setFocus(selector.name);
    }
  }, [setFocus, isSelectOpened]);

  return (
    <div className="filters__spares-filter">
      {inputs.map((input) => {
        return (
          <InputRadio key={input.value} input={input} register={register} />
        );
      })}
      {isSelectOpened ? (
        <div className="filters__spares-select">
          <InputSelect
            optionsData={spares}
            type={selector.type}
            name={selector.name}
            register={register}
            setValue={setValue}
            emptyMessage={selector.emptyMessage}
            isSearchable={true}
            placeholder={selector.placeholder}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default FilterSpares;
