import React, { useState, useEffect } from "react";
import Input from "components/Input/Input";
import Icon from "components/Icon/Icon";
import { Status } from "utils/const";

const InputSelect = ({
  optionsData,
  type,
  name,
  label,
  register,
  params,
  setValue,
  isError,
  clearErrors,
  emptyMessage,
  isSearchable,
  placeholder,
  isStatus,
  statusValue,
}) => {
  const [isOpened, setOpened] = useState(false);
  const [isSelected, setSelected] = useState(false);
  const [query, setQuery] = useState("");
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const filterKeys = ["value"];

    let filteredData;
    if (query && isSearchable) {
      filteredData = optionsData.filter((item) => {
        return filterKeys.some((key) => {
          return item[key].toLowerCase().includes(query.toLowerCase());
        });
      });
    } else {
      filteredData = optionsData;
    }

    setOptions(filteredData);
  }, [query]);

  useEffect(() => {
    if (isStatus && !statusValue && params.value) {
      setValue(name, params.value);
    }
  }, [statusValue]);

  const focusInputHandler = (e) => {
    setOpened(true);
    const { value } = e.target;
    if (isSearchable) {
      const position = value.length;
      e.target.setSelectionRange(position, position);

      if (value) {
        setQuery(value);
      }
    }

    if (value && !isSelected) {
      setSelected(true);
    }
  };

  const changeInputHandler = (e) => {
    if (isSearchable) {
      setSelected(false);
      setQuery(e.target.value);

      if (clearErrors) {
        clearErrors();
      }
    }
  };

  const blurInputHandler = (e) => {
    if (
      e.relatedTarget &&
      e.relatedTarget.classList.contains("form__prompt-item")
    ) {
      return;
    } else {
      if (!isSelected) {
        setValue(name, "");
      }
      setOpened(false);
      if (isSearchable) {
        setQuery("");
      }
    }
  };

  const clickPromptItemHandler = (selected) => {
    const value = isStatus ? Status[selected].name : selected;
    setValue(name, value);
    setSelected(true);
    setOpened(false);
    if (isSearchable) {
      setQuery("");
    }

    if (clearErrors) {
      clearErrors();
    }
  };

  const newParams = {
    ...params,
    onChange: changeInputHandler,
    onBlur: blurInputHandler,
  };

  return (
    <>
      <Input
        name={name}
        type={type}
        label={label}
        params={newParams}
        register={register}
        onFocus={focusInputHandler}
        isError={isError}
        isReadOnly={!isSearchable}
        placeholder={placeholder}
        isSelect={true}
      />
      <Icon name="select" />
      {isOpened ? (
        <div className="form__prompt">
          {options.length ? (
            options.map((item, i) => (
              <p
                key={i + 1}
                className={`form__prompt-item ${
                  isStatus ? `status status--${Status[item.value].color}` : ""
                }`}
                tabIndex="0"
                onClick={() => clickPromptItemHandler(item.value)}
              >
                {isStatus ? Status[item.value].name : item.value}
              </p>
            ))
          ) : (
            <p className="form__prompt-item form__prompt-item--empty">
              {emptyMessage}
            </p>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default InputSelect;
