import React from "react";
import { useSelector } from "react-redux";
import { AuthPage } from "utils/const";
import PageWBack from "components/PageWBack/PageWBack";
import Auth from "components/Auth/Auth";
import Reset from "components/Reset/Reset";
import ResetSuccess from "components/ResetSuccess/ResetSuccess";

const ResetPage = () => {
  const resetSuccess = useSelector((state) => state.resetSuccess);

  return (
    <PageWBack>
      {!resetSuccess ? (
        <Auth
          title={AuthPage.RESET.title}
          subtitle={AuthPage.RESET.subtitle}
          isBackButton={true}
        >
          <Reset />
        </Auth>
      ) : (
        <Auth title={AuthPage.RESET.title} isBackButton={true}>
          <ResetSuccess />
        </Auth>
      )}
    </PageWBack>
  );
};

export default ResetPage;
