import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { resetPassword } from "store/async";
import { clearResetError } from "store/slice";
import Input from "components/Input/Input";
import { Forms, AppPath, Errors } from "utils/const";

const { resetMeta } = Forms;

const Reset = () => {
  const dispatch = useDispatch();
  const errorCode = useSelector((state) => state.resetError);

  const { register, handleSubmit } = useForm();

  const changeInputHandler = () => {
    if (errorCode) {
      dispatch(clearResetError());
    }
  };

  const onSubmit = (data) => {
    dispatch(resetPassword(data));
  };

  return (
    <form className="form auth__form" onSubmit={handleSubmit(onSubmit)}>
      <div className="auth__fieldset">
        {resetMeta.map((field, i) => (
          <div key={i + 1} className="auth__field">
            <Input
              name={field.name}
              type={field.type}
              label={field.label}
              params={{ ...field.params, onChange: changeInputHandler }}
              register={register}
              placeholder={field.placeholder}
              isError={errorCode && Errors[errorCode].type === field.name}
            />
          </div>
        ))}
      </div>

      <div className="auth__buttons">
        <button className="button button--black" type="submit">
          Сбросить пароль
        </button>
        <Link to={AppPath.LOGIN} className="auth__link">
          Отмена
        </Link>
        {errorCode && <p className="auth__error">{Errors[errorCode].message}</p>}
      </div>


    </form>
  );
};

export default Reset;