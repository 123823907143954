import React from "react";
import { useSelector } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";
import Icon from "components/Icon/Icon";

const ApplicationFormInstruction = ({
  children,
  topText,
  bottomText,
  scheme,
  markers,
  isVisible,
  onCloseButtonClick,
}) => {
  const isMobile = useSelector((state) => state.isMobile);

  return (
    <OutsideClickHandler
      onOutsideClick={onCloseButtonClick ? onCloseButtonClick : () => {}}
    >
      <div
        className={`application__instruction ${
          isVisible ? "" : "application__instruction--hidden"
        }`}
      >
        {isMobile && (
          <button
            type="button"
            className="application__instruction-close-btn"
            onClick={onCloseButtonClick}
          >
            <Icon name="delete" />
          </button>
        )}

        <p className="application__instruction-text application__instruction-text--top">{topText}</p>
        <div className="application__instruction-scheme">
          <img
            className="application__instruction-img"
            src={scheme.img}
            alt={scheme.name}
          />
          {markers && (
            <img className="application__instruction-markers" src={markers} />
          )}
        </div>
        <p className="application__instruction-text">{bottomText}</p>
        {children}
      </div>
    </OutsideClickHandler>
  );
};

export default ApplicationFormInstruction;
