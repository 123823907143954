import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ApplicationsTableCol from "components/ApplicationsTableCol/ApplicationsTableCol";
import ApplicationStatus from "components/ApplicationStatus/ApplicationStatus";
import Icon from "components/Icon/Icon";
import { formatDate, getExternalComment } from "utils/utils";
import { ApplicationsTableMeta, AppPath, Status } from "utils/const";

const ApplicationsTableRow = ({ application, isAdmin }) => {
  const isMobile = useSelector((state) => state.isMobile);
  const [isCommentVisible, setCommentVisible] = useState(false);
  const [comment, setComment] = useState("");

  useEffect(() => {
    const { status, daesungComments } = application;
    const externalComment = getExternalComment(status, daesungComments) || "";
    const internalComment =
      daesungComments?.internal || "";

    if (externalComment && !isAdmin) {
      setComment(externalComment);
    }

    if (internalComment && isAdmin) {
      setComment(internalComment);
    }
  }, [application]);

  const clickCommentButtonHandler = (e) => {
    e.preventDefault();
    setCommentVisible(!isCommentVisible);
  };

  return (
    <Link
      to={`${AppPath.APPLICATIONS}/${application.number}`}
      className="applications__table-row"
    >
      {ApplicationsTableMeta.map((col) =>
        col.isAdminOnly && !isAdmin ? (
          ""
        ) : (
          <ApplicationsTableCol
            key={col.id}
            id={col.id}
            label={col.labelMobile ? col.labelMobile : col.label}
            content={
              col.parentId ? (
                application[col.parentId][col.id]
              ) : col.id === "status" ? (
                <ApplicationStatus
                  color={Status[application[col.id]].color}
                  label={Status[application[col.id]].name}
                />
              ) : col.id === "date" ? (
                formatDate(application[col.id].seconds)
              ) : col.id === "number" ? (
                `#${application[col.id]}`
              ) : (
                application[col.id]
              )
            }
          />
        )
      )}
      {comment && !isMobile && (
        <div className="applications__table-col applications__table-col--icon">
          <button
            type="button"
            className="applications__comment-btn"
            onClick={clickCommentButtonHandler}
          >
            <Icon name={isAdmin ? "comment-admin" : "comment"} />
          </button>
        </div>
      )}

      {comment && (
        <div
          className={`applications__table-col applications__table-col--comment ${
            isCommentVisible || isMobile ? "" : "applications__table-col--hidden"
          }`}
        >
          {isMobile && <span className="applications__table-label">Комментарий менеджера:</span>}
          <p>{comment}</p>
        </div>
      )}
    </Link>
  );
};

export default ApplicationsTableRow;
