import React from "react";

const ApplicationItemImage = ({ image, alt }) => {
  return (
    <div className="application__item-img">
      <a href={image} target="_blank">
        <img className="application__img" src={image} alt={alt} />
      </a>
    </div>
  );
};

export default ApplicationItemImage;
