import React from "react";

const InputRadio = ({ input, register }) => {
  return (
    <div className="filters__radio">
      <input
        {...register(input.name)}
        type={input.type}
        value={input.value}
        id={input.value}
      />
      <label className="filters__radio-label" htmlFor={input.value}>
        <span>{input.label}</span>
      </label>
    </div>
  );
};

export default InputRadio;
