import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useFormState } from "react-hook-form";
import ApplicationFormFieldset from "components/ApplicationFormFieldset/ApplicationFormFieldset";
import ApplicationFormInstruction from "components/ApplicationFormInstruction/ApplicationFormInstruction";
import InputUpload from "components/InputUpload/InputUpload";
import InputMasked from "components/InputMasked/InputMasked";
import Input from "components/Input/Input";

const ApplicationFormStep4 = ({
  number,
  title,
  description,
  fields,
  instruction,
  register,
  control,
  setValue,
  clearErrors,
  trigger,
}) => {
  const { errors } = useFormState({
    control,
  });

  const isMobile = useSelector((state) => state.isMobile);
  const [isInstructionVisible, setInstructionVisible] = useState(false);
  const stepFields = fields.map((field) => field.name);

  return (
    <div className="application__step application__step--four">
      <ApplicationFormFieldset
        number={number}
        title={title}
        description={description}
        trigger={trigger}
        stepFields={stepFields}
        isInstructionButton={true}
        onInstructionButtonClick={() => setInstructionVisible(true)}
      >
        <div className="application__fields">
          {fields.map((field, i) =>
            field.type === "mask" ? (
              <InputMasked
                key={i + 1}
                fieldClassName={"application__field"}
                name={field.name}
                label={field.label}
                params={field.params}
                mask={field.mask}
                formatChars={field.formatChars}
                placeholder={field.placeholder}
                defaultValue={""}
                control={control}
                isError={errors[field.name]}
                onChangeHandler={() => clearErrors()}
              />
            ) : field.type !== "file" ? (
              <div key={i + 1} className="application__field">
                <Input
                  name={field.name}
                  type={field.type}
                  label={field.label}
                  params={{ ...field.params, onChange: () => clearErrors() }}
                  placeholder={field.placeholder}
                  register={register}
                  isError={errors[field.name]}
                />
              </div>
            ) : (
              ""
            )
          )}
        </div>
        {fields.map((field, i) =>
          field.type === "file" ? (
            <div
              key={i + 1}
              className="application__field application__field--upload"
            >
              <InputUpload
                name={field.name}
                label={field.label}
                params={field.params}
                register={register}
                control={control}
                setValue={setValue}
                isError={errors[field.name]}
                clearErrors={clearErrors}
              />
            </div>
          ) : (
            ""
          )
        )}
      </ApplicationFormFieldset>
      <ApplicationFormInstruction
        topText={instruction.topText}
        bottomText={instruction.bottomText}
        scheme={instruction.scheme}
        markers={instruction.markers}
        isVisible={isMobile ? isInstructionVisible : true}
        onCloseButtonClick={() => setInstructionVisible(false)}
      >
        <p className="application__instruction-text">
          Вы можете указать двойную ставку, если выезжали дальше, чем на 100км
          или если проводили ремонтные работы несколько дней подряд.
        </p>
        <p className="application__instruction-text-w-link">
          Скачать шаблон акта для заполнения вы можете по{" "}
          <a className="form__link" href="/assets/template-act.pdf">
            ссылке.
          </a>
        </p>
      </ApplicationFormInstruction>
    </div>
  );
};

export default ApplicationFormStep4;
