import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { addApplication, getApplicationsByUserId } from "store/async";
import { setIsApplicationCreating } from "store/slice";
import { useAuth } from "hooks/useAuth";
import ApplicationFormStep1 from "components/ApplicationFormStep1/ApplicationFormStep1";
import ApplicationFormStep2 from "components/ApplicationFormStep2/ApplicationFormStep2";
import ApplicationFormStep3 from "components/ApplicationFormStep3/ApplicationFormStep3";
import ApplicationFormStep4 from "components/ApplicationFormStep4/ApplicationFormStep4";
import ApplicationFormStep5 from "components/ApplicationFormStep5/ApplicationFormStep5";
import ApplicationFormStep6 from "components/ApplicationFormStep6/ApplicationFormStep6";
import { ApplicationCreateFormSteps, AppPath } from "utils/const";

const ApplicationCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const number = useSelector((state) => state.newApplicationNumber);
  const step = useSelector((state) => state.applicationStep);
  const { uid, name, address, region, ogrn, inn } = useAuth();

  useEffect(() => {
    if (number) {
      history.push(`${AppPath.APPLICATIONS}/${number}`);
    }
  }, [number]);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    trigger,
    clearErrors,
  } = useForm();

  const onSubmit = async (data) => {
    dispatch(setIsApplicationCreating(true));
    const application = {
      ...data,
      applicant: { uid, company: name, address, region, ogrn, inn },
    };
    try {
      await dispatch(addApplication(application)).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="application application--create">
      <form
        className="form application__form"
        onSubmit={handleSubmit(onSubmit)}
      >
        {ApplicationCreateFormSteps.map((item) => {
          const isCurrentStep = item.number === step;
          switch (item.number) {
            case 1:
              return (
                isCurrentStep && (
                  <ApplicationFormStep1
                    key={item.number}
                    number={item.number}
                    title={item.title}
                    description={item.description}
                    fields={item.fields}
                    register={register}
                    control={control}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    trigger={trigger}
                  />
                )
              );
            case 2:
              return (
                isCurrentStep && (
                  <ApplicationFormStep2
                    key={item.number}
                    number={item.number}
                    title={item.title}
                    description={item.description}
                    fields={item.fields}
                    instruction={item.instruction}
                    register={register}
                    control={control}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    trigger={trigger}
                  />
                )
              );
            case 3:
              return (
                isCurrentStep && (
                  <ApplicationFormStep3
                    key={item.number}
                    number={item.number}
                    title={item.title}
                    fields={item.fields}
                    hiddenFields={item.hiddenFields}
                    instruction={item.instruction}
                    register={register}
                    control={control}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    trigger={trigger}
                  />
                )
              );
            case 4:
              return (
                isCurrentStep && (
                  <ApplicationFormStep4
                    key={item.number}
                    number={item.number}
                    title={item.title}
                    fields={item.fields}
                    instruction={item.instruction}
                    register={register}
                    control={control}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    trigger={trigger}
                  />
                )
              );
            case 5:
              return (
                isCurrentStep && (
                  <ApplicationFormStep5
                    key={item.number}
                    number={item.number}
                    title={item.title}
                    fields={item.fields}
                    hiddenFields={item.hiddenFields}
                    instruction={item.instruction}
                    register={register}
                    control={control}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    trigger={trigger}
                  />
                )
              );
            case 6:
              return (
                isCurrentStep && (
                  <ApplicationFormStep6
                    key={item.number}
                    number={item.number}
                    title={item.title}
                    description={item.description}
                    fields={item.fields}
                    register={register}
                    control={control}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    trigger={trigger}
                  />
                )
              );
          }
        })}
      </form>
    </div>
  );
};

export default ApplicationCreate;
