import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import {clearFilters, setQuery} from "../../store/slice";

import {QueryResetRoutes} from "../../utils/const";

const Page = ({ children }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const curRoute = pathname.split('/')[1];

  useEffect(() => {
      if (QueryResetRoutes.includes(`/${curRoute}`)) {
        dispatch(clearFilters());
        dispatch(setQuery(''));
      }
  }, [curRoute]);

  return (
    <>
      <Header />
      <main className="page container">{children}</main>
      <Footer />
    </>
  );
};

export default Page;
