import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormState, useWatch } from "react-hook-form";
import { setSparesVisible } from "store/slice";
import ApplicationFormFieldset from "components/ApplicationFormFieldset/ApplicationFormFieldset";
import ApplicationFormInstruction from "components/ApplicationFormInstruction/ApplicationFormInstruction";
import InputToggle from "components/InputToggle/InputToggle";
import InputSelect from "components/InputSelect/InputSelect";
import InputMasked from "components/InputMasked/InputMasked";
import Icon from "components/Icon/Icon";
import { Spares } from "utils/data";

const ApplicationFormStep5 = ({
  number,
  title,
  description,
  fields,
  hiddenFields,
  instruction,
  register,
  control,
  setValue,
  clearErrors,
  trigger,
}) => {
  const { errors } = useFormState({
    control,
  });

  const isSpares = useWatch({
    control,
    name: "isSpares",
  });

  const dispatch = useDispatch();
  const isMobile = useSelector((state) => state.isMobile);
  const sparesVisible = useSelector((state) => state.sparesVisible);
  const stepFields = fields.map((field) => field.name);
  const [dynamicFields, setDynamicFields] = useState([]);
  const [isInstructionVisible, setInstructionVisible] = useState(false);

  useEffect(() => {
    setDynamicFields(hiddenFields);
  }, [hiddenFields]);

  useEffect(() => {
    if (!isSpares) {
      setValue("isSpares", "no");
    }
  }, [isSpares]);

  const changeToggleHandler = (e) => {
    const { value } = e.target;

    if (value === "yes") {
      dispatch(setSparesVisible(1));
    } else {
      dispatch(setSparesVisible(0));
      dynamicFields.map((field) => setValue(field.name, {}));
    }
  };

  const addButtonClickHandler = () => {
    const newCount = sparesVisible + 1;
    dispatch(setSparesVisible(newCount));
  };

  const deleteButtonClickHandler = () => {
    const newCount = sparesVisible - 1;
    dispatch(setSparesVisible(newCount));
    dynamicFields.map((field, i) => {
      if (i === newCount) {
        setValue(`${field.name}.${i}.${field.title.fieldName}`, "");
        setValue(`${field.name}.${i}.${field.cost.fieldName}`, "");
      }
    });
  };

  return (
    <div className="application__step application__step--five">
      <ApplicationFormFieldset
        number={number}
        title={title}
        description={description}
        trigger={trigger}
        stepFields={stepFields}
        isInstructionButton={true}
        onInstructionButtonClick={() => setInstructionVisible(true)}
      >
        <div className="application__fields">
          {fields.map((field, i) =>
            field.type === "toggle" ? (
              <div key={i + 1} className="application__field">
                <InputToggle
                  name={field.name}
                  label={field.label}
                  params={field.params}
                  buttons={field.buttons}
                  register={register}
                  onChange={changeToggleHandler}
                />
              </div>
            ) : (
              ""
            )
          )}
          {dynamicFields.map((field, i) =>
            sparesVisible > 0 && i + 1 <= sparesVisible ? (
              <div key={i + 1} className="application__field-wrapper">
                {sparesVisible > 1 && i + 1 === sparesVisible && (
                  <button
                    type="button"
                    className="application__button-delete"
                    onClick={deleteButtonClickHandler}
                  >
                     <Icon name={isMobile ? "minus" : "delete"} />
                  </button>
                )}
                <div className="application__field">
                  <InputSelect
                    optionsData={Spares}
                    name={`${field.name}.${i}.${field.title.fieldName}`}
                    type={field.title.type}
                    label={field.title.label}
                    register={register}
                    params={field.title.params}
                    setValue={setValue}
                    isError={errors[field.name]}
                    clearErrors={clearErrors}
                    emptyMessage={field.title.emptyMessage}
                    isSearchable={true}
                    placeholder={field.title.placeholder}
                  />
                </div>
                <InputMasked
                  fieldClassName={"application__field"}
                  name={`${field.name}.${i}.${field.cost.fieldName}`}
                  label={field.cost.label}
                  params={field.cost.params}
                  mask={field.cost.mask}
                  formatChars={field.cost.formatChars}
                  placeholder={field.cost.placeholder}
                  defaultValue={""}
                  control={control}
                  isError={errors[field.name]}
                  onChangeHandler={() => clearErrors()}
                />
              </div>
            ) : (
              ""
            )
          )}
          {sparesVisible > 0 && sparesVisible < 6 && (
            <div className="application__field application__field--button">
              <button
                type="button"
                className="application__button-add"
                onClick={addButtonClickHandler}
              >
                <span className="button button--round button--medium-gray">
                  <Icon name="add" />
                </span>
                <span>Добавить ещё одну запасную часть</span>
              </button>
            </div>
          )}
        </div>
      </ApplicationFormFieldset>
      <ApplicationFormInstruction
        topText={instruction.topText}
        bottomText={instruction.bottomText}
        scheme={instruction.scheme}
        markers={instruction.markers}
        isVisible={isMobile ? isInstructionVisible : true}
        onCloseButtonClick={() => setInstructionVisible(false)}
      >
        <p className="application__instruction-text-w-link">
          <a
            className="form__link"
            href="https://disk.yandex.ru/d/cFAfj2CyN0HJlg"
            target="_blank"
          >
            Все схемы оборудования
          </a>
        </p>
      </ApplicationFormInstruction>
    </div>
  );
};

export default ApplicationFormStep5;
