import React from "react";
import { useForm } from "react-hook-form";
import InputMasked from "components/InputMasked/InputMasked";
import Input from "components/Input/Input";
import InputPrompt from "components/InputPrompt/InputPrompt";
import { Forms, Errors } from "utils/const";
import { formatPhoneNumber } from "utils/utils";

const UserForm = ({
  children,
  onSubmitHandler,
  onChangeHandler,
  errorCode,
  values = {},
}) => {
  const { register, handleSubmit, control, setValue } = useForm();
  const { userMeta } = Forms;

  return (
    <form className="form user__form" onSubmit={handleSubmit(onSubmitHandler)}>
      <div className="user__fieldset">
        {userMeta.map((field, i) =>
          field.type === "mask" ? (
            <InputMasked
            key={i + 1}
            fieldClassName={"user__field"}
            name={field.name}
            label={field.label}
            params={field.params}
            mask={field.mask}
            formatChars={field.formatChars}
            defaultValue={
              field.name === "phone" && values[field.name]
                ? formatPhoneNumber(values[field.name]) || ""
                : values[field.name] || ""
            }
            control={control}
            onChangeHandler={onChangeHandler}
          />
          ) : field.name === "address" ? (
            <div key={i + 1} className="user__field">
              <InputPrompt
                name={field.name}
                type={field.type}
                label={field.label}
                params={{
                  ...field.params,
                  onChange: onChangeHandler,
                  value: values[field.name] || "",
                }}
                register={register}
                setValue={setValue}
              />
            </div>
          ) : (
            <div key={i + 1} className="user__field">
              <Input
                name={field.name}
                type={field.type}
                label={field.label}
                params={{
                  ...field.params,
                  onChange: onChangeHandler,
                  value: values[field.name] || "",
                }}
                register={register}
                isError={errorCode && Errors[errorCode].type === field.name}
              />
              {errorCode && Errors[errorCode].type === field.name && (
                <p className="user__error">{Errors[errorCode].message}</p>
              )}
            </div>
          )
        )}
        <input
          type="hidden"
          name="region"
          id="region"
          {...register("region", { value: values["region"] || "" })}
        />
      </div>
      {children}
    </form>
  );
};

export default UserForm;
