import React from "react";
import Icon from "components/Icon/Icon";

const Footer = () => {
  return (
    <footer className="footer container">
      <div className="footer__wrapper">
        <div className="footer__contacts">
          <p className="footer__text">
            По любым вопросам работы сервиса обращайтесь в представительство
            дэсунг:
          </p>
          <a className="footer__contact-link" href="tel:+78002349335">
            <Icon name={"phone"} />
            <span>+7 800 234-93-35</span>
          </a>
          <a className="footer__contact-link" href="mailto:info@дэсунг.рус">
          <Icon name={"mail"} />
          <span>info@дэсунг.рус</span>
          </a>
        </div>
        <div className="footer__links">
          <a className="footer__text footer__text--link" href="#">
            Политика конфиденциальности
          </a>
          <a className="footer__text footer__text--link" href="#">
            Согласие на обработку персональных данных
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
