import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setServiceDatesVisible } from "store/slice";
import ApplicationItem from "components/ApplicationItem/ApplicationItem";
import ApplicationItemInput from "components/ApplicationItemInput/ApplicationItemInput";
import InputDate from "components/InputDate/InputDate";
import Icon from "components/Icon/Icon";

const ApplicationServiceInputs = ({
  inputs,
  title,
  modClass,
  control,
  setValue,
  clearErrors,
}) => {
  const dispatch = useDispatch();
  const serviceDatesVisible = useSelector((state) => state.serviceDatesVisible);
  const isMobile = useSelector((state) => state.isMobile);
  const addButtonClickHandler = () => {
    const newCount = serviceDatesVisible + 1;
    dispatch(setServiceDatesVisible(newCount));
  };

  const deleteButtonClickHandler = () => {
    const newCount = serviceDatesVisible - 1;
    dispatch(setServiceDatesVisible(newCount));
    inputs.map((input, i) => {
      if (i === newCount) {
        setValue(`${input.name}.${input.fieldName}`, "");
      }
    });
  };

  return (
    <div className="application__service-wrapper">
      {serviceDatesVisible
        ? inputs.map((input, i) => {
            return i + 1 <= serviceDatesVisible ? (
              <ApplicationItem
                key={i + 1}
                title={`${title} ${i + 1}:`}
              >
                <ApplicationItemInput modClass={modClass}>
                  <InputDate
                    name={`${input.name}.${input.fieldName}`}
                    params={input.params}
                    control={control}
                    onChangeHandler={() => clearErrors()}
                    fieldClassName={"application__service-date"}
                  />
                  {i + 1 === serviceDatesVisible && (
                  <button
                    type="button"
                    className="application__button-delete"
                    onClick={deleteButtonClickHandler}
                  >
                    <Icon name={isMobile ? "minus" : "delete"} />
                  </button>
                )}
                </ApplicationItemInput>
              </ApplicationItem>
            ) : (
              ""
            );
          })
        : ""}
      {serviceDatesVisible < 3 ? (
        <button
          type="button"
          className="application__button-add"
          onClick={addButtonClickHandler}
        >
          <span className="button button--round button--medium-gray">
          <Icon name="add" />
          </span>
          {isMobile ? <span>Добавить ещё дату тех. обслуживания</span> : <span>Добавить дату <br/>тех. обслуживания</span>}
        </button>
      ) : (
        ""
      )}
    </div>
  );
};

export default ApplicationServiceInputs;
