import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setEditedApplication } from "store/slice";
import ApplicationAdminButtons from "components/ApplicationAdminButtons/ApplicationAdminButtons";
import ApplicationComment from "components/ApplicationComment/ApplicationComment";
import Icon from "components/Icon/Icon";
import { getExternalComment } from "utils/utils";

const ApplicationHeaderAdmin = ({
  number,
  applicant,
  status,
  uid,
  daesungComments,
  isEdit,
}) => {
  const dispatch = useDispatch();
  const isMobile = useSelector((state) => state.isMobile);
  const isApplicationUpdated = useSelector(
    (state) => state.isApplicationUpdated
  );

  const externalComment = getExternalComment(status, daesungComments) || "";
  const internalComment = daesungComments?.internal || "";

  const cancelEditButtonClick = () => {
    dispatch(setEditedApplication(null));
  };

  return (
    <div className="application__header">
      <div className="application__header-wrapper">
        <div className="application__title-wrapper">
          <h2 className="application__title title">{`${applicant.company}${!isMobile ? `, ${applicant.region}` : ""}`}</h2>
          {!isMobile && <p className="application__subtitle title">{`#${number}`}</p>}
        </div>
        {isEdit && !isApplicationUpdated ? (
          <div className="application__buttons">
            <button
              className="application__button-cancel button button--medium-gray"
              type="button"
              onClick={cancelEditButtonClick}
            >
              Отмена
            </button>
            <button
              className="application__button-save button button--black"
              type="submit"
            >
              <Icon name="check" />
              <span>Сохранить изменения</span>
            </button>
          </div>
        ) : isApplicationUpdated ? (
          <p className="application__alert button button--alert button--green">
            <Icon name="check" />
            <span>Изменения сохранены</span>
          </p>
        ) : (
          <ApplicationAdminButtons status={status} uid={uid} number={number} />
        )}
      </div>
      {(externalComment || internalComment) && (
        <div className="application__comments">
          {!isEdit && externalComment && (
            <ApplicationComment
              externalComment={externalComment}
              isAdmin={true}
            />
          )}
          {internalComment && (
            <ApplicationComment
              internalComment={internalComment}
              isAdmin={true}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ApplicationHeaderAdmin;
