import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  getApplications,
  getApplicationsByUserId,
  addApplication,
  updateApplication,
  getUsers,
  loginUser,
  logoutUser,
  addUser,
  updateUser,
  resetPassword,
} from "./async";
import { formatDate, getSumNumber } from "utils/utils";
import { Status } from "utils/const";

const initialState = {
  applications: [],
  users: [],
  authorizedUser: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : {},
  filters: {},
  filteredData: [],
  applicationStep: 1,
  newApplicationNumber: null,
  serviceDatesVisible: 0,
  sparesVisible: 0,
  adminModal: null,
  query: "",
  editedApplication: null,
  updatedApplicationData: null,
  editedUser: null,
  registerError: "",
  editingError: "",
  loginError: "",
  resetError: "",
  resetSuccess: false,
  isApplicationCreating: false,
  isApplicationUpdated: false,
  isMobile: false,
};

const daesungSlice = createSlice({
  name: "daesung",
  initialState,
  reducers: {
    setIsMobile: (state, action) => {
      state.isMobile = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    clearFilters: (state, action) => {
      state.filters = {};
    },
    setFilteredData: (state, action) => {
      state.filteredData = action.payload;
    },
    setStep: (state, action) => {
      state.applicationStep = action.payload;
    },
    setServiceDatesVisible: (state, action) => {
      state.serviceDatesVisible = action.payload;
    },
    setSparesVisible: (state, action) => {
      state.sparesVisible = action.payload;
    },
    setAdminModal: (state, action) => {
      state.adminModal = action.payload;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setEditedApplication: (state, action) => {
      state.editedApplication = action.payload;
    },
    setUpdatedApplicationData: (state, action) => {
      state.updatedApplicationData = action.payload;
    },
    setEditedUser: (state, action) => {
      state.editedUser = action.payload;
    },
    setIsApplicationCreating: (state, action) => {
      state.isApplicationCreating = action.payload;
    },
    setIsApplicationUpdated: (state, action) => {
      state.isApplicationUpdated = action.payload;
    },
    clearRegisterError: (state, action) => {
      state.registerError = "";
    },
    clearEditingError: (state, action) => {
      state.editingError = "";
    },
    clearLoginError: (state, action) => {
      state.loginError = "";
    },
    clearResetError: (state, action) => {
      state.resetError = "";
    },
    clearResetSuccess: (state, action) => {
      state.resetSuccess = false;
    },
  },
  extraReducers: {
    [getApplications.fulfilled]: (state, action) => {
      state.applications = action.payload;
    },
    [getApplicationsByUserId.fulfilled]: (state, action) => {
      state.applications = action.payload;
    },
    [addApplication.fulfilled]: (state, action) => {
      state.newApplicationNumber = action.payload;
    },
    [updateApplication.fulfilled]: (state, action) => {
      state.isApplicationUpdated = false;
      state.editedApplication = null;
      state.updatedApplicationData = null;
    },
    [getUsers.fulfilled]: (state, action) => {
      state.users = action.payload;
    },
    [addUser.rejected]: (state, action) => {
      state.registerError = action.payload;
    },
    [updateUser.rejected]: (state, action) => {
      state.editingError = action.payload;
    },
    [loginUser.fulfilled]: (state, action) => {
      const user = {
        uid: action.payload.user_id,
        email: action.payload.email,
        name: action.payload.name,
        isAdmin: action.payload.admin || false,
        phone: action.payload.phone_number,
        address: action.payload.address,
        region: action.payload.region,
        inn: action.payload.inn,
        ogrn: action.payload.ogrn,
      };
      state.authorizedUser = user;
      localStorage.setItem("user", JSON.stringify(user));
    },
    [logoutUser.fulfilled]: (state, action) => {
      state.applications = [];
      state.authorizedUser = {};
      localStorage.removeItem("user");
    },
    [loginUser.rejected]: (state, action) => {
      state.loginError = action.payload;
    },
    [resetPassword.fulfilled]: (state, action) => {
      state.resetSuccess = true;
    },
    [resetPassword.rejected]: (state, action) => {
      state.resetError = action.payload;
    },
  },
});

export const selectApplicationById = createSelector(
  [(state) => state.applications, (state, id) => id],
  (applications, id) => {
    return applications.filter((item) => item.number === id);
  }
);

export const selectRegions = createSelector(
  (state) => state.applications,
  (applications) => {
    return applications.reduce((acc, item) => {
      if (!acc.some((obj) => obj.value === item.applicant.region)) {
        acc.push({
          value: item.applicant.region,
        });
      }
      return acc;
    }, []);
  }
);

export const selectMinAndMaxSum = createSelector(
  (state) => state.applications,
  (applications) => {
    const sums = applications.reduce((acc, item) => {
      const sum = getSumNumber(item.visitCost, item.worksCost, item.spares);
      acc.push(sum);

      return acc;
    }, []);

    const minValue = Math.min(...sums);
    const maxValue = Math.max(...sums);

    return { minValue, maxValue };
  }
);

export const selectSpares = createSelector(
  (state) => state.applications,
  (applications) => {
    const allSpares = applications.reduce((acc, item) => {
      if (item.spares?.length) {
        acc = [...acc, ...item.spares];
      }
      return acc;
    }, []);

    const sparesList = allSpares.reduce((acc, item) => {
      if (!acc.some((obj) => obj.value === item.title)) {
        acc.push({
          value: item.title,
        });
      }
      return acc;
    }, []);

    return sparesList.sort((a, b) => (a.value > b.value ? 1 : -1));
  }
);

export const selectExcelData = createSelector(
  (state) => state.filteredData,
  (filteredData) => {
    return filteredData.reduce((acc, item) => {
      acc.push({
        "№": item.number,
        "Модель котла": item.model,
        "Серийный номер": item.serial,
        "Дата запуска котла": formatDate(item.launchDate.seconds),
        "Неисправность": item.breakdown,
        "Решение Daesung": Status[item.status].name,
        "Дата обращения": formatDate(item.date.seconds),
        "Имя": item.client.name,
        "Адрес": item.client.address,
        "Телефон": item.client.phone,
        "Регион": item.applicant.region,
        "Компания": item.applicant.company,
        "Сумма к оплате": getSumNumber(
          item.visitCost,
          item.worksCost,
          item.spares
        ),
        "Оплачен": item.status === "paid" ? "Да" : "Нет",
        "Работа": Number(item.worksCost),
        "Выезд": Number(item.visitCost),
        "Запчасти":
          getSumNumber(item.visitCost, item.worksCost, item.spares) -
          Number(item.worksCost) -
          Number(item.visitCost),
        "Комментарий": item.daesungComments?.internal ? item.daesungComments.internal : "Нет",
        "Комментарий отправителя": item?.comment ? item.comment : "Нет",
        "Запасная часть": item.spares?.length ? item.spares.map((spare) => spare.title).join('; ') : "Нет",
      });

      return acc;
    }, []);
  }
);

const Reducer = daesungSlice.reducer;

export const {
  setIsMobile,
  setFilters,
  clearFilters,
  setFilteredData,
  setStep,
  setServiceDatesVisible,
  setSparesVisible,
  setAdminModal,
  setQuery,
  setEditedApplication,
  setUpdatedApplicationData,
  setEditedUser,
  setIsApplicationCreating,
  setIsApplicationUpdated,
  clearRegisterError,
  clearEditingError,
  clearLoginError,
  clearResetError,
  clearResetSuccess,
} = daesungSlice.actions;

export default Reducer;
