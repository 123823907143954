import React, { useState } from "react";
import { useFormState } from "react-hook-form";
import { useSelector } from "react-redux";
import ApplicationFormFieldset from "components/ApplicationFormFieldset/ApplicationFormFieldset";
import ApplicationFormInstruction from "components/ApplicationFormInstruction/ApplicationFormInstruction";
import InputUpload from "components/InputUpload/InputUpload";
import InputSelect from "components/InputSelect/InputSelect";
import InputMasked from "components/InputMasked/InputMasked";
import Input from "components/Input/Input";
import { Models } from "utils/data";

const ApplicationFormStep2 = ({
  number,
  title,
  description,
  fields,
  instruction,
  register,
  control,
  setValue,
  clearErrors,
  trigger,
}) => {
  const { errors } = useFormState({
    control,
  });

  const isMobile = useSelector((state) => state.isMobile);
  const [isInstructionVisible, setInstructionVisible] = useState(false);
  const stepFields = fields.map((field) => field.name);

  return (
    <div className="application__step application__step--two">
      <ApplicationFormFieldset
        number={number}
        title={title}
        description={description}
        trigger={trigger}
        stepFields={stepFields}
        isInstructionButton={true}
        onInstructionButtonClick={() => setInstructionVisible(true)}
      >
        <div className="application__fields">
          {fields.map((field, i) =>
            field.type === "mask" ? (
              <InputMasked
                key={i + 1}
                fieldClassName={"application__field"}
                name={field.name}
                label={field.label}
                params={field.params}
                mask={field.mask}
                defaultValue={""}
                control={control}
                isError={errors[field.name]}
                onChangeHandler={() => clearErrors()}
                formatChars={field.formatChars}
              />
            ) : field.type === "file" ? (
              <div key={i + 1} className="application__field">
                <InputUpload
                  name={field.name}
                  label={field.label}
                  params={field.params}
                  register={register}
                  control={control}
                  setValue={setValue}
                />
              </div>
            ) : field.fieldType === "select" ? (
              <div key={i + 1} className="application__field">
                <InputSelect
                  optionsData={Models}
                  name={field.name}
                  type={field.type}
                  label={field.label}
                  register={register}
                  params={field.params}
                  setValue={setValue}
                  isError={errors[field.name]}
                  clearErrors={clearErrors}
                  emptyMessage={field.emptyMessage}
                  isSearchable={true}
                  placeholder={field.placeholder}
                />
              </div>
            ) : (
              <div key={i + 1} className="application__field">
                <Input
                  name={field.name}
                  type={field.type}
                  label={field.label}
                  params={{ ...field.params, onChange: () => clearErrors() }}
                  register={register}
                  isError={errors[field.name]}
                />
              </div>
            )
          )}
        </div>
      </ApplicationFormFieldset>
      <ApplicationFormInstruction
        topText={instruction.topText}
        bottomText={instruction.bottomText}
        scheme={instruction.scheme}
        markers={isMobile ? instruction.markersMobile : instruction.markers}
        isVisible={isMobile ? isInstructionVisible : true}
        onCloseButtonClick={() => setInstructionVisible(false)}
      />
    </div>
  );
};

export default ApplicationFormStep2;
