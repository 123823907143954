export const Periods = [
  {
    value: "24 месяца",
  },
  {
    value: "36 месяцев",
  },
];

export const Models = [
  {
    value: "E10",
  },
  {
    value: "E12",
  },
  {
    value: "E14",
  },
  {
    value: "E17",
  },
  {
    value: "E20",
  },
  {
    value: "E21",
  },
  {
    value: "E24",
  },
  {
    value: "E25",
  },
  {
    value: "E30",
  },
  {
    value: "E35",
  },
  {
    value: "A16",
  },
  {
    value: "A21",
  },
  {
    value: "A24",
  },
  {
    value: "A30",
  },
  {
    value: "A35",
  },
  {
    value: "A41",
  },
  {
    value: "A MAX 16",
  },
  {
    value: "A MAX 21",
  },
  {
    value: "A MAX 24",
  },
  {
    value: "A MAX 30",
  },
  {
    value: "A MAX 35",
  },
  {
    value: "A MAX 41",
  },
  {
    value: "A MAX 50 ",
  },
  {
    value: "C19",
  },
  {
    value: "C24",
  },
  {
    value: "C30",
  },
  {
    value: "C35",
  },
  {
    value: "C41",
  },
  {
    value: "S9",
  },
  {
    value: "C PLUS 19",
  },
  {
    value: "C PLUS 24",
  },
  {
    value: "C PLUS 30",
  },
  {
    value: "C PLUS 35",
  },
  {
    value: "A PLUS 16",
  },
  {
    value: "A PLUS 21",
  },
  {
    value: "A PLUS 24",
  },
  {
    value: "A PLUS 30",
  },
  {
    value: "A PLUS 35",
  },
  {
    value: "A PLUS 41",
  },
  {
    value: "Platinum 3.13",
  },
  {
    value: "Platinum 3.16",
  },
  {
    value: "Platinum 3.20",
  },
  {
    value: "Platinum 3.25",
  },
  {
    value: "Platinum 3.30",
  },
  {
    value: "Platinum 3.35",
  },
  {
    value: "ESR-2.13",
  },
  {
    value: "ESR-2.16",
  },
  {
    value: "ESR-2.20",
  },
  {
    value: "ESR-2.25",
  },
  {
    value: "ESR-2.30",
  },
  {
    value: "ESR-2.35",
  },
];

export const Spares = [
  {
    value: "пульт управления арт.52081201 пульт(dr-100u) для котлов d",
  },
  {
    value: "пульт управления арт.52082143 пульт(dr-100uc) для котлов c",
  },
  {
    value: "пульт управления арт.52082133 пульт(dr-102) для котлов e",
  },
  {
    value: "пульт управления арт.52082133 пульт(dr-102) для котлов e",
  },
  {
    value: "пульт управления арт.52082133 пульт(dr-102) для котлов e",
  },
  {
    value: "пульт управления арт.52082133 пульт(dr-102) для котлов e",
  },
  {
    value: "пульт управления арт.52082133 пульт(dr-102) для котлов e",
  },
  {
    value: "пульт управления арт.52080810 пульт(dsr-220r) для котлов esr",
  },
  {
    value: "пульт управления арт.52080973 пульт(dsr-220rh) для котлов pl",
  },
  {
    value: "пульт управления арт.52082080 пульт(dsr-225u) для котлов a16-41",
  },
  {
    value: "пульт управления арт.52081203 пульт(dsr-400u) для котлов a50",
  },
  {
    value: "пульт управления арт.52081182 пульт(vd-100) для котлов s9",
  },
  {
    value: "газовый клапан арт.52030280 для котлов a16-41, c, d, esr",
  },
  {
    value: "газовый клапан арт.52030277 для котлов a50",
  },
  {
    value: "газовый клапан арт.52030280 для котлов a16-41, c, d, esr",
  },
  {
    value: "газовый клапан арт.52030280 для котлов a16-41, c, d, esr",
  },
  {
    value: "газовый клапан арт.52030346 для котлов e",
  },
  {
    value: "газовый клапан арт.52030280 для котлов a16-41, c, d, esr",
  },
  {
    value: "газовый клапан арт.52030308 для котлов pl3.13-35",
  },
  {
    value: "газовый клапан арт.42030332 для котлов s9",
  },
  {
    value: "датчик перегрева арт.52080390 для котлов a, c, d, e, esr, pl",
  },
  {
    value: "датчик перегрева арт.52080390 для котлов a, c, d, e, esr, pl",
  },
  {
    value: "датчик перегрева арт.52080390 для котлов a, c, d, e, esr, pl",
  },
  {
    value: "датчик перегрева арт.52080390 для котлов a, c, d, e, esr, pl",
  },
  {
    value: "датчик перегрева арт.52080390 для котлов a, c, d, e, esr, pl",
  },
  {
    value: "датчик перегрева арт.52080390 для котлов a, c, d, e, esr, pl",
  },
  {
    value: "датчик перегрева арт.42082066 труба подачи для котлов s9",
  },
  {
    value:
      "датчик перегрева арт.52081017 колектор дымовых газов для котлов pl3.13-35",
  },
  {
    value: "датчик перегрева арт.42082068 теплообменник для котлов s9",
  },
  {
    value: "теплообменник для отопления арт.52070549 для котлов a16-21, d16-21",
  },
  {
    value: "теплообменник для отопления арт.52070581 для котлов a24",
  },
  {
    value: "теплообменник для отопления арт.52070582 для котлов a30-41, d24-41",
  },
  {
    value: "теплообменник для отопления арт.52070562 для котлов a50",
  },
  {
    value: "теплообменник для отопления арт.52070448 для котлов c19-24",
  },
  {
    value: "теплообменник для отопления арт.52070449 для котлов c30-41",
  },
  {
    value: "теплообменник для отопления арт.52070549 для котлов a16-21, d16-21",
  },
  {
    value: "теплообменник для отопления арт.52070582 для котлов a30-41, d24-41",
  },
  {
    value: "теплообменник для отопления арт.52070808 для котлов e12-17",
  },
  {
    value: "теплообменник для отопления арт.52070809 для котлов e21-25",
  },
  {
    value:
      "теплообменник для отопления арт.52070392 для котлов esr-2.13-16, pl3.13-16",
  },
  {
    value:
      "теплообменник для отопления арт.52070391 для котлов esr-2.20, pl3.20",
  },
  {
    value:
      "теплообменник для отопления арт.52070429 для котлов esr-2.25-35, pl3.25-35",
  },
  {
    value:
      "теплообменник для отопления арт.52070392 для котлов esr-2.13-16, pl3.13-16",
  },
  {
    value:
      "теплообменник для отопления арт.52070391 для котлов esr-2.20, pl3.20",
  },
  {
    value:
      "теплообменник для отопления арт.52070429 для котлов esr-2.25-35, pl3.25-35",
  },
  {
    value: "теплообменник для отопления арт.42070790 для котлов s9",
  },
  {
    value: "датчик температуры отопления арт.52080459 для котлов a16-41, d",
  },
  {
    value: "датчик температуры отопления арт.53130693 для котлов a50",
  },
  {
    value: "датчик температуры отопления арт.52080459 для котлов a16-41, d",
  },
  {
    value: "датчик температуры отопления арт.52080250 для котлов esr, pl",
  },
  {
    value: "датчик температуры отопления арт.52080250 для котлов esr, pl",
  },
  {
    value: "форсунка(природный газ) арт.52020370 для котлов a16-21, d16-21",
  },
  {
    value:
      "форсунка(природный газ) арт.52020297 для котлов a24, c19-24, esr-2.20, pl3.20",
  },
  {
    value:
      "форсунка(природный газ) арт.52020338 для котлов a30-35, d30-35, esr-2.25-30, pl3.25-30",
  },
  {
    value:
      "форсунка(природный газ) арт.52020341 для котлов a41, c41, d41, esr-2.35, pl3.35",
  },
  {
    value: "форсунка(природный газ) арт.52020355 для котлов a50",
  },
  {
    value:
      "форсунка(природный газ) арт.52020297 для котлов a24, c19-24, esr-2.20, pl3.20",
  },
  {
    value: "форсунка(природный газ) арт.52020344 для котлов c30-35",
  },
  {
    value:
      "форсунка(природный газ) арт.52020341 для котлов a41, c41, d41, esr-2.35, pl3.35",
  },
  {
    value: "форсунка(природный газ) арт.52020370 для котлов a16-21, d16-21",
  },
  {
    value: "форсунка(природный газ) арт.52020425 для котлов d24",
  },
  {
    value:
      "форсунка(природный газ) арт.52020338 для котлов a30-35, d30-35, esr-2.25-30, pl3.25-30",
  },
  {
    value:
      "форсунка(природный газ) арт.52020341 для котлов a41, c41, d41, esr-2.35, pl3.35",
  },
  {
    value: "форсунка(природный газ) арт.52020547 для котлов e12-17",
  },
  {
    value: "форсунка(природный газ) арт.52020548 для котлов e21-25",
  },
  {
    value: "форсунка(природный газ) арт.52020292 для котлов esr-2.13, pl3.13",
  },
  {
    value: "форсунка(природный газ) арт.52020292 для котлов esr-2.13, pl3.13",
  },
  {
    value: "форсунка(природный газ) арт.52020294 для котлов esr-2.16, pl3.16",
  },
  {
    value:
      "форсунка(природный газ) арт.52020297 для котлов a24, c19-24, esr-2.20, pl3.20",
  },
  {
    value:
      "форсунка(природный газ) арт.52020338 для котлов a30-35, d30-35, esr-2.25-30, pl3.25-30",
  },
  {
    value:
      "форсунка(природный газ) арт.52020341 для котлов a41, c41, d41, esr-2.35, pl3.35",
  },
  {
    value: "форсунка(природный газ) арт.52020292 для котлов esr-2.13, pl3.13",
  },
  {
    value: "форсунка(природный газ) арт.52020292 для котлов esr-2.13, pl3.13",
  },
  {
    value: "форсунка(природный газ) арт.52020294 для котлов esr-2.16, pl3.16",
  },
  {
    value:
      "форсунка(природный газ) арт.52020297 для котлов a24, c19-24, esr-2.20, pl3.20",
  },
  {
    value:
      "форсунка(природный газ) арт.52020338 для котлов a30-35, d30-35, esr-2.25-30, pl3.25-30",
  },
  {
    value:
      "форсунка(природный газ) арт.52020341 для котлов a41, c41, d41, esr-2.35, pl3.35",
  },
  {
    value: "форсунка(сжиженный газ) арт.52020371 для котлов a16-21, d16-21",
  },
  {
    value:
      "форсунка(сжиженный газ) арт.52020296 для котлов a24, c19-24, esr-2.20, pl3.20",
  },
  {
    value:
      "форсунка(сжиженный газ) арт.52020340 для котлов a30-35, c30-35, d30-35, pl3.25-30, esr-2.25-30",
  },
  {
    value:
      "форсунка(сжиженный газ) арт.52020342 для котлов a41, c41, d41, esr-2.35, pl3.35",
  },
  {
    value: "форсунка(сжиженный газ) арт.52020357 для котлов a50",
  },
  {
    value:
      "форсунка(сжиженный газ) арт.52020296 для котлов a24, c19-24, esr-2.20, pl3.20",
  },
  {
    value:
      "форсунка(сжиженный газ) арт.52020340 для котлов a30-35, c30-35, d30-35, pl3.25-30, esr-2.25-30",
  },
  {
    value:
      "форсунка(сжиженный газ) арт.52020342 для котлов a41, c41, d41, esr-2.35, pl3.35",
  },
  {
    value: "форсунка(сжиженный газ) арт.52020371 для котлов a16-21, d16-21",
  },
  {
    value: "форсунка(сжиженный газ) арт.52020551 для котлов d24",
  },
  {
    value:
      "форсунка(сжиженный газ) арт.52020340 для котлов a30-35, c30-35, d30-35, pl3.25-30, esr-2.25-30",
  },
  {
    value:
      "форсунка(сжиженный газ) арт.52020342 для котлов a41, c41, d41, esr-2.35, pl3.35",
  },
  {
    value: "форсунка(сжиженный газ) арт.52020549 для котлов e12-17",
  },
  {
    value: "форсунка(сжиженный газ) арт.52020550 для котлов e21-25",
  },
  {
    value: "форсунка(сжиженный газ) арт.52020281 для котлов esr-2.13, pl3.13",
  },
  {
    value: "форсунка(сжиженный газ) арт.52020277 для котлов esr-2.16, pl3.16",
  },
  {
    value:
      "форсунка(сжиженный газ) арт.52020296 для котлов a24, c19-24, esr-2.20, pl3.20",
  },
  {
    value:
      "форсунка(сжиженный газ) арт.52020296 для котлов a24, c19-24, esr-2.20, pl3.20",
  },
  {
    value:
      "форсунка(сжиженный газ) арт.52020340 для котлов a30-35, c30-35, d30-35, pl3.25-30, esr-2.25-30",
  },
  {
    value:
      "форсунка(сжиженный газ) арт.52020342 для котлов a41, c41, d41, esr-2.35, pl3.35",
  },
  {
    value: "форсунка(сжиженный газ) арт.52020281 для котлов esr-2.13, pl3.13",
  },
  {
    value: "форсунка(сжиженный газ) арт.52020277 для котлов esr-2.16, pl3.16",
  },
  {
    value:
      "форсунка(сжиженный газ) арт.52020296 для котлов a24, c19-24, esr-2.20, pl3.20",
  },
  {
    value:
      "форсунка(сжиженный газ) арт.52020340 для котлов a30-35, c30-35, d30-35, pl3.25-30, esr-2.25-30",
  },
  {
    value:
      "форсунка(сжиженный газ) арт.52020342 для котлов a41, c41, d41, esr-2.35, pl3.35",
  },
  {
    value:
      "регулятор протока арт.52060611 для котлов a16-24, c19-24, d24, esr-2.13-20, pl3.13-20",
  },
  {
    value:
      "регулятор протока арт.52060612 для котлов a30-41, c30-41, d30-41, pl3.25-35, esr-2.25-35",
  },
  {
    value:
      "регулятор протока арт.52060611 для котлов a16-24, c19-24, d24, esr-2.13-20, pl3.13-20",
  },
  {
    value:
      "регулятор протока арт.52060612 для котлов a30-41, c30-41, d30-41, pl3.25-35, esr-2.25-35",
  },
  {
    value:
      "регулятор протока арт.52060611 для котлов a16-24, c19-24, d24, esr-2.13-20, pl3.13-20",
  },
  {
    value:
      "регулятор протока арт.52060612 для котлов a30-41, c30-41, d30-41, pl3.25-35, esr-2.25-35",
  },
  {
    value:
      "регулятор протока арт.52060611 для котлов a16-24, c19-24, d24, esr-2.13-20, pl3.13-20",
  },
  {
    value:
      "регулятор протока арт.52060612 для котлов a30-41, c30-41, d30-41, pl3.25-35, esr-2.25-35",
  },
  {
    value:
      "регулятор протока арт.52060611 для котлов a16-24, c19-24, d24, esr-2.13-20, pl3.13-20",
  },
  {
    value:
      "регулятор протока арт.52060612 для котлов a30-41, c30-41, d30-41, pl3.25-35, esr-2.25-35",
  },
  {
    value: "труба обратки арт.52091496 труба обратки для котлов e",
  },
  {
    value: "труба обратки арт.42091499 труба обратки 1 для котлов s9",
  },
  {
    value: "труба обратки арт.42091498 труба обратки 2 для котлов s9",
  },
  {
    value: "труба обратки арт.43030304 соединитель трубы обратки для котлов s9",
  },
  {
    value: "обратный клапан арт.52060320 для котлов a16-21, d16-21",
  },
  {
    value: "обратный клапан арт.52060520 для котлов a50",
  },
  {
    value: "обратный клапан арт.52060320 для котлов a16-21, d16-21",
  },
  {
    value: "клапан подпитки арт.52060228 для котлов a, c, d, esr, pl",
  },
  {
    value: "клапан подпитки арт.52060228 для котлов a, c, d, esr, pl",
  },
  {
    value: "клапан подпитки арт.52060228 для котлов a, c, d, esr, pl",
  },
  {
    value: "клапан подпитки арт.52060228 для котлов a, c, d, esr, pl",
  },
  {
    value: "клапан подпитки арт.52060228 для котлов a, c, d, esr, pl",
  },
  {
    value: "горелка арт.52020366 для котлов a16-21, d16-21",
  },
  {
    value: "горелка арт.52020299 для котлов a24, c19-24, esr-2.20, pl3.20",
  },
  {
    value:
      "горелка арт.52020339 для котлов a30-41, c30-41, d24-41, esr-2.25-35, pl3.25-35",
  },
  {
    value: "горелка арт.52020356 для котлов a50",
  },
  {
    value: "горелка арт.52020299 для котлов a24, c19-24, esr-2.20, pl3.20",
  },
  {
    value:
      "горелка арт.52020339 для котлов a30-41, c30-41, d24-41, esr-2.25-35, pl3.25-35",
  },
  {
    value: "горелка арт.52020366 для котлов a16-21, d16-21",
  },
  {
    value:
      "горелка арт.52020339 для котлов a30-41, c30-41, d24-41, esr-2.25-35, pl3.25-35",
  },
  {
    value: "горелка арт.52020545 для котлов e12-17",
  },
  {
    value: "горелка арт.52020546 для котлов e21-25",
  },
  {
    value: "горелка арт.52020282 для котлов esr-2.13",
  },
  {
    value: "горелка арт.52020278 для котлов esr-2.16, pl3.13-16",
  },
  {
    value: "горелка арт.52020299 для котлов a24, c19-24, esr-2.20, pl3.20",
  },
  {
    value:
      "горелка арт.52020339 для котлов a30-41, c30-41, d24-41, esr-2.25-35, pl3.25-35",
  },
  {
    value: "горелка арт.52020278 для котлов esr-2.16, pl3.13-16",
  },
  {
    value: "горелка арт.52020299 для котлов a24, c19-24, esr-2.20, pl3.20",
  },
  {
    value:
      "горелка арт.52020339 для котлов a30-41, c30-41, d24-41, esr-2.25-35, pl3.25-35",
  },
  {
    value: "3-х ходовой клапан арт.52040125 для котлов a16-41, d",
  },
  {
    value: "3-х ходовой клапан арт.52040100 для котлов a50",
  },
  {
    value: "3-х ходовой клапан арт.52040105 для котлов c, esr",
  },
  {
    value: "3-х ходовой клапан арт.52040125 для котлов a16-41, d",
  },
  {
    value: "3-х ходовой клапан арт.52050244 для котлов e",
  },
  {
    value: "3-х ходовой клапан арт.52040105 для котлов c, esr",
  },
  {
    value: "3-х ходовой клапан арт.52040178 для котлов pl",
  },
  {
    value:
      "электрод розжига арт.52052063 для котлов a16-24, c19-24, d16-21, esr-2.13-20, pl3.13-20",
  },
  {
    value:
      "электрод розжига арт.52020527 для котлов a30-41, c30-41, d24-41, esr-2.25-35, pl3.25-35",
  },
  {
    value: "электрод розжига арт.52020405 для котлов a50",
  },
  {
    value:
      "электрод розжига арт.52052063 для котлов a16-24, c19-24, d16-21, esr-2.13-20, pl3.13-20",
  },
  {
    value:
      "электрод розжига арт.52020527 для котлов a30-41, c30-41, d24-41, esr-2.25-35, pl3.25-35",
  },
  {
    value:
      "электрод розжига арт.52052063 для котлов a16-24, c19-24, d16-21, esr-2.13-20, pl3.13-20",
  },
  {
    value:
      "электрод розжига арт.52020527 для котлов a30-41, c30-41, d24-41, esr-2.25-35, pl3.25-35",
  },
  {
    value: "электрод розжига арт.52020544 для котлов e",
  },
  {
    value:
      "электрод розжига арт.52052063 для котлов a16-24, c19-24, d16-21, esr-2.13-20, pl3.13-20",
  },
  {
    value:
      "электрод розжига арт.52020527 для котлов a30-41, c30-41, d24-41, esr-2.25-35, pl3.25-35",
  },
  {
    value:
      "электрод розжига арт.52052063 для котлов a16-24, c19-24, d16-21, esr-2.13-20, pl3.13-20",
  },
  {
    value:
      "электрод розжига арт.52020527 для котлов a30-41, c30-41, d24-41, esr-2.25-35, pl3.25-35",
  },
  {
    value: "электрод розжига арт.42020521 для котлов s9",
  },
  {
    value:
      "предохранительный клапан арт.52060255 для котлов a16-21, d, esr-2.13-20, pl3.13-20",
  },
  {
    value:
      "предохранительный клапан арт.52060337 для котлов a24-41, c, d24-41, esr-2.25-35, pl3.25-35",
  },
  {
    value: "предохранительный клапан арт.52060161 для котлов a50",
  },
  {
    value:
      "предохранительный клапан арт.52060337 для котлов a24-41, c, d24-41, esr-2.25-35, pl3.25-35",
  },
  {
    value:
      "предохранительный клапан арт.52060255 для котлов a16-21, d, esr-2.13-20, pl3.13-20",
  },
  {
    value:
      "предохранительный клапан арт.52060337 для котлов a24-41, c, d24-41, esr-2.25-35, pl3.25-35",
  },
  {
    value: "предохранительный клапан арт.52060601 для котлов e",
  },
  {
    value:
      "предохранительный клапан арт.52060255 для котлов a16-21, d, esr-2.13-20, pl3.13-20",
  },
  {
    value:
      "предохранительный клапан арт.52060337 для котлов a24-41, c, d24-41, esr-2.25-35, pl3.25-35",
  },
  {
    value:
      "предохранительный клапан арт.52060255 для котлов a16-21, d, esr-2.13-20, pl3.13-20",
  },
  {
    value:
      "предохранительный клапан арт.52060337 для котлов a24-41, c, d24-41, esr-2.25-35, pl3.25-35",
  },
  {
    value: "манометр арт.52080744 для котлов a16-41, pl",
  },
  {
    value: "манометр арт.52080774 для котлов a50",
  },
  {
    value: "манометр арт.52082120 для котлов e",
  },
  {
    value: "манометр арт.52080653 для котлов esr",
  },
  {
    value: "манометр арт.52080744 для котлов a16-41, pl",
  },
  {
    value: "датчик давления воды арт.52060305 для котлов a16-41, esr, pl",
  },
  {
    value: "датчик давления воды арт.52060600 для котлов e",
  },
  {
    value: "датчик давления воды арт.52060305 для котлов a16-41, esr, pl",
  },
  {
    value: "датчик давления воды арт.52060305 для котлов a16-41, esr, pl",
  },
  {
    value: "воздухоотводчик в сборе арт.52060321 для котлов a16-21, d16-21",
  },
  {
    value: "воздухоотводчик в сборе арт.52060330 для котлов a24-41, d24-41",
  },
  {
    value: "воздухоотводчик в сборе арт.52050107 для котлов a50",
  },
  {
    value: "воздухоотводчик в сборе арт.52060326 для котлов c",
  },
  {
    value: "воздухоотводчик в сборе арт.52060321 для котлов a16-21, d16-21",
  },
  {
    value: "воздухоотводчик в сборе арт.52060330 для котлов a24-41, d24-41",
  },
  {
    value: "воздухоотводчик в сборе арт.52060309 для котлов esr, pl",
  },
  {
    value: "воздухоотводчик в сборе арт.52060309 для котлов esr, pl",
  },
  {
    value: "теплообменник гвс арт.52060582 для котлов a16-21, d16-21",
  },
  {
    value: "теплообменник гвс арт.52060332 для котлов a24, d24",
  },
  {
    value: "теплообменник гвс арт.52060333 для котлов a30, d30-35",
  },
  {
    value: "теплообменник гвс арт.52060334 для котлов a35-41, d41",
  },
  {
    value: "теплообменник гвс арт.52060281 для котлов a50",
  },
  {
    value: "теплообменник гвс арт.52060580 для котлов c19, esr-2.13-16",
  },
  {
    value: "теплообменник гвс арт.52060581 для котлов c24, esr-2.20",
  },
  {
    value: "теплообменник гвс арт.52060578 для котлов c30, esr-2.25",
  },
  {
    value: "теплообменник гвс арт.52060579 для котлов c35-41, esr-2.30-35",
  },
  {
    value: "теплообменник гвс арт.52060582 для котлов a16-21, d16-21",
  },
  {
    value: "теплообменник гвс арт.52060332 для котлов a24, d24",
  },
  {
    value: "теплообменник гвс арт.52060333 для котлов a30, d30-35",
  },
  {
    value: "теплообменник гвс арт.52060334 для котлов a35-41, d41",
  },
  {
    value: "теплообменник гвс арт.52060598 для котлов e12-17",
  },
  {
    value: "теплообменник гвс арт.52060599 для котлов e21-25",
  },
  {
    value: "теплообменник гвс арт.52060580 для котлов c19, esr-2.13-16",
  },
  {
    value: "теплообменник гвс арт.52060581 для котлов c24, esr-2.20",
  },
  {
    value: "теплообменник гвс арт.52060578 для котлов c30, esr-2.25",
  },
  {
    value: "теплообменник гвс арт.52060579 для котлов c35-41, esr-2.30-35",
  },
  {
    value: "теплообменник гвс арт.52060490 для котлов pl3.13-16",
  },
  {
    value: "теплообменник гвс арт.52060491 для котлов pl3.20",
  },
  {
    value: "теплообменник гвс арт.52060477 для котлов pl3.25",
  },
  {
    value: "теплообменник гвс арт.52060479 для котлов pl3.30-35",
  },
  {
    value: "датчик температуры гвс арт.52081300 для котлов a16-21, d16-21",
  },
  {
    value: "датчик температуры гвс арт.53130969 для котлов a24-41, d24-41, pl",
  },
  {
    value: "датчик температуры гвс арт.53130893 для котлов a50",
  },
  {
    value: "датчик температуры гвс арт.53130885 для котлов c",
  },
  {
    value: "датчик температуры гвс арт.52081300 для котлов a16-21, d16-21",
  },
  {
    value: "датчик температуры гвс арт.53130969 для котлов a24-41, d24-41, pl",
  },
  {
    value: "датчик температуры гвс арт.53131034 для котлов e",
  },
  {
    value: "датчик температуры гвс арт.53130969 для котлов a24-41, d24-41, pl",
  },
  {
    value: "датчик протока арт.52060290 для котлов a16-41, d",
  },
  {
    value: "датчик протока арт.52060229 для котлов a50, c, esr, pl",
  },
  {
    value: "датчик протока арт.52060229 для котлов a50, c, esr, pl",
  },
  {
    value: "датчик протока арт.52060290 для котлов a16-41, d",
  },
  {
    value: "датчик протока арт.52060229 для котлов a50, c, esr, pl",
  },
  {
    value: "датчик протока арт.52060229 для котлов a50, c, esr, pl",
  },
  {
    value: "блок управления арт.52081601 для котлов a16-21",
  },
  {
    value: "блок управления арт.52081602 для котлов a24-41",
  },
  {
    value: "блок управления арт.52081788 для котлов a50",
  },
  {
    value: "блок управления арт.52081936 для котлов c",
  },
  {
    value: "блок управления арт.52081979 для котлов d16-21",
  },
  {
    value: "блок управления арт.52081980 для котлов d24-41",
  },
  {
    value: "блок управления арт.52082121 для котлов e",
  },
  {
    value: "блок управления арт.52081807 для котлов esr",
  },
  {
    value: "блок управления арт.52081856 для котлов pl",
  },
  {
    value: "блок управления арт.42082071 для котлов s9",
  },
  {
    value: "электрический датчик давления воды арт.52060325 для котлов c, d",
  },
  {
    value: "электрический датчик давления воды арт.52060325 для котлов c, d",
  },
  {
    value: "трансформатор розжига арт.52080364 для котлов a, c, d, esr, pl",
  },
  {
    value: "трансформатор розжига арт.52080364 для котлов a, c, d, esr, pl",
  },
  {
    value: "трансформатор розжига арт.52080364 для котлов a, c, d, esr, pl",
  },
  {
    value: "трансформатор розжига арт.52080364 для котлов a, c, d, esr, pl",
  },
  {
    value: "трансформатор розжига арт.52080364 для котлов a, c, d, esr, pl",
  },
  {
    value: "трансформатор розжига арт.42082065 для котлов s9",
  },
  {
    value: "панель управления арт.52082122 для котлов e",
  },
  {
    value: "панель управления арт.42082070 для котлов s9",
  },
  {
    value: "блок подачи холодной воды арт.52060596 для котлов e12-17",
  },
  {
    value: "блок подачи холодной воды арт.52060597 для котлов e21-25",
  },
  {
    value: "вентилятор арт.52100317 для котлов a16-21, d16-21",
  },
  {
    value: "вентилятор арт.52100328 для котлов a24-30, d24-35",
  },
  {
    value: "вентилятор арт.52100329 для котлов a35-41, d41",
  },
  {
    value: "вентилятор арт.52100325 для котлов a50",
  },
  {
    value: "вентилятор арт.52100258 для котлов c19-30",
  },
  {
    value: "вентилятор арт.52100262 для котлов c35",
  },
  {
    value: "вентилятор арт.52100291 для котлов c41, esr-2.30-35",
  },
  {
    value: "вентилятор арт.52100317 для котлов a16-21, d16-21",
  },
  {
    value: "вентилятор арт.52100328 для котлов a24-30, d24-35",
  },
  {
    value: "вентилятор арт.52100329 для котлов a35-41, d41",
  },
  {
    value: "вентилятор арт.52100519 для котлов e",
  },
  {
    value: "вентилятор арт.52100259 для котлов esr-2.13-25",
  },
  {
    value: "вентилятор арт.52100291 для котлов c41, esr-2.30-35",
  },
  {
    value: "вентилятор арт.52100450 для котлов pl3.13-25",
  },
  {
    value: "вентилятор арт.52100453 для котлов pl3.30-35",
  },
  {
    value: "вентилятор арт.42100479 для котлов s9",
  },
  {
    value: "расширительный бак арт.52070548 для котлов a16-21, d16-21",
  },
  {
    value:
      "расширительный бак арт.52070346 для котлов a24-41, c, d24-41, esr, pl",
  },
  {
    value: "расширительный бак арт.52070470 для котлов a50",
  },
  {
    value:
      "расширительный бак арт.52070346 для котлов a24-41, c, d24-41, esr, pl",
  },
  {
    value: "расширительный бак арт.52070548 для котлов a16-21, d16-21",
  },
  {
    value:
      "расширительный бак арт.52070346 для котлов a24-41, c, d24-41, esr, pl",
  },
  {
    value: "расширительный бак арт.52070807 для котлов e",
  },
  {
    value:
      "расширительный бак арт.52070346 для котлов a24-41, c, d24-41, esr, pl",
  },
  {
    value:
      "расширительный бак арт.52070346 для котлов a24-41, c, d24-41, esr, pl",
  },
  {
    value: "насос арт.52050118 для котлов esr-2.13-20, a16-24",
  },
  {
    value: "насос арт.52050115 для котлов a30-41, esr-2.25-35",
  },
  {
    value: "насос арт.52050137 для котлов a50",
  },
  {
    value: "насос арт.52050148 для котлов c, d30-41",
  },
  {
    value: "насос арт.52050149 для котлов d16-21",
  },
  {
    value: "насос арт.52050147 для котлов d24",
  },
  {
    value: "насос арт.52050148 для котлов c, d30-41",
  },
  {
    value: "насос арт.52050242 для котлов e",
  },
  {
    value: "насос арт.52050118 для котлов esr-2.13-20, a16-24",
  },
  {
    value: "насос арт.52050115 для котлов a30-41, esr-2.25-35",
  },
  {
    value: "насос арт.52050130 для котлов pl3.13-20",
  },
  {
    value: "насос арт.52050131 для котлов pl3.25-35",
  },
  {
    value: "датчик давления воздуха арт.52100319 для котлов a16-21, d16-21",
  },
  {
    value: "датчик давления воздуха арт.52100330 для котлов a24, d24",
  },
  {
    value: "датчик давления воздуха арт.52100334 для котлов a30-41, d30-41",
  },
  {
    value: "датчик давления воздуха арт.52100280 для котлов a50",
  },
  {
    value:
      "датчик давления воздуха арт.52100261 для котлов c19-30, esr-2.13-25, pl",
  },
  {
    value:
      "датчик давления воздуха арт.52100266 для котлов c35-41, esr-2.30-35, pl3.30-35",
  },
  {
    value: "датчик давления воздуха арт.52100319 для котлов a16-21, d16-21",
  },
  {
    value: "датчик давления воздуха арт.52100330 для котлов a24, d24",
  },
  {
    value: "датчик давления воздуха арт.52100334 для котлов a30-41, d30-41",
  },
  {
    value: "датчик давления воздуха арт.52100520 для котлов e12-17",
  },
  {
    value: "датчик давления воздуха арт.52100521 для котлов e21-25",
  },
  {
    value:
      "датчик давления воздуха арт.52100261 для котлов c19-30, esr-2.13-25, pl",
  },
  {
    value:
      "датчик давления воздуха арт.52100266 для котлов c35-41, esr-2.30-35, pl3.30-35",
  },
  {
    value:
      "датчик давления воздуха арт.52100261 для котлов c19-30, esr-2.13-25, pl",
  },
  {
    value:
      "датчик давления воздуха арт.52100266 для котлов c35-41, esr-2.30-35, pl3.30-35",
  },
  {
    value: "датчик давления воздуха арт.42100480 для котлов s9",
  },
  {
    value: "датчик пламени арт.52020525 для котлов a16-41, c, d, esr, pl",
  },
  {
    value: "датчик пламени арт.52020361 для котлов a50",
  },
  {
    value: "датчик пламени арт.52020525 для котлов a16-41, c, d, esr, pl",
  },
  {
    value: "датчик пламени арт.52020525 для котлов a16-41, c, d, esr, pl",
  },
  {
    value: "датчик пламени арт.52020525 для котлов a16-41, c, d, esr, pl",
  },
  {
    value: "датчик пламени арт.52020525 для котлов a16-41, c, d, esr, pl",
  },
  {
    value: "датчик пламени арт.42020520 для котлов s9",
  },
  {
    value: "нет нужной детали в списке арт.0000000",
  },
];
