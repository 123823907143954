import React from "react";
import Page from "components/Page/Page";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import ApplicationCreate from "components/ApplicationCreate/ApplicationCreate";

const ApplicationCreatePage = () => {
  return (
    <Page>
      <Breadcrumbs path={"/"} btnText={"Все заявки"} currentPage={"Новая заявка"} />
      <ApplicationCreate />
    </Page>
  );
};

export default ApplicationCreatePage;