import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, useWatch, useFormState } from "react-hook-form";
import { useAuth } from "hooks/useAuth";
import OutsideClickHandler from "react-outside-click-handler";
import {
  selectApplicationById,
  setServiceDatesVisible,
  setAdminModal,
  setUpdatedApplicationData,
  setIsApplicationUpdated
} from "store/slice";
import { updateApplication } from "store/async";
import ApplicationHeader from "components/ApplicationHeader/ApplicationHeader";
import ApplicationHeaderAdmin from "components/ApplicationHeaderAdmin/ApplicationHeaderAdmin";
import ApplicationItem from "components/ApplicationItem/ApplicationItem";
import ApplicationItemText from "components/ApplicationItemText/ApplicationItemText";
import ApplicationItemInput from "components/ApplicationItemInput/ApplicationItemInput";
import ApplicationImageUpload from "components/ApplicationImageUpload/ApplicationImageUpload";
import ApplicationServiceInputs from "components/ApplicationServiceInputs/ApplicationServiceInputs";
import ApplicationSparesInputs from "components/ApplicationSparesInputs/ApplicationSparesInputs";
import ApplicationStatus from "components/ApplicationStatus/ApplicationStatus";
import Modal from "components/Modal/Modal";
import ModalWindow from "components/ModalWindow/ModalWindow";
import InputPrompt from "components/InputPrompt/InputPrompt";
import InputSelect from "components/InputSelect/InputSelect";
import InputMasked from "components/InputMasked/InputMasked";
import InputDate from "components/InputDate/InputDate";
import InputText from "components/InputText/InputText";
import Input from "components/Input/Input";
import { formatDate } from "utils/utils";
import { Status, StatusModal, ApplicationEditBlocks } from "utils/const";
import { Models, Periods } from "utils/data";

const ApplicationEdit = ({ number }) => {
  const { isAdmin } = useAuth();
  const dispatch = useDispatch();

  const editedApplication = useSelector((state) =>
    selectApplicationById(state, Number(number))
  );
  const updatedApplication = useSelector(
    (state) => state.updatedApplicationData
  );

  const modal = useSelector((state) => state.adminModal);
  const isMobile = useSelector((state) => state.isMobile);

  const [application, setApplication] = useState(null);
  const [statusClassname, setStatusClassname] = useState("");

  const { register, handleSubmit, control, setValue, clearErrors } = useForm();

  const { errors } = useFormState({
    control,
  });

  const statusValue = useWatch({
    control,
    name: "status",
  });

  const launchDateValue = useWatch({
    control,
    name: "launchDate",
  });

  const serviceDateValue = useWatch({
    control,
    name: "serviceDate",
  });

  useEffect(() => {
    if (updatedApplication) {
      const isStatusCommentRequired =
        updatedApplication.status === "rejection" ||
        updatedApplication.status === "incomplete";
      const isStatusChanged = updatedApplication.status !== application.status;

      if (isStatusCommentRequired && isStatusChanged && isAdmin) {
        dispatch(
          setAdminModal(StatusModal[updatedApplication.status.toUpperCase()])
        );
      } else {
        submitUpdate();
      }
    }
  }, [updatedApplication]);

  useEffect(() => {
    if (modal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [modal]);

  useEffect(() => {
    if (editedApplication.length) {
      setApplication(editedApplication[0]);
    }
  }, [editedApplication]);

  useEffect(() => {
    if (statusValue) {
      Object.entries(Status).map(([key, value]) => {
        if (value.name === statusValue) {
          setStatusClassname(`status status--${value.color}`);
        }
      });
    }
  }, [statusValue]);

  useEffect(() => {
    if (!launchDateValue && application) {
      setValue("launchDate", new Date(application.launchDate.seconds * 1000));
    }
  }, [launchDateValue, application]);

  useEffect(() => {
    if (application && !serviceDateValue) {
      const dates = Object.entries(application.serviceDate).filter(
        ([key, value]) => value
      );

      dispatch(setServiceDatesVisible(dates.length));
      dates.map(([key, value]) => {
        setValue(`serviceDate.${key}`, new Date(value.seconds * 1000));
      });
    }
  }, [serviceDateValue, application]);

  const closeModalHandler = () => {
    dispatch(setAdminModal(null));
  };

  const applicationExternalCommentKey = ['rejection', 'incomplete'].filter((status) => status === application?.status)?.[0] || 'additional';

  const submitUpdate = async (statusComment) => {
    let newData = updatedApplication;

    if (statusComment && isAdmin) {
      newData = {
        ...newData,
        daesungComments: { ...application.daesungComments, ...statusComment },
      };
      closeModalHandler();
    } else {
      newData = {
        ...newData,
        daesungComments: {  ...updatedApplication.daesungComments, },
      };
    }
    try {
      dispatch(setIsApplicationUpdated(true));
      await dispatch(updateApplication(newData)).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = (data) => {
    let status;
    if (isAdmin) {
      Object.entries(Status).map(([key, value]) => {
        if (value.name === data.status) {
          status = key;
        }
      });
    } else {
      status = "inProcess";
    }

    const nameplate =
      data.nameplate === application.nameplate
        ? data.nameplate
        : { old: application.nameplate, new: data.nameplate };
    const act =
      data.act === application.act
        ? data.act
        : { old: application.act, new: data.act };
    const guarantee =
      data.guarantee === application.guarantee
        ? data.guarantee
        : { old: application.guarantee, new: data.guarantee };
    const oldAdditionalImages = application.additionalImages.filter(
      (link) => !data.additionalImages.includes(link)
    );
    const daesungComments = {
      ...(application.daesungComments || {}),
      [applicationExternalCommentKey]: data.externalComment
    }

    dispatch(
      setUpdatedApplicationData({
        ...data,
        id: application.uid,
        number: application.number,
        status,
        nameplate,
        act,
        guarantee,
        oldAdditionalImages,
        daesungComments,
      })
    );
  };

  const renderChildren = (item, i, data) => {
    const renderSwitch = (id, parentId) => {
      switch (id) {
        case "status":
          return isAdmin ? (
            <InputSelect
              optionsData={item.input.options}
              type={item.input.type}
              name={item.input.name}
              register={register}
              params={{
                ...item.input.params,
                value: Status[application[id]].name,
              }}
              setValue={setValue}
              isStatus={true}
              statusValue={statusValue}
            />
          ) : (
            <ApplicationStatus
              color={Status[application[id]].color}
              label={Status[application[id]].name}
            />
          );
        case "date":
          return formatDate(application[id].seconds);
        case "model":
          return (
            <InputSelect
              optionsData={Models}
              type={item.input.type}
              name={item.input.name}
              register={register}
              params={{
                ...item.input.params,
                value: application[id],
              }}
              setValue={setValue}
              isError={errors[item.input.name]}
              clearErrors={clearErrors}
              emptyMessage={item.input.emptyMessage}
              isSearchable={true}
              placeholder={item.input.placeholder}
            />
          );
        case "serial":
          return (
            <InputMasked
              name={item.input.name}
              params={item.input.params}
              mask={item.input.mask}
              defaultValue={application[id]}
              control={control}
              isError={errors[item.input.name]}
              onChangeHandler={() => clearErrors()}
              formatChars={item.input.formatChars}
            />
          );
        case "guaranteePeriod":
          return (
            <>
              <InputDate
                name={item.input.name}
                params={item.input.params}
                control={control}
                isError={errors[item.input.name]}
                onChangeHandler={() => clearErrors()}
              />
              <p>+</p>
              <InputSelect
                optionsData={Periods}
                type={item.select.type}
                name={item.select.name}
                register={register}
                params={{
                  ...item.select.params,
                  value: application[id],
                }}
                setValue={setValue}
              />
            </>
          );
        case "sum":
          return item.inputs.map((input, i) => {
            return (
              <InputMasked
                key={i + 1}
                name={input.name}
                label={isMobile && input.labelMobile ? input.labelMobile : input.label}
                params={input.params}
                mask={input.mask}
                defaultValue={application[input.name]}
                control={control}
                isError={errors[input.name]}
                onChangeHandler={() => clearErrors()}
                formatChars={input.formatChars}
              />
            );
          });
        case "breakdown":
          return (
            <Input
              name={item.input.name}
              type={item.input.type}
              params={{
                ...item.input.params,
                value: application[id],
                onChange: () => clearErrors(),
              }}
              placeholder={item.input.placeholder}
              register={register}
              isError={errors[item.input.name]}
            />
          );
        case "name":
          return (
            <Input
              name={item.input.name}
              type={item.input.type}
              params={{
                ...item.input.params,
                value: application[parentId][id],
                onChange: () => clearErrors(),
              }}
              register={register}
              isError={errors[item.input.name]}
            />
          );
        case "address":
          if (item.parentId === "client") {
            return (
              <InputPrompt
                name={item.input.name}
                type={item.input.type}
                params={{
                  ...item.input.params,
                  value: application[parentId][id],
                }}
                register={register}
                setValue={setValue}
                isError={errors[item.input.name]}
                clearErrors={clearErrors}
              />
            );
          }
        case "phone":
          if (item.parentId === "client") {
            return (
              <InputMasked
                name={item.input.name}
                params={item.input.params}
                mask={item.input.mask}
                defaultValue={application[parentId][id]}
                control={control}
                isError={errors[item.input.name]}
                onChangeHandler={() => clearErrors()}
                formatChars={item.input.formatChars}
              />
            );
          }

        default:
          return parentId ? application[parentId][id] : application[id];
      }
    };

    if (item.type === "image") {
      return (
        <ApplicationItem key={i + 1} title={isMobile && item.titleMobile ? item.titleMobile : item.title}>
          <ApplicationImageUpload
            name={item.input.name}
            value={application[item.id]}
            alt={item.title}
            params={item.input.params}
            register={register}
            control={control}
            setValue={setValue}
          />
        </ApplicationItem>
      );
    } else if (item.type === "input") {
      return (
        <ApplicationItem key={i + 1} title={isMobile && item.titleMobile ? item.titleMobile : item.title}>
          <ApplicationItemInput
            modClass={`${item.modClass ? item.modClass : ""} ${
              item.id === "status" ? statusClassname : ""
            }`}
          >
            {renderSwitch(item.id, item.parentId)}
          </ApplicationItemInput>
        </ApplicationItem>
      );
    } else {
      return (
        <ApplicationItem key={i + 1} title={isMobile && item.titleMobile ? item.titleMobile : item.title}>
          <ApplicationItemText modClass={item.modClass ? item.modClass : ""}>
            {renderSwitch(item.id, item.parentId)}
          </ApplicationItemText>
        </ApplicationItem>
      );
    }
  };

  return (
    <>
      <form
        className={`application application--edit ${
          isAdmin ? "application--admin" : ""
        }`}
        onSubmit={handleSubmit(onSubmit)}
      >
        {application ? (
          <>
            {isAdmin ? (
              <ApplicationHeaderAdmin
                number={application.number}
                applicant={application.applicant}
                status={application.status}
                uid={application.uid}
                daesungComments={application.daesungComments}
                isEdit={true}
              />
            ) : (
              <ApplicationHeader
                number={application.number}
                date={application.date}
                status={application.status}
                daesungComments={application.daesungComments}
                isEdit={true}
              />
            )}
            <div className="application__grid">
              <div className="application__block application__block--additional application__block--comment">
                <ApplicationItem title="Открытый комментарий (видят все):">
                  <ApplicationItemInput modClass="application__item-input--comment">
                    <InputText
                        name="externalComment"
                        params={{
                          value: application.daesungComments?.[applicationExternalCommentKey] || "",
                          onChange: () => clearErrors(),
                        }}
                        register={register}
                        placeholder="Добавьте открытый комментарий..."
                    />
                  </ApplicationItemInput>
                </ApplicationItem>
              </div>

              <div className="application__block application__block--additional application__block--comment">
                <ApplicationItem title="Комментарий отправителя:">
                  <ApplicationItemInput modClass="application__item-input--comment">
                    <InputText
                        name="comment"
                        params={{
                          value: application.comment ? application.comment : "",
                          onChange: () => clearErrors(),
                        }}
                        register={register}
                        placeholder="Добавьте комментарий..."
                    />
                  </ApplicationItemInput>
                </ApplicationItem>
              </div>

              <div className="application__content">
                {ApplicationEditBlocks.map((block, i) => {
                  return (
                      <div
                          key={i + 1}
                          className={`application__block ${
                              block.modClass ? block.modClass : ""
                          }`}
                      >
                        {block.type === "images" ? (
                            <div className="application__images">
                              {block.items.map((item, i) => {
                                return renderChildren(item, i);
                              })}
                            </div>
                        ) : (
                            block.items.map((item, i) => {
                              if (item.id === "spares") {
                                return (
                                    <ApplicationSparesInputs
                                        key={i + 1}
                                        inputs={item.inputs}
                                        values={application.spares}
                                        control={control}
                                        register={register}
                                        setValue={setValue}
                                        clearErrors={clearErrors}
                                    />
                                );
                              } else if (item.id === "isService") {
                                return (
                                    <ApplicationServiceInputs
                                        key={i + 1}
                                        inputs={item.inputs}
                                        title={isMobile && item.titleMobile ? item.titleMobile : item.title}
                                        modClass={item.modClass}
                                        control={control}
                                        setValue={setValue}
                                        clearErrors={clearErrors}
                                    />
                                );
                              } else {
                                return renderChildren(item, i);
                              }
                            })
                        )}
                      </div>
                  );
                })}
              </div>

              <div className="application__block application__block--additional">
                <ApplicationItem title="Дополнительные материалы:">
                  <ApplicationImageUpload
                      name="additionalImages"
                      value={
                        application.additionalImages
                            ? application.additionalImages
                            : ""
                      }
                      alt="Дополнительное фото"
                      params={{}}
                      register={register}
                      control={control}
                      setValue={setValue}
                      isMultiple={true}
                  />
                </ApplicationItem>
              </div>
            </div>
          </>
        ) : (
            ""
        )}
      </form>
      {modal ? (
          <Modal>
            <OutsideClickHandler onOutsideClick={closeModalHandler}>
              <ModalWindow
                  modal={modal}
                  number={number}
                  onClose={closeModalHandler}
                  onSubmit={submitUpdate}
              />
            </OutsideClickHandler>
          </Modal>
      ) : (
          ""
      )}
    </>
  );
};

export default ApplicationEdit;
