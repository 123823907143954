import { configureStore } from "@reduxjs/toolkit";
import Reducer from "./slice";
import apiFactory from "../services/api";
import { db, storage } from "../firebase";

const api = apiFactory.getAPI();

export const store = configureStore({
  reducer: Reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: {
          api: api,
          db: db,
          storage: storage,
        },
      },
      serializableCheck: false,
    }),
});
