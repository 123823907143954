import React from "react";

const ResetSuccess = () => {
  return (
    <div className="auth__form">
      <p className="auth__reset-message">
        Мы отправили вам на почту ссылку, по которой можно установить новый
        пароль для вашего аккаунта.
      </p>
      <p className="auth__reset-message">Пожалуйста, проверьте почту.</p>
    </div>
  );
};

export default ResetSuccess;
