import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearEditingError, setEditedUser } from "store/slice";
import { updateUser, getUsers } from "store/async";
import UserForm from "components/UserForm/UserForm";
import Icon from "components/Icon/Icon";

const UserEdit = ({ user }) => {
  const dispatch = useDispatch();
  const errorCode = useSelector((state) => state.editingError);
  const [isLoading, setIsLoading] = useState(false);

  const closeModalHandler = () => {
    dispatch(setEditedUser(null));
  };

  const changeInputHandler = () => {
    if (errorCode) {
      dispatch(clearEditingError());
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      await dispatch(updateUser({ ...data, uid: user.uid })).unwrap();
      dispatch(getUsers());
      closeModalHandler();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="user user--edit">
      <button type="button" className="user__close" onClick={closeModalHandler}>
        <Icon name="close" />
      </button>
      <h3 className="user__title title">Редактирование данных АСЦ</h3>
      <UserForm
        onSubmitHandler={onSubmit}
        onChangeHandler={changeInputHandler}
        errorCode={errorCode}
        values={user}
      >
        <div className="user__buttons">
          <button
            className="button button--dark-gray"
            type="button"
            onClick={closeModalHandler}
          >
            Отмена
          </button>
          <button className="button button--black user__button" type="submit">
            <Icon name="check" />
            <span>{isLoading ? "Сохранено" : "Сохранить изменения"}</span>
          </button>
        </div>
      </UserForm>
    </div>
  );
};

export default UserEdit;
