import React from "react";

const ApplicationItemText = ({ children, modClass }) => {
  return (
    <p className={`application__item-text ${modClass ? modClass : ""}`}>
      {children}
    </p>
  );
};

export default ApplicationItemText;
