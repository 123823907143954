import React from "react";
import { useSelector } from "react-redux";
import ApplicationsTableRow from "components/ApplicationsTableRow/ApplicationsTableRow";
import ApplicationsTableCol from "components/ApplicationsTableCol/ApplicationsTableCol";
import { ApplicationsTableMeta } from "utils/const";

const ApplicationsTable = ({ applications, isAdmin }) => {
  const isMobile = useSelector((state) => state.isMobile);

  return (
    <div className={`applications__table ${isAdmin ? "applications__table--admin" : ""}`}>
      {!isMobile && <div className="applications__table-row applications__table-row--head">
        {ApplicationsTableMeta.map((col) => (
            col.isAdminOnly && !isAdmin ? "" :
          <ApplicationsTableCol key={col.id + 1} id={col.id} content={col.label} />
        ))}
      </div>}
      {applications.map((application) => (
        <ApplicationsTableRow key={application.uid} application={application} isAdmin={isAdmin} />
      ))}
    </div>
  );
};

export default ApplicationsTable;