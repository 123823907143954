import React from "react";
import { Redirect } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import PageWBack from "components/PageWBack/PageWBack";
import Auth from "components/Auth/Auth";
import Login from "components/Login/Login";
import { AppPath, AuthPage } from "utils/const";

const LoginPage = () => {
  const { isAuth } = useAuth();

  return !isAuth ? (
    <PageWBack>
      <Auth
        title={AuthPage.LOGIN.title}
        subtitle={AuthPage.LOGIN.subtitle}
      >
        <Login />
      </Auth>
    </PageWBack>
  ) : (
    <Redirect to={AppPath.ROOT} replace />
  );
};

export default LoginPage;
