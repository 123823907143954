import React from "react";
import { Range, getTrackBackground } from "react-range";
import { Controller } from "react-hook-form";

const InputRange = ({ range, control, min, max, isMinError, isMaxError }) => {
  return (
    <Controller
      control={control}
      name={range.name}
      id={range.name}
      render={({ field: { onChange, value } }) => (
        <Range
          values={
            !value
              ? [min, max]
              : isMinError
              ? [min, value[1]]
              : isMaxError
              ? [value[0], max]
              : value
          }
          step={range.step}
          min={min}
          max={max}
          onChange={onChange}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
            >
              <div
                ref={props.ref}
                style={{
                  height: range.trackStyles.height,
                  width: range.trackStyles.width,
                  background: getTrackBackground({
                    values: !value
                      ? [min, max]
                      : isMinError
                      ? [min, value[1]]
                      : isMaxError
                      ? [value[0], max]
                      : value,
                    colors: range.trackStyles.colors,
                    min: min,
                    max: max,
                  }),
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              style={{
                ...props.style,
                ...range.thumbStyles,
              }}
            ></div>
          )}
        />
      )}
    />
  );
};

export default InputRange;
