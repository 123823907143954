import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { addUser, getUsers } from "store/async";
import { clearRegisterError } from "store/slice";
import UserForm from "components/UserForm/UserForm";
import UserCreateSuccess from "components/UserCreateSuccess/UserCreateSuccess";
import Icon from "components/Icon/Icon";

const UserCreate = () => {
  const dispatch = useDispatch();
  const errorCode = useSelector((state) => state.registerError);
  const [isLoading, setIsLoading] = useState(false);
  const [registeredUser, setRegisteredUser] = useState("");

  const changeInputHandler = () => {
    if (errorCode) {
      dispatch(clearRegisterError());
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      await dispatch(addUser(data)).unwrap();
      setRegisteredUser(data.email);
      dispatch(getUsers());
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="user">
      <h3 className="user__title title">
        {!registeredUser
          ? "Создание нового пользователя"
          : "Новый пользователь создан!"}
      </h3>
      {!registeredUser ? (
        <UserForm
          onSubmitHandler={onSubmit}
          onChangeHandler={changeInputHandler}
          errorCode={errorCode}
        >
          <div className="user__buttons">
            <Link to="." className="button button--dark-gray">
              Отмена
            </Link>
            <button
              className="button button--black user__button"
              type="submit"
            >
              <Icon name="plus" />
              <span>{isLoading ? "Подождите..." : "Создать"}</span>
            </button>
          </div>
        </UserForm>
      ) : (
        <UserCreateSuccess email={registeredUser} />
      )}
    </div>
  );
};

export default UserCreate;
