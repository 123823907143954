import React from "react";

const InputToggle = ({ name, label, params, buttons, register, onChange }) => {
  return (
    <div className="form__toggle">
      <span className="form__label">
        {label}
        {params.required ? <span>*</span> : <></>}
      </span>
      <div className="form__toggle-buttons">
        {buttons.map((item) => (
          <div key={item.value} className="form__toggle-button">
            <input
              {...register(name, { ...params, onChange: onChange })}
              type="radio"
              value={item.value}
              id={item.value}
            />
            <label className="form__toggle-label" htmlFor={item.value}>
              {item.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InputToggle;
