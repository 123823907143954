import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectRegions } from "store/slice";
import InputSelect from "components/InputSelect/InputSelect";

const FiltersRegion = ({ selector, register, setValue, setFocus }) => {
  const regions = useSelector((state) => selectRegions(state));

  useEffect(() => {
    setFocus(selector.name);
  }, [setFocus]);

  return (
    <div className="filters__region-filter">
      <InputSelect
        optionsData={regions}
        type={selector.type}
        name={selector.name}
        register={register}
        setValue={setValue}
        emptyMessage={selector.emptyMessage}
        isSearchable={true}
        placeholder={selector.placeholder}
      />
    </div>
  );
};

export default FiltersRegion;
