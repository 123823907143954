import React, { useEffect } from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAuth } from "hooks/useAuth";
import { useMediaQuery } from "react-responsive";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import RouteProtected from "components/RouteProtected/RouteProtected";
import RouteAdmin from "components/RouteAdmin/RouteAdmin";
import RouteUser from "components/RouteUser/RouteUser";
import MainPage from "modules/MainPage/MainPage";
import LoginPage from "modules/LoginPage/LoginPage";
import PasswordPage from "modules/PasswordPage/PasswordPage";
import ResetPage from "modules/ResetPage/ResetPage";
import UsersPage from "modules/UsersPage/UsersPage";
import UsersCreatePage from "modules/UsersCreatePage/UsersCreatePage";
import ApplicationPage from "modules/ApplicationPage/ApplicationPage";
import ApplicationCreatePage from "modules/ApplicationCreatePage/ApplicationCreatePage";
import { getApplications, getApplicationsByUserId } from "store/async";
import { setIsMobile } from "store//slice";
import { AppPath, MOBILE_VIEW } from "utils/const";

const { ROOT, LOGIN, PASSWORD, RESET, USERS, APPLICATION, APPLICATIONS } =
  AppPath;

const App = () => {
  const dispatch = useDispatch();
  const { uid, isAdmin, isAuth } = useAuth();
  const isMobile = useMediaQuery({ maxWidth: MOBILE_VIEW });

  useEffect(() => {
    if (isAuth) {
      const unsubscribe = onSnapshot(collection(db, "applications"), () => {
        console.log("Snapshot");
        if (isAdmin) {
          dispatch(getApplications());
        } else {
          dispatch(getApplicationsByUserId(uid));
        }
      });
      return () => unsubscribe();
    }
  });

  useEffect(() => {
    dispatch(setIsMobile(isMobile));
  }, [isMobile]);

  return (
    <Router>
      <Switch>
        <RouteProtected exact path={ROOT} component={MainPage} />
        <Route exact path={LOGIN} component={LoginPage} />
        <Route exact path={PASSWORD} component={PasswordPage} />
        <Route exact path={RESET} component={ResetPage} />
        <RouteProtected
          exact
          path={`${APPLICATIONS}/:id`}
          component={ApplicationPage}
        />
        <RouteAdmin exact path={USERS} component={UsersPage} />
        <RouteAdmin exact path={`${USERS}/add`} component={UsersCreatePage} />
        <RouteUser
          exact
          path={`${APPLICATION}/add`}
          component={ApplicationCreatePage}
        />
      </Switch>
    </Router>
  );
};

export default App;
