import React, { useState } from "react";
import Icon from "components/Icon/Icon";

const UserCreateSuccess = ({ email }) => {
  const [copyMessage, setCopyMessage] = useState("Копировать приглашение");

  const inviteText = `Здравствуйте! Вы зарегистрированы в системе Daesung: Гарантийный ремонт. С помощью системы вы можете отправлять заявки на компенсацию гарантийного ремонта оборудования Daesung и отслеживать статус уже отправленных заявок. Ваш аккаунт зарегистрирован на email ${email}. Чтобы войти в сервис проверьте электронную почту и пройдите по ссылке из письма.`;

  const onCopy = () => {
    navigator.clipboard.writeText(inviteText);
    setCopyMessage("Скопировано");
    setTimeout(() => {
      setCopyMessage("Копировать приглашение");
    }, 2000);
  };

  return (
    <div className="user__success">
      <p className="user__success-message">
        Ссылка и инструкция для входа и установки пароля будут отправлены
        пользователю по электронной почте.
      </p>
      <p className="user__success-message">
        Также вы можете скопировать приглашение и отправить его самостоятельно
        удобным способом.
      </p>
      <div className="user__buttons">
        <button
          className="button button--black user__button"
          type="button"
          onClick={onCopy}
        >
          <Icon name="copy" />
          <span>{copyMessage}</span>
        </button>
      </div>
    </div>
  );
};

export default UserCreateSuccess;
