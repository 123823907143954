import React from "react";
import { useLocation, Link } from "react-router-dom";
import { AppPath } from "utils/const";

const Logo = ({ title, isHeader }) => {
  const location = useLocation();
  const isMainPage = location.pathname === AppPath.ROOT;

  const renderLogo = () => {
    return (
      <>
        <img
          className="logo__img"
          src="/assets/logo.svg"
          alt="Логотип Daesung"
        />
        {title && <h3 className="logo__text title">{title}</h3>}
      </>
    );
  };
  return (
    <>
      {isHeader && !isMainPage ? (
        <Link to={AppPath.ROOT} className="logo">
          {renderLogo()}
        </Link>
      ) : (
        <div className="logo">{renderLogo()}</div>
      )}
    </>
  );
};

export default Logo;
