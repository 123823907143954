import React from "react";
import { useSelector } from "react-redux";
import DropdownMenuLink from "components/DropdownMenuLink/DropdownMenuLink";
import Icon from "components/Icon/Icon";

const DropdownMenu = ({ links, isCloseButton, onClose }) => {
  const isMobile = useSelector((state) => state.isMobile);

  return (
    <div className="menu">
      {isMobile && isCloseButton && (
        <button
          type="button"
          className="menu__close-btn"
          onClick={onClose}
        >
          <Icon name="delete" />
        </button>
      )}
      {links.map((link, i) => (
        <DropdownMenuLink
          key={i + 1}
          label={link.label}
          path={link.path || null}
          href={link.href || null}
          type={link.type}
          handler={link.handler}
          modClass={link.modClass || null}
        />
      ))}
    </div>
  );
};

export default DropdownMenu;
