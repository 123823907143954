import React from "react";
import Footer from "components/Footer/Footer";

const PageWBack = ({ children }) => {
  return (
    <>
      <main className="page page--with-back">{children}
      </main>
      <Footer />
    </>
  );
};

export default PageWBack;
