import React from "react";
import InputCheckbox from "components/InputCheckbox/InputCheckbox";

const FiltersStatus = ({ inputs, register }) => {
  return inputs.map((input) => {
    return (
      <InputCheckbox
        key={input.value}
        input={input}
        register={register}
        isStatusCheckbox={true}
      />
    );
  });
};

export default FiltersStatus;
