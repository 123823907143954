import React from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({ onPageChange, pageCount }) => {

  return (
    <ReactPaginate
      breakLabel=". . ."
      onPageChange={onPageChange}
      pageRangeDisplayed={3}
      marginPagesDisplayed={2}
      pageCount={pageCount}
      renderOnZeroPageCount={null}
      containerClassName="pagination"
      previousClassName="pagination__previous"
      nextClassName="pagination__next"
      pageClassName="pagination__item"
      breakClassName="pagination__item"
      activeClassName="pagination__item--active"
    />
  );
};

export default Pagination;
