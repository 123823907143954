import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEditedApplication } from "store/slice";
import Icon from "components/Icon/Icon";

const ApplicationComment = ({
  title,
  message,
  additionalComment,
  isAdmin,
  internalComment,
  externalComment,
  isEditAvailable,
  number,
}) => {
  const dispatch = useDispatch();
  const isMobile = useSelector((state) => state.isMobile);

  const commentSubtile = internalComment
    ? isMobile
      ? "Внутренний комментарий:"
      : "Внутренний комментарий (видят только менеджеры Daesung):"
    : externalComment
    ? "Открытый комментарий (видят все):"
    : additionalComment
    ? "Комментарий менеджера:"
    : "";
  const comment = internalComment
    ? internalComment
    : externalComment
    ? externalComment
    : additionalComment
    ? additionalComment
    : "";

  const editButtonClickHandler = () => {
    dispatch(setEditedApplication(number));
  };

  const renderComment = (subtitle, text) => {
    return (
      <div className="application__comment-wrapper">
        <p className="application__comment-subtitle">
          {internalComment ? <Icon name="comment" /> : ""}
          <span>{subtitle}</span>
        </p>
        <p className="application__comment-text">{text}</p>
      </div>
    );
  };

  return (
    <div className="application__comment">
      {isAdmin ? (
        renderComment(commentSubtile, comment)
      ) : (
        <div>
          {title && (
            <h3 className="application__comment-title title">{title}</h3>
          )}
          <p
            className="application__comment-text"
            dangerouslySetInnerHTML={{ __html: message }}
          ></p>
          {comment ? renderComment(commentSubtile, comment) : ""}
        </div>
      )}
      {!isAdmin && isEditAvailable ? (
        <button
          className="application__button-edit button button--black"
          type="button"
          onClick={editButtonClickHandler}
        >
          <Icon name="edit" />
          {isMobile ? <span>Редактировать заявку</span> : <span>Отредактировать заявку</span>}
        </button>
      ) : (
        ""
      )}
    </div>
  );
};

export default ApplicationComment;
