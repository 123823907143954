import React from "react";
import PropTypes from "prop-types";

const Icon = ({ name }) => {
  return (
    <svg className={`icon icon--${name}`} width="16" height="16">
      <use xlinkHref={`/assets/sprite.svg#icon-${name}`} />
    </svg>
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Icon;
