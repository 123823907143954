import React from "react";
import { useFormState } from "react-hook-form";
import ApplicationFormFieldset from "components/ApplicationFormFieldset/ApplicationFormFieldset";
import InputMasked from "components/InputMasked/InputMasked";
import InputPrompt from "components/InputPrompt/InputPrompt";
import Input from "components/Input/Input";

const ApplicationFormStep1 = ({
  number,
  title,
  description,
  fields,
  register,
  control,
  setValue,
  clearErrors,
  trigger,
}) => {
  const { errors } = useFormState({
    control,
  });

  const stepFields = fields.map((field) => field.name);

  return (
    <div className="application__step application__step--one">
      <ApplicationFormFieldset
        number={number}
        title={title}
        description={description}
        trigger={trigger}
        stepFields={stepFields}
      >
        <div className="application__fields">
          {fields.map((field, i) =>
            field.type === "mask" ? (
              <InputMasked
                key={i + 1}
                fieldClassName={"application__field"}
                name={field.name}
                label={field.label}
                params={field.params}
                mask={field.mask}
                formatChars={field.formatChars}
                defaultValue={""}
                control={control}
                isError={errors[field.name]}
                onChangeHandler={() => clearErrors()}
              />
            ) : field.name === "address" ? (
              <div key={i + 1} className="application__field">
                <InputPrompt
                  name={field.name}
                  type={field.type}
                  label={field.label}
                  params={field.params}
                  register={register}
                  setValue={setValue}
                  isError={errors[field.name]}
                  clearErrors={clearErrors}
                />
              </div>
            ) : (
              <div key={i + 1} className="application__field">
                <Input
                  name={field.name}
                  type={field.type}
                  label={field.label}
                  params={{ ...field.params, onChange: () => clearErrors() }}
                  register={register}
                  isError={errors[field.name]}
                />
              </div>
            )
          )}
        </div>
      </ApplicationFormFieldset>
    </div>
  );
};

export default ApplicationFormStep1;
