import React from "react";
import { useDispatch } from "react-redux";
import { setEditedUser } from "store/slice";
import UsersTableCol from "components/UsersTableCol/UsersTableCol";
import Icon from "components/Icon/Icon";
import { formatPhoneNumber } from "utils/utils";
import { UsersTableMeta } from "utils/const";

const UsersTableRow = ({ user }) => {
  const dispatch = useDispatch();

  const clickEditHandler = () => {
    dispatch(setEditedUser(user));
  };

  return (
    <div className="users__table-row">
      {UsersTableMeta.map((col) => (
        <UsersTableCol
          key={col.id}
          id={col.id}
          text={
            col.id === "phone" ? formatPhoneNumber(user[col.id]) : user[col.id]
          }
        />
      ))}
      <div className="users__table-col users__table-col--edit">
        <button type="button" className="button" onClick={clickEditHandler}>
          <span>Редактировать</span>
          <Icon name="edit" />
        </button>
      </div>
    </div>
  );
};

export default UsersTableRow;
