import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Icon from "components/Icon/Icon";

const Breadcrumbs = ({ btnText, path, currentPage }) => {
  const isMobile = useSelector((state) => state.isMobile);

  return (
    <div className="breadcrumbs">
      <Link to={path} className="breadcrumbs__button">
        {!isMobile && <Icon name="breadcrumb-back" />}
        <span>{btnText}</span>
        {isMobile && <Icon name="breadcrumb-forward" />}
      </Link>
      {isMobile && <span>{currentPage}</span>}
    </div>
  );
};

export default Breadcrumbs;
