import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Page from "components/Page/Page";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import UserCreate from "components/UserCreate/UserCreate";
import { AppPath } from "utils/const";

const UsersCreatePage = () => {
  const history = useHistory();
  const isMobile = useSelector((state) => state.isMobile);

  useEffect(() => {
    if (isMobile) {
      history.push(AppPath.ROOT);
    }
  }, [isMobile]);

  return (
    <Page>
      <Breadcrumbs path={"."} btnText={"К списку пользователей"} />
      <UserCreate />
    </Page>
  );
};

export default UsersCreatePage;
