import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAuth,
  signInWithEmailAndPassword,
  confirmPasswordReset,
  sendPasswordResetEmail,
  signOut
} from "firebase/auth";
import {
  addDoc,
  setDoc,
  updateDoc,
  getDocs,
  getDoc,
  doc,
  collection,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

export const addApplication = createAsyncThunk(
  "daesung/addApplication",
  async (data, { extra }) => {
    const { db, storage } = extra;

    const {
      applicant,
      name,
      address,
      phone,
      model,
      serial,
      nameplate,
      launchDate,
      guaranteePeriod,
      isService,
      guarantee,
      serviceDate = {},
      breakdown,
      visitCost,
      worksCost,
      act,
      isSpares,
      spares = [],
      comment = "",
      additionalImages = [],
    } = data;

    try {
      const snapshot = await getDocs(collection(db, "applications"));
      const applicationNumber = snapshot.size + 1;
      const applicationDate = new Date();

      let nameplateURL = "";
      if (nameplate.length) {
        const nameplateRef = ref(
          storage,
          `${applicationNumber}/${nameplate[0].name}`
        );
        const nameplateSnap = await uploadBytes(nameplateRef, nameplate[0]);
        nameplateURL = await getDownloadURL(nameplateSnap.ref);
      }

      const guaranteeRef = ref(
        storage,
        `${applicationNumber}/${guarantee[0].name}`
      );
      const guaranteeSnap = await uploadBytes(guaranteeRef, guarantee[0]);
      const guaranteeURL = await getDownloadURL(guaranteeSnap.ref);
      const actRef = ref(storage, `${applicationNumber}/${act[0].name}`);
      const actSnap = await uploadBytes(actRef, act[0]);
      const actURL = await getDownloadURL(actSnap.ref);

      let validSpares = [];

      if (spares.length) {
        validSpares = spares.filter((spare) => spare.title && spare.cost);
      }

      let additionalImagesURLs = [];
      const getAdditionalImagesURLs = async (images) => {
        for (const image of images) {
          const imgRef = ref(storage, `${applicationNumber}/${image.name}`);
          const imgSnap = await uploadBytes(imgRef, image);
          const imgURL = await getDownloadURL(imgSnap.ref);
          additionalImagesURLs.push(imgURL);
        }
      };

      if (additionalImages.length) {
        await getAdditionalImagesURLs(additionalImages);
      }

      const newApplication = {
        number: applicationNumber,
        date: applicationDate,
        status: "inProcess",
        applicant: applicant,
        client: {
          name: name,
          address: address,
          phone: phone,
        },
        model: model,
        serial: serial,
        launchDate: launchDate,
        guaranteePeriod: guaranteePeriod,
        isService: isService,
        serviceDate: serviceDate,
        nameplate: nameplateURL,
        guarantee: guaranteeURL,
        act: actURL,
        breakdown: breakdown,
        visitCost: visitCost,
        worksCost: worksCost,
        isSpares: isSpares,
        spares: validSpares,
        comment: comment,
        additionalImages: additionalImagesURLs,
      };

      await addDoc(collection(db, "applications"), newApplication);

      return applicationNumber;
    } catch (error) {
      console.error(error);
    }
  }
);

export const updateApplication = createAsyncThunk(
  "daesung/updateApplication",
  async (data, { extra }) => {
    const { db, storage } = extra;

    const {
      id,
      number,
      status,
      name,
      address,
      phone,
      model,
      serial,
      nameplate,
      launchDate,
      guaranteePeriod,
      guarantee,
      serviceDate = {},
      breakdown,
      visitCost,
      worksCost,
      act,
      spares = [],
      comment = "",
      daesungComments,
      additionalImages,
      oldAdditionalImages,
    } = data;

    try {

      let nameplateURL = "";
      if (nameplate?.new) {
        const nameplateRef = ref(storage, `${number}/${nameplate.new[0].name}`);
        const nameplateSnap = await uploadBytes(nameplateRef, nameplate.new[0]);
        nameplateURL = await getDownloadURL(nameplateSnap.ref);
        if (nameplate.old) {
          const nameplateOldRef = ref(storage, nameplate.old);
          await deleteObject(nameplateOldRef);
        }
      }

      let guaranteeURL = guarantee;
      if (guarantee.new) {
        const guaranteeRef = ref(storage, `${number}/${guarantee.new[0].name}`);
        const guaranteeSnap = await uploadBytes(guaranteeRef, guarantee.new[0]);
        guaranteeURL = await getDownloadURL(guaranteeSnap.ref);

        const guaranteeOldRef = ref(storage, guarantee.old);
        await deleteObject(guaranteeOldRef);
      }

      let actURL = act;
      if (act.new) {
        const actRef = ref(storage, `${number}/${act.new[0].name}`);
        const actSnap = await uploadBytes(actRef, act.new[0]);
        actURL = await getDownloadURL(actSnap.ref);

        const actOldRef = ref(storage, act.old);
        await deleteObject(actOldRef);
      }

      let validServiceDate = {...serviceDate};
      Object.entries(serviceDate).map(([key, value]) => {
        if (!value && key === "first") {
          validServiceDate = {};
          return;
        }

        if (value && key === "third" && !validServiceDate.second) {
          delete validServiceDate["third"];
        }

        if (!value) {
          delete validServiceDate[key];
        }
      })

      const isSpares = spares.length ? "yes" : "no";
      const isService = Object.values(validServiceDate).length ? "yes" : "no";
      let validSpares = [];

      if (spares.length) {
        validSpares = spares.filter((spare) => spare.title && spare.cost);
      }

      const deleteOldAdditionalImages = async (images) => {
        for (const image of images) {
          const imgRef = ref(storage, image);
          await deleteObject(imgRef);
        }
      };

      let additionalImagesURLs = [];
      const getAdditionalImagesURLs = async (images) => {
        for (const image of images) {
          if (image instanceof File) {
            const imgRef = ref(storage, `${number}/${image.name}`);
            const imgSnap = await uploadBytes(imgRef, image);
            const imgURL = await getDownloadURL(imgSnap.ref);
            additionalImagesURLs.push(imgURL);
          } else {
            additionalImagesURLs.push(image);
          }
        }
      };

      if (oldAdditionalImages.length) {
        deleteOldAdditionalImages(oldAdditionalImages);
      }

      if (additionalImages.length) {
        await getAdditionalImagesURLs(additionalImages);
      }

      const updatedData = {
        status: status,
        client: {
          name: name,
          address: address,
          phone: phone,
        },
        model: model,
        serial: serial,
        launchDate: launchDate,
        guaranteePeriod: guaranteePeriod,
        isService: isService,
        serviceDate: validServiceDate,
        nameplate: nameplateURL,
        guarantee: guaranteeURL,
        act: actURL,
        breakdown: breakdown,
        visitCost: visitCost,
        worksCost: worksCost,
        isSpares: isSpares,
        spares: validSpares,
        comment: comment,
        daesungComments: daesungComments,
        additionalImages: additionalImagesURLs,
      };

      const applicationDocRef = doc(db, "applications", id);
      await updateDoc(applicationDocRef, updatedData);
    } catch (error) {
      console.error(error);
    }
  }
);

export const getApplications = createAsyncThunk(
  "daesung/getApplications",
  async (data, { extra }) => {
    const { db } = extra;
    try {
      let applications = [];
      const q = query(
        collection(db, "applications"),
        orderBy("number", "desc")
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        if (doc.data().status !== "deleted") {
          applications.push({ uid: doc.id, ...doc.data() });
        }
      });

      return applications;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
);

export const getApplicationsByUserId = createAsyncThunk(
  "daesung/getApplicationsByUserId",
  async (id, { extra }) => {
    const { db } = extra;
    try {
      let applications = [];
      const q = query(
        collection(db, "applications"),
        where("applicant.uid", "==", id),
        orderBy("number", "desc")
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        if (doc.data().status !== "deleted") {
          applications.push({ uid: doc.id, ...doc.data() });
        }
      });

      return applications;
    } catch (error) {
      console.error(error);
    }
  }
);

export const setApplicationStatus = createAsyncThunk(
  "daesung/setApplicationStatus",
  async (data, { extra, rejectWithValue }) => {
    const { db } = extra;
    const { id, status, comment } = data;
    let updatedData = { status: status };
    if (comment) {
      const [[key, value]] = Object.entries(comment);
      const commentsNestedObj = `daesungComments.${key}`;
      updatedData = { ...updatedData, [commentsNestedObj]: value };
    }

    try {
      const applicationDocRef = doc(db, "applications", id);
      await updateDoc(applicationDocRef, updatedData);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.response.data.error.code);
    }
  }
);

export const addApplicationInternalComment = createAsyncThunk(
  "daesung/addApplicationInternalComment",
  async (data, { extra, rejectWithValue }) => {
    const { db } = extra;
    const { id, comment } = data;

    const [[key, value]] = Object.entries(comment);
    const commentsNestedObj = `daesungComments.${key}`;
    const updatedData = { [commentsNestedObj]: value };

    try {
      const applicationDocRef = doc(db, "applications", id);
      await updateDoc(applicationDocRef, updatedData);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.response.data.error.code);
    }
  }
);

export const deleteApplication = createAsyncThunk(
  "daesung/deleteApplication",
  async (data, { extra, rejectWithValue }) => {
    const { db } = extra;
    const { id } = data;

    try {
      const applicationDocRef = doc(db, "applications", id);
      await updateDoc(applicationDocRef, { status: "deleted" });
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.response.data.error.code);
    }
  }
);

export const getUsers = createAsyncThunk(
  "daesung/getUsers",
  async (data, { extra }) => {
    const { api, db } = extra;
    try {
      const users = await api.getUsers();
      const usersAddInfo = await getDocs(collection(db, "users")).catch(e => console.log(e));
      const usersWithAddInfo = {};
      usersAddInfo?.forEach((doc) => {
        usersWithAddInfo[doc.id] = doc.data();
      });

      const finalUsers = users.reduce((acc, user) => {
        if (!user.customClaims) {
          acc.push({
            uid: user.uid,
            email: user.email,
            phone: user.phoneNumber,
            company: user.displayName,
            address: usersWithAddInfo[user.uid] && usersWithAddInfo[user.uid].address,
            region: usersWithAddInfo[user.uid] && usersWithAddInfo[user.uid].region,
            contact: usersWithAddInfo[user.uid] && usersWithAddInfo[user.uid].contact,
            inn: usersWithAddInfo[user.uid] && usersWithAddInfo[user.uid].inn,
            ogrn: usersWithAddInfo[user.uid] && usersWithAddInfo[user.uid].ogrn,
          });
        }
        return acc;
      }, []);
      return finalUsers;
    } catch (error) {
      console.error(error);
    }
  }
);

export const addUser = createAsyncThunk(
  "daesung/addUser",
  async (data, { extra, rejectWithValue }) => {
    const { api, db } = extra;
    const auth = getAuth();
    try {
      const { address, region, company, contact, email, inn, ogrn, phone } =
        data;
      const mainData = {
        email: email,
        phone: phone,
        company: company,
      };

      const additionalData = {
        address: address,
        region: region,
        contact: contact,
        inn: inn,
        ogrn: ogrn,
      };

      const newUser = await api.createUser(mainData);
      await setDoc(doc(db, "users", newUser.uid), additionalData);

      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.response.data.error.code);
    }
  }
);

export const updateUser = createAsyncThunk(
  "daesung/updateUser",
  async (data, { extra, rejectWithValue }) => {
    const { api, db } = extra;
    try {
      const {
        uid,
        address,
        region,
        company,
        contact,
        email,
        inn,
        ogrn,
        phone,
      } = data;
      const mainData = {
        uid: uid,
        email: email,
        phone: phone,
        company: company,
      };

      const additionalData = {
        address: address,
        region: region,
        contact: contact,
        inn: inn,
        ogrn: ogrn,
      };

      await api.updateUser(mainData);
      await updateDoc(doc(db, "users", uid), additionalData);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.response.data.error.code);
    }
  }
);

export const loginUser = createAsyncThunk(
  "daesung/loginUser",
  async (data, { extra, rejectWithValue }) => {
    try {
      const { db } = extra;
      const { email, password } = data;
      const auth = getAuth();

      await signInWithEmailAndPassword(auth, email, password);
      const userData = await auth.currentUser.getIdTokenResult();
      const additionalData = await getDoc(
        doc(db, "users", userData.claims.user_id)
      );
      const userAdditional = additionalData.data();
      return { ...userData.claims, ...userAdditional };
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.code);
    }
  }
);

export const logoutUser = createAsyncThunk(
  "daesung/logoutUser",
  async (data, { rejectWithValue }) => {
    const auth = getAuth();

    try {
      await signOut(auth);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.code);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "daesung/resetPassword",
  async (data, { rejectWithValue }) => {
    const { email } = data;
    const auth = getAuth();

    try {
      return await sendPasswordResetEmail(auth, email);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.code);
    }
  }
);

export const setPassword = createAsyncThunk(
  "daesung/setPassword",
  async (data) => {
    const { oobCode, newPassword } = data;
    const auth = getAuth();

    try {
      return await confirmPasswordReset(auth, oobCode, newPassword);
    } catch (error) {
      console.error(error);
    }
  }
);
