import React from "react";
import { useSelector } from "react-redux";

const ApplicationsTableCol = ({ id, label, content }) => {
  const isMobile = useSelector((state) => state.isMobile);

  return (
    <div className={`applications__table-col applications__table-col--${id}`}>
      {isMobile && <span className="applications__table-label">{`${label}:`}</span>}
      {content}
    </div>
  );
};

export default ApplicationsTableCol;
