import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSparesVisible } from "store/slice";
import ApplicationItem from "components/ApplicationItem/ApplicationItem";
import ApplicationItemInput from "components/ApplicationItemInput/ApplicationItemInput";
import InputSelect from "components/InputSelect/InputSelect";
import InputMasked from "components/InputMasked/InputMasked";
import Icon from "components/Icon/Icon";
import { Spares } from "utils/data";

const ApplicationSparesInputs = ({
  inputs,
  values,
  control,
  register,
  setValue,
  clearErrors,
}) => {
  const dispatch = useDispatch();
  const sparesVisible = useSelector((state) => state.sparesVisible);
  const isMobile = useSelector((state) => state.isMobile);

  useEffect(() => {
    dispatch(setSparesVisible(values.length));
  }, [values]);

  const addButtonClickHandler = () => {
    const newCount = sparesVisible + 1;
    dispatch(setSparesVisible(newCount));
  };

  const deleteButtonClickHandler = () => {
    const newCount = sparesVisible - 1;
    dispatch(setSparesVisible(newCount));
    inputs.map((input, i) => {
      if (i === newCount) {
        setValue(`${input.name}.${i}.${input.title.fieldName}`, "");
        setValue(`${input.name}.${i}.${input.cost.fieldName}`, "");
      }
    });
  };

  return (
    <div className="application__spares-wrapper">
      {sparesVisible
        ? inputs.map((input, i) => {
            return i + 1 <= sparesVisible ? (
              <div key={i + 1} className="application__spares-item">
                <ApplicationItem
                  title={
                    isMobile && input.title.labelMobile
                      ? input.title.labelMobile
                      : input.title.label
                  }
                >
                  <ApplicationItemInput modClass={input.title.modClass}>
                    <InputSelect
                      optionsData={Spares}
                      name={`${input.name}.${i}.${input.title.fieldName}`}
                      type={input.title.type}
                      register={register}
                      params={{
                        ...input.title.params,
                        value: values[i]?.title,
                      }}
                      setValue={setValue}
                      clearErrors={clearErrors}
                      emptyMessage={input.title.emptyMessage}
                      isSearchable={true}
                      placeholder={input.title.placeholder}
                    />
                  </ApplicationItemInput>
                </ApplicationItem>
                <ApplicationItem title={
                    isMobile && input.cost.labelMobile
                      ? input.cost.labelMobile
                      : input.cost.label
                  }>
                  <ApplicationItemInput modClass={input.cost.modClass}>
                    <InputMasked
                      name={`${input.name}.${i}.${input.cost.fieldName}`}
                      params={input.cost.params}
                      mask={input.cost.mask}
                      formatChars={input.cost.formatChars}
                      placeholder={input.cost.placeholder}
                      defaultValue={values[i]?.cost}
                      control={control}
                      onChangeHandler={() => clearErrors()}
                    />
                  </ApplicationItemInput>
                  {i + 1 === sparesVisible && (
                    <button
                      type="button"
                      className="application__button-delete"
                      onClick={deleteButtonClickHandler}
                    >
                      <Icon name={isMobile ? "minus" : "delete"} />
                    </button>
                  )}
                </ApplicationItem>
              </div>
            ) : (
              ""
            );
          })
        : ""}
      {sparesVisible < 6 ? (
        <button
          type="button"
          className="application__button-add"
          onClick={addButtonClickHandler}
        >
          <span className="button button--round button--medium-gray">
            <Icon name="add" />
          </span>
          {isMobile ? <span>Добавить ещё одну запасную часть</span> : <span>Добавить запасную часть</span>}
        </button>
      ) : (
        ""
      )}
    </div>
  );
};

export default ApplicationSparesInputs;
