import React, { useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from "react-router-dom";
import Page from "components/Page/Page";
import Users from "components/Users/Users";
import Modal from "components/Modal/Modal";
import UserEdit from "components/UserEdit/UserEdit";
import { AppPath } from "utils/const";
import {setQuery} from "../../store/slice";

const UsersPage = () => {
  const history = useHistory();
  const editedUser = useSelector((state) => state.editedUser);
  const isMobile = useSelector((state) => state.isMobile);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isMobile) {
      history.push(AppPath.ROOT);
    }

    return () => dispatch(setQuery(''));
  }, [isMobile]);

  return (
    <Page>
      <Users />
      {editedUser && (
        <Modal>
          <UserEdit user={editedUser} />
        </Modal>
      )}
    </Page>
  );
};

export default UsersPage;
