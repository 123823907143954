import { useSelector } from "react-redux";

export const useAuth = () => {
  const { uid, email, name, isAdmin, phone, address, region, inn, ogrn } = useSelector(
    (state) => state.authorizedUser
  );

  return {
    isAuth: !!email,
    uid,
    email,
    name,
    isAdmin,
    phone,
    address,
    region,
    inn,
    ogrn,
  };
};
