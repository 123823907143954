import React from "react";
import InputCheckbox from "components/InputCheckbox/InputCheckbox";
import { Models } from "utils/data";

const FiltersModel = ({ register }) => {
  return (
    <div className="filters__model-filter">
      {Models.map((model, i) => {
        return (
          <InputCheckbox
            key={i + 1}
            input={{
              name: "model",
              type: "checkbox",
              value: model.value,
              label: model.value,
            }}
            register={register}
          />
        );
      })}
    </div>
  );
};

export default FiltersModel;
