import React from "react";
import InputDate from "components/InputDate/InputDate";

const FiltersDate = ({ inputs, control }) => {
  return (
    <div className="filters__date-filter">
      {inputs.map((input, i) => {
        return (
          <React.Fragment key={i + 1}>
            <p>{input.name === "minDate" ? "c" : "по"}</p>
            <InputDate name={input.name} control={control} />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default FiltersDate;
