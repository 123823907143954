import React from "react";
import { Controller } from "react-hook-form";
import InputMask from "react-input-mask";

const InputMasked = ({
  fieldClassName,
  name,
  label,
  params,
  mask,
  formatChars,
  defaultValue,
  control,
  isError,
  onChangeHandler,
  placeholder,
}) => {
  return (
    <label className={fieldClassName}>
      {label ? (
        <span className="form__label">
          {label}
          {params.required ? <span>*</span> : <></>}
        </span>
      ) : (
        ""
      )}
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={{
          ...params,
          onChange: onChangeHandler,
        }}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <InputMask
            mask={mask}
            onBlur={onBlur}
            onChange={onChange}
            inputRef={ref}
            value={value}
            maskChar={null}
            className={`form__input ${isError ? "form__input--error" : ""}`}
            formatChars={formatChars}
            placeholder={placeholder}
          />
        )}
      />
    </label>
  );
};

export default InputMasked;
