import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import InputSearch from "components/InputSearch/InputSearch";
import Icon from "components/Icon/Icon";

const UsersNavigation = () => {
  const { url } = useRouteMatch();

  return (
    <div className="users__navigation">
      <h3 className="users__title title">Список пользователей</h3>
      <div className="users__buttons">
        <InputSearch />
        <Link
          to={`${url.endsWith("/") ? url.slice(0, -1) : url}/add`}
          className="button button--black users__add-btn"
        >
          <Icon name="plus" />
          <span>Новый пользователь</span>
        </Link>
      </div>
    </div>
  );
};

export default UsersNavigation;
