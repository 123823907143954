import React from "react";
import {useDispatch, useSelector} from "react-redux";
import { setQuery } from "store/slice";
import Icon from "components/Icon/Icon";

const InputSearch = () => {
  const dispatch = useDispatch();
  const query = useSelector(state => state.query);

  const changeInputHandler = (e) => {
    dispatch(setQuery(e.target.value));
  }

  return (
    <div className="search">
      <input
        value={query}
        onChange={changeInputHandler}
        className="form__input search__input"
        type="text"
        name="search"
        id="search"
        placeholder="Поиск"
      />
      <Icon name="search" />
    </div>
  );
};

export default InputSearch;
