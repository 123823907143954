import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormState, useWatch } from "react-hook-form";
import { setServiceDatesVisible } from "store/slice";
import ApplicationFormFieldset from "components/ApplicationFormFieldset/ApplicationFormFieldset";
import ApplicationFormInstruction from "components/ApplicationFormInstruction/ApplicationFormInstruction";
import InputUpload from "components/InputUpload/InputUpload";
import InputSelect from "components/InputSelect/InputSelect";
import InputToggle from "components/InputToggle/InputToggle";
import InputDate from "components/InputDate/InputDate";
import Icon from "components/Icon/Icon";
import { Periods } from "utils/data";

const ApplicationFormStep3 = ({
  number,
  title,
  description,
  fields,
  hiddenFields,
  instruction,
  register,
  control,
  setValue,
  clearErrors,
  trigger,
}) => {
  const { errors } = useFormState({
    control,
  });

  const isService = useWatch({
    control,
    name: "isService",
  });

  const dispatch = useDispatch();
  const isMobile = useSelector((state) => state.isMobile);
  const serviceDatesVisible = useSelector((state) => state.serviceDatesVisible);
  const [isInstructionVisible, setInstructionVisible] = useState(false);
  const stepFields = fields.map((field) => field.name);

  const [dynamicFields, setDynamicFields] = useState([]);

  useEffect(() => {
    setDynamicFields(hiddenFields);
  }, [hiddenFields]);

  useEffect(() => {
    if (!isService) {
      setValue("isService", "no");
    }
  }, [isService]);

  const changeToggleHandler = (e) => {
    const { value } = e.target;

    if (value === "yes") {
      dispatch(setServiceDatesVisible(1));
    } else {
      dispatch(setServiceDatesVisible(0));
      dynamicFields.map((field) => setValue(field.name, {}));
    }
  };

  const addButtonClickHandler = () => {
    const newCount = serviceDatesVisible + 1;
    dispatch(setServiceDatesVisible(newCount));
  };

  const deleteButtonClickHandler = () => {
    const newCount = serviceDatesVisible - 1;
    dispatch(setServiceDatesVisible(newCount));
    dynamicFields.map((field, i) => {
      if (i === newCount) {
        setValue(`${field.name}.${field.fieldName}`, "");
      }
    });
  };

  return (
    <div className="application__step application__step--three">
      <ApplicationFormFieldset
        number={number}
        title={title}
        description={description}
        trigger={trigger}
        stepFields={stepFields}
        isInstructionButton={true}
        onInstructionButtonClick={() => setInstructionVisible(true)}
      >
        <div className="application__fields">
          {fields.map((field, i) =>
            field.type === "date" ? (
              <InputDate
                key={i + 1}
                fieldClassName={
                  "application__field application__field--datepicker"
                }
                name={field.name}
                label={field.label}
                params={field.params}
                control={control}
                isError={errors[field.name]}
                onChangeHandler={() => clearErrors()}
              />
            ) : field.type === "toggle" ? (
              <div
                key={i + 1}
                className="application__field application__field--toggle"
              >
                <InputToggle
                  name={field.name}
                  label={field.label}
                  params={field.params}
                  buttons={field.buttons}
                  register={register}
                  onChange={changeToggleHandler}
                />
              </div>
            ) : field.name === "guaranteePeriod" ? (
              <div
                key={i + 1}
                className="application__field application__field--guarantee"
              >
                <InputSelect
                  optionsData={Periods}
                  name={field.name}
                  type={field.type}
                  label={field.label}
                  register={register}
                  params={field.params}
                  setValue={setValue}
                  isError={errors[field.name]}
                  clearErrors={clearErrors}
                  emptyMessage={field.emptyMessage}
                />
              </div>
            ) : (
              ""
            )
          )}
          {serviceDatesVisible > 0 && <div className="application__dynamic-fields">
            {dynamicFields.map((field, i) =>
              serviceDatesVisible > 0 && i + 1 <= serviceDatesVisible ? (
                <div key={i + 1} className="application__field-wrapper">
                  {serviceDatesVisible > 1 && i + 1 === serviceDatesVisible && (
                    <button
                      type="button"
                      className="application__button-delete"
                      onClick={deleteButtonClickHandler}
                    >
                      <Icon name={isMobile ? "minus" : "delete"} />
                    </button>
                  )}
                  <InputDate
                    fieldClassName={
                      "application__field application__field--datepicker"
                    }
                    name={`${field.name}.${field.fieldName}`}
                    label={isMobile ? field.labelMobile : field.label}
                    params={field.params}
                    control={control}
                    isError={errors[field.name]}
                    onChangeHandler={() => clearErrors()}
                  />
                </div>
              ) : (
                ""
              )
            )}
            {serviceDatesVisible > 0 && serviceDatesVisible < 3 && (
              <div className="application__field application__field--button">
                <button
                  type="button"
                  className="application__button-add"
                  onClick={addButtonClickHandler}
                >
                  <span className="button button--round button--medium-gray">
                    <Icon name="add" />
                  </span>
                  <span>{isMobile ? "Добавить ещё одну дату ТО" : "Добавить тех.обслуж."}</span>
                </button>
              </div>
            )}
          </div>}

          {fields.map((field, i) =>
            field.type === "file" ? (
              <div
                key={i + 1}
                className="application__field application__field--upload"
              >
                <InputUpload
                  name={field.name}
                  label={field.label}
                  params={field.params}
                  register={register}
                  control={control}
                  setValue={setValue}
                  isError={errors[field.name]}
                  clearErrors={clearErrors}
                />
              </div>
            ) : (
              ""
            )
          )}
        </div>
      </ApplicationFormFieldset>
      <ApplicationFormInstruction
        topText={instruction.topText}
        bottomText={instruction.bottomText}
        scheme={instruction.scheme}
        markers={isMobile ? instruction.markersMobile : instruction.markers}
        isVisible={isMobile ? isInstructionVisible : true}
        onCloseButtonClick={() => setInstructionVisible(false)}
      >
        <p className="application__instruction-text-w-link">
          Если нет гарантийного талона, свяжитесь с менеджером (тел.{" "}
          <a href="tel:+78002349335">+7 800 234-93-35</a>)
        </p>
      </ApplicationFormInstruction>
    </div>
  );
};

export default ApplicationFormStep3;
