import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { setAdminModal } from "store/slice";
import {
  setApplicationStatus,
  addApplicationInternalComment,
  deleteApplication,
} from "store/async";
import Modal from "components/Modal/Modal";
import ModalWindow from "components/ModalWindow/ModalWindow";
import { AppPath } from "utils/const";

const ApplicationModal = ({ uid, number, internalComment, confirmComment }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const modal = useSelector((state) => state.adminModal);

  useEffect(() => {
    if (modal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [modal]);

  const closeModalHandler = () => {
    dispatch(setAdminModal(null));
  };

  const deleteButtonClickHandler = async () => {
    await dispatch(deleteApplication({ id: uid })).unwrap();
    closeModalHandler();
    history.push(AppPath.ROOT);
  };

  const commentSubmitHandler = (comment) => {
    dispatch(addApplicationInternalComment({ id: uid, comment: comment }));
    closeModalHandler();
  };

  const incompleteSubmitHandler = (comment) => {
    dispatch(
      setApplicationStatus({ id: uid, status: "incomplete", comment: comment })
    );
    closeModalHandler();
  };

  const rejectionSubmitHandler = (comment) => {
    dispatch(
      setApplicationStatus({ id: uid, status: "rejection", comment: comment })
    );
    closeModalHandler();
  };

  const confirmSubmitHandler = (comment) => {
    dispatch(
      setApplicationStatus({
        id: uid,
        status: "documentsAwait",
        comment: comment ? comment : confirmComment ? confirmComment : "",
      })
    );
    closeModalHandler();
  };

  const submitHandlers = {
    comment: commentSubmitHandler,
    incomplete: incompleteSubmitHandler,
    rejection: rejectionSubmitHandler,
    confirm: confirmSubmitHandler,
  };

  const buttonClickHandlers = {
    delete: deleteButtonClickHandler,
  };

  return (
    <>
      {modal ? (
        <Modal>
          <OutsideClickHandler onOutsideClick={closeModalHandler}>
            <ModalWindow
              modal={modal}
              number={number}
              comment={internalComment}
              onClose={closeModalHandler}
              onSubmit={submitHandlers[modal.name]}
              onButtonClick={buttonClickHandlers[modal.name]}
            />
          </OutsideClickHandler>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};

export default ApplicationModal;
