import React from "react";

const Input = ({
  type,
  name,
  label,
  register,
  params,
  placeholder,
  isError,
  onFocus,
  isReadOnly = false,
  isSelect = false,
}) => {
  return (
    <>
      {label ? (
        <label className="form__label" htmlFor={name}>
          {label}
          {params.required ? <span>*</span> : <></>}
        </label>
      ) : (
        ""
      )}
      <input
        className={`form__input ${isError ? "form__input--error" : ""} ${
          isSelect ? "form__input--select" : ""
        }`}
        type={type}
        name={name}
        id={name}
        {...register(name, params)}
        placeholder={placeholder}
        onFocus={onFocus}
        readOnly={isReadOnly}
      />
    </>
  );
};

export default Input;
