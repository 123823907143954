import React from "react";
import UsersTableRow from "components/UsersTableRow/UsersTableRow";
import UsersTableCol from "components/UsersTableCol/UsersTableCol";
import { UsersTableMeta } from "utils/const";

const UsersTable = ({ users }) => {
  return (
    <div className="users__table">
      <div className="users__table-row users__table-row--head">
        {UsersTableMeta.map((col) => (
          <UsersTableCol key={col.id + 1} id={col.id} text={col.label} />
        ))}
      </div>
      {users.map((user) => (
        <UsersTableRow key={user.uid} user={user} />
      ))}
    </div>
  );
};

export default UsersTable;
