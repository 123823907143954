import React from "react";

const ApplicationItem = ({ children, title }) => {
  return (
    <div className="application__item">
      {title !== "none" && <p className="application__item-title">{title}</p>}
      {children}
    </div>
  );
};

export default ApplicationItem;
