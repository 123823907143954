import React from "react";
import { Controller } from "react-hook-form";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
registerLocale("ru", ru);

const InputDate = ({
  fieldClassName,
  name,
  label,
  params,
  control,
  isError,
  onChangeHandler,
}) => {

  return (
    <div className={fieldClassName}>
      {label ? (
        <label className="form__label" htmlFor={name}>
          {label}
          {params.required ? <span>*</span> : <></>}
        </label>
      ) : (
        ""
      )}
      <Controller
        control={control}
        name={name}
        id={name}
        rules={{
          ...params,
          onChange: onChangeHandler,
        }}
        render={({ field: { onChange, value, ref } }) => (
          <DatePicker
            selected={value}
            dateFormat="dd.MM.yyyy"
            locale={ru}
            inputRef={ref}
            onChange={onChange}
            placeholderText={new Date().toLocaleString("ru", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            })}
            disabledKeyboardNavigation
            name={name}
            className={`form__input form__input--datepicker ${
              isError ? "form__input--error" : ""
            }`}
          />
        )}
      />
    </div>
  );
};

export default InputDate;
