import React from "react";
import { Link } from "react-router-dom";
import { ExportToExcel } from "components/ExportToExcel/ExportToExcel";

const DropdownMenuLink = ({ path, href, label, handler, type, modClass }) => {
  return type === "download" ? (
    <ExportToExcel label={label} />
  ) : path ? (
    <Link to={path} className="menu__link">
      {label}
    </Link>
  ) : href ? (
    <a href={href} className={`menu__link ${modClass ? modClass : ""}`} target="_blank">
      {label}
    </a>
  ) : (
    <a className={`menu__link ${modClass ? modClass : ""}`} onClick={handler}>
      {label}
    </a>
  );
};

export default DropdownMenuLink;
