import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, useWatch } from "react-hook-form";
import { setFilters, clearFilters, selectMinAndMaxSum } from "store/slice";
import FiltersToggle from "components/FiltersToggle/FiltersToggle";
import FiltersModal from "components/FiltersModal/FiltersModal";
import FiltersStatus from "components/FiltersStatus/FiltersStatus";
import FiltersDate from "components/FiltersDate/FiltersDate";
import FiltersModel from "components/FiltersModel/FiltersModel";
import FiltersRegion from "components/FiltersRegion/FiltersRegion";
import FiltersSum from "components/FiltersSum/FiltersSum";
import FiltersSpares from "components/FiltersSpares/FiltersSpares";
import Icon from "components/Icon/Icon";
import { FiltersMeta } from "utils/const";

const Filters = ({isFiltersOpened}) => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.filters);
  const sumMarginalValues = useSelector((state) => selectMinAndMaxSum(state));
  const { register, control, setValue, setFocus, reset,  } = useForm();
  const [filterOpened, setFilterOpened] = useState(null);

  const statusFilters = useWatch({
    control,
    name: "status",
  });

  const minDateFilter = useWatch({
    control,
    name: "minDate",
  });

  const maxDateFilter = useWatch({
    control,
    name: "maxDate",
  });

  const modelFilters = useWatch({
    control,
    name: "model",
  });

  const regionFilter = useWatch({
    control,
    name: "region",
  });

  const sumFilter = useWatch({
    control,
    name: "sum",
  });

  const sparesFilter = useWatch({
    control,
    name: "spares",
  });

  const spareFilter = useWatch({
    control,
    name: "spare",
  });

  useEffect(() => {
    FiltersMeta.forEach((filter) => {
      setStoreVals(filter);
    })
  }, []);

  useEffect(() => {
    if (!sparesFilter) {
      setValue("spares", filters["spare"]?.sparesValue || "all");
    }
  }, [sparesFilter]);

  useEffect(() => {
    dispatch(
      setFilters({
        date: {
          count: minDateFilter || maxDateFilter ? 1 : 0,
          min: minDateFilter,
          max: maxDateFilter,
        },
      })
    );

    dispatch(
      setFilters({
        region: {
          count: regionFilter ? 1 : 0,
          value: regionFilter,
        },
      })
    );

    dispatch(
      setFilters({
        spare: {
          count: sparesFilter !== "all" ? 1 : 0,
          sparesValue: sparesFilter,
          spareName: spareFilter,
        },
      })
    );

    if (statusFilters) {
      dispatch(
        setFilters({
          status: { count: statusFilters.length, values: statusFilters },
        })
      );
    }

    if (modelFilters) {
      dispatch(
        setFilters({
          model: { count: modelFilters.length, values: modelFilters },
        })
      );
    }

    if (sumFilter) {
      const isMinFilter =
        sumFilter[0] && sumFilter[0] > sumMarginalValues.minValue;
      const isMaxFilter =
        sumFilter[1] && sumFilter[1] < sumMarginalValues.maxValue;

      dispatch(
        setFilters({
          sum: {
            count: isMinFilter || isMaxFilter ? 1 : 0,
            min: sumFilter[0],
            max: sumFilter[1],
          },
        })
      );
    }
  }, [
    statusFilters,
    minDateFilter,
    maxDateFilter,
    modelFilters,
    regionFilter,
    sumFilter,
    sparesFilter,
    spareFilter,
  ]);

  const openFilterHandler = (filter) => {
    if (filterOpened === filter) {
      setFilterOpened(null);
    } else {
      setFilterOpened(filter);
    }
  };

  const closeFilterHandler = (e) => {
    if (
      !e.target.classList.contains("filters__toggle") &&
      !e.target.parentElement.classList.contains("filters__toggle")
    ) {
      setFilterOpened(null);
    }
  };

  const clearFiltersHandler = () => {
    reset();
    dispatch(clearFilters());
  };

  const setStoreVals = (filter) => {
    const key = filter.id;

    switch (filter.id) {
      case "status":
        setValue(filter.id, filters[key]?.values);
        break;
      case "date":
        setValue('minDate', filters[key]?.min);
        setValue('maxDate', filters[key]?.max);
        break;
      case "model":
        setValue(filter.id, filters[key]?.values || []);
        break;
      case "region":
        setValue(filter.id, filters[key]?.value);
        break;
      case "sum":
        if (filters[key]?.min && filters[key]?.max) {
          setValue(filter.id, [filters[key]?.min, filters[key]?.max]);
        }
        break;
      case "spare":
        setValue('spare', filters[key]?.spareName);
        setValue('spares', filters[key]?.sparesValue);
        break;
    }
  }

  const renderFilter = (filter) => {
    switch (filter.id) {
      case "status":
        return <FiltersStatus inputs={filter.inputs} register={register} />;
      case "date":
        return <FiltersDate inputs={filter.inputs} control={control} />;
      case "model":
        return <FiltersModel register={register} />;
      case "region":
        return (
          <FiltersRegion
            selector={filter.selector}
            register={register}
            setValue={setValue}
            setFocus={setFocus}
          />
        );
      case "sum":
        return (
          <FiltersSum
            range={filter.range}
            inputs={filter.inputs}
            control={control}
            minValue={sumMarginalValues.minValue}
            maxValue={sumMarginalValues.maxValue}
            currentValues={sumFilter}
            setValue={setValue}
          />
        );
      case "spare":
        return (
          <FiltersSpares
            inputs={filter.radioButtons}
            selector={filter.selector}
            register={register}
            setValue={setValue}
            setFocus={setFocus}
            isSelectOpened={sparesFilter === "withSpares"}
          />
        );
    }
  };

  return (
    <div className={`applications__filters filters ${!isFiltersOpened ? "filters--hidden" : ""}`}>
      <form className="form filters__form">
        {FiltersMeta.map((filter) => (
          <div key={filter.id} className="filters__item">
            <FiltersToggle
              title={filter.label}
              count={filters[filter.id]?.count || 0}
              id={filter.id}
              onClick={openFilterHandler}
            />
            {filterOpened === filter.id && (
              <FiltersModal onClose={closeFilterHandler}>
                {renderFilter(filter)}
              </FiltersModal>
            )}
          </div>
        ))}
        <button
          type="button"
          className="filters__reset"
          onClick={clearFiltersHandler}
        >
          <span>Очистить фильтры</span>
          <Icon name="clear" />
        </button>
      </form>
    </div>
  );
};

export default Filters;
