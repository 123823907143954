import React from "react";

const ApplicationItemInput = ({ children, modClass }) => {
  return (
    <div className={`application__item-input ${modClass ? modClass : ""}`}>
      {children}
    </div>
  );
};

export default ApplicationItemInput;
